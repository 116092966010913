import React, { useCallback, useEffect, useState } from 'react'
import { LoadingContainer, useRouteNavigation, useRouteParams } from '@mindshare/layout'
import { Checkbox, message } from 'antd'

import {
  cleanUpFrontSheetSelectPlansContainer, saveNewFrontSheet, updateFrontSheet, updateFrontSheetCheckedPlans,
  initialiseFrontSheetSelectPlansContainer
} from 'Actions/frontSheetActions'
import { getMediaPlans } from 'Actions/mediaPlansActions'
import { RootState } from 'Reducers/index'
import { FrontSheetPost } from 'Apis/generated/frontSheetsApi'
import { ILookupPlan } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import FrontSheetSelectPlansComponent from 'Components/FrontSheets/FrontSheetSelectFields/FrontSheetSelectPlansComponent'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useHierarchies } from 'Hooks/useHierarchies'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectCurrentFrontSheet } from '../../../selectors'

interface IProps {
  frontSheetId?: number
}

export const FrontSheetSelectPlansContainer: React.FC<IProps> = ({ frontSheetId }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useRouteNavigation()
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [saveInProgress, setSaveInProgress] = useState(false)
  const messageKey = 'messageKey'
  const [frontSheetName, setFrontSheetName] = useState<string>('')
  const [leftAlignNumbersInExcelExport, setLeftAlignNumbersInExcelExport] = useState(false)
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)
  const currentClientDisplayName = useAppSelector((state) => state.app.currentClient.displayName)
  const currentFrontSheet = useAppSelector(selectCurrentFrontSheet)
  const mediaPlans = useAppSelector((state: RootState) => state.mediaPlans.mediaPlans) as ILookupPlan[]

  const { data: hierarchies } = useHierarchies(currentClientId)
  const [selectedGeography, setSelectedGeography] = useState<number>()
  const [selectedMedia, setSelectedMedia] = useState<number>()
  const [selectedBusiness, setSelectedBusiness] = useState<number>()
  const [selectedBrand, setSelectedBrand] = useState<number>()
  const [hideSortOrderInExcelExport, setHideSortOrderInExcelExport] = useState(false)
  const [verticalAlignmentInExcelExport, setVerticalAlignmentInExcelExport] = useState(false)

  useEffect(() => {
    if (currentFrontSheet.frontSheetName) {
      setFrontSheetName(currentFrontSheet.frontSheetName)
    }
    setLeftAlignNumbersInExcelExport(currentFrontSheet.leftAlignNumbersInExcelExport)
    setHideSortOrderInExcelExport(currentFrontSheet.hideSortOrderInExcelExport)
    setVerticalAlignmentInExcelExport(currentFrontSheet.verticalAlignmentInExcelExport)

    return () => {
      message.destroy()
      dispatch(cleanUpFrontSheetSelectPlansContainer())
    }
  }, [
    dispatch,
    currentFrontSheet.frontSheetName,
    currentFrontSheet.leftAlignNumbersInExcelExport,
    currentFrontSheet.hideSortOrderInExcelExport,
    currentFrontSheet.verticalAlignmentInExcelExport
  ])

  const getMediaPlansFromStore = useCallback(async (
    clientId: string | number,
    geographyHierarchyId: number,
    mediaHierarchyId: number,
    businessHierarchyId: number,
    brandHierarchyId: number
  ) => {
    await dispatch(getMediaPlans(clientId, hierarchies, geographyHierarchyId, mediaHierarchyId, businessHierarchyId, brandHierarchyId))
  }
  , [dispatch, hierarchies])

  useEffect(() => {
    getMediaPlansFromStore(
      currentClientId,
      selectedGeography,
      selectedMedia,
      selectedBusiness,
      selectedBrand
    )
  }, [
    getMediaPlansFromStore,
    currentClientId,
    selectedGeography,
    selectedMedia,
    selectedBusiness,
    selectedBrand
  ])

  const onHierarchyFilterSelectionChange = (hierarchyName: string, id: number) => {
    switch (hierarchyName) {
      case 'geography':
      { setSelectedGeography(id); return }
      case 'media':
      { setSelectedMedia(id); return }
      case 'business':
      { setSelectedBusiness(id); return }
      case 'brand':
      { setSelectedBrand(id) }
    }
  }

  const table = {
    checkedPlans: currentFrontSheet.mediaPlanIds,
    checkPlans: (item: ILookupPlan) => {
      if (table.checkedPlans.some(x => x === item.mediaPlanId)) {
        dispatch(updateFrontSheetCheckedPlans([...table.checkedPlans].filter(x => x !== item.mediaPlanId)))
      } else {
        dispatch(updateFrontSheetCheckedPlans([...table.checkedPlans, item.mediaPlanId]))
      }
    },
    renderLastColumn: (item: ILookupPlan) => {
      return (
        <Checkbox
          checked={table.checkedPlans.some(x => x === item.mediaPlanId)}
          data-testid='table-checkbox'
          onChange={() => table.checkPlans(item)}
        />
      )
    }
  }

  const saveFunctions = {
    renderErrorMessage: (errors: string) => {
      message.destroy()
      if (typeof errors === 'string') {
        message.error({ content: errors, duration: 10, key: messageKey })
      }
      setSaveInProgress(false)
      setSaveSuccess(false)
    },
    saveNewFrontSheet: async () => {
      const requestBody: FrontSheetPost = {
        frontSheetName,
        leftAlignNumbersInExcelExport,
        mediaPlanIds: table.checkedPlans,
        hideSortOrderInExcelExport,
        verticalAlignmentInExcelExport
      }
      const saveResult = await dispatch(saveNewFrontSheet(currentClientId, requestBody))
      if (saveResult && typeof saveResult === 'string') {
        saveFunctions.renderErrorMessage(saveResult)
      } else {
        navigate(`/edit-front-sheet/select-fields/${saveResult}?clientId=${currentClientId}`, false)
      }
    },
    updateExistingFrontSheet: async () => {
      const saveResult = await dispatch(
        updateFrontSheet(currentClientId, {
          ...currentFrontSheet,
          frontSheetName,
          leftAlignNumbersInExcelExport,
          hideSortOrderInExcelExport,
          verticalAlignmentInExcelExport
        })
      )
      if (saveResult && typeof saveResult === 'string') {
        saveFunctions.renderErrorMessage(saveResult)
      } else {
        navigate(`/edit-front-sheet/select-fields/${frontSheetId}?clientId=${currentClientId}`, false)
      }
    }
  }

  const buttonActions = {
    save: () => {
      setSaveInProgress(true)
      if (frontSheetId) {
        saveFunctions.updateExistingFrontSheet()
      } else {
        saveFunctions.saveNewFrontSheet()
      }
    }
  }

  const mappedRows = mediaPlans ? mediaPlans.map((plan) => {
    return {
      ...plan,
      checked: currentFrontSheet.mediaPlanIds.some(x => x === plan.mediaPlanId) ? 1 : 0
    }
  }) : []

  return (
    <FrontSheetSelectPlansComponent
      buttonActions={buttonActions}
      currentClientId={currentClientId}
      currentClientDisplayName={currentClientDisplayName}
      frontSheetName={frontSheetName}
      hierarchies={hierarchies}
      mediaPlans={[...mappedRows].sort((a, b) => b.checked - a.checked)}
      onHierarchyFilterSelectionChange={onHierarchyFilterSelectionChange}
      saveInProgress={saveInProgress}
      saveSuccess={saveSuccess}
      selectedBrand={selectedBrand}
      selectedBusiness={selectedBusiness}
      selectedGeography={selectedGeography}
      selectedMedia={selectedMedia}
      table={table}
      updateFrontSheetName={setFrontSheetName}
      isEditMode={!!frontSheetId}
      leftAlignNumbersInExcelExport={leftAlignNumbersInExcelExport}
      updateLeftAlignExport={setLeftAlignNumbersInExcelExport}
      hideSortOrderInExcelExport={hideSortOrderInExcelExport}
      onHideSortOrderInExcelExport={setHideSortOrderInExcelExport}
      verticalAlignmentInExcelExport={verticalAlignmentInExcelExport}
      onVerticalAlignmentExportChange={setVerticalAlignmentInExcelExport}
    />
  )
}


export const FrontSheetSelectPlansLoadingContainer: React.FC = (props) => {
  const frontSheetId = useRouteParams('frontSheetId', Number)
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const { data: currentClient } = useCurrentClient()
  const { data: hierarchies } = useHierarchies(currentClient?.id)
  const mediaPlans = useAppSelector(state => state.mediaPlans.mediaPlans) as ILookupPlan[]

  return (
    <LoadingContainer
      appDataSuccess={true}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={
        (id) => initialiseFrontSheetSelectPlansContainer(id, hierarchies, mediaPlans, { frontSheetId, currentClientId: currentClient?.id })
      }
      loadingSelector={(state: RootState) => state.frontSheets.frontSheetSelectPlansContainerLoading}
    >
      <FrontSheetSelectPlansContainer {...props} frontSheetId={frontSheetId} />
    </LoadingContainer>
  )
}

export default FrontSheetSelectPlansLoadingContainer

