import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Select, Tooltip } from 'antd'
import { Agency } from 'Apis/generated/buyingAgenciesApi'
import { ClientCampaign } from 'Apis/generated/clientCampaignsApi'
import { ClientMediaPartner } from 'Apis/generated/clientMediaPartnersApi'
import { LinkedLookup, LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { isFieldOfSameOrHigherLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMasteredListsData, MasteredListConfig } from 'Hooks/useMasteredListFieldsData'
import { SelectTooltip } from 'Components/SelectTooltip'

type TMasteredListDataSourceItem = ClientCampaign | ClientMediaPartner | Agency

interface IProps {
  masteredListsData: IMasteredListsData
  mediaPlanFieldDataType: number
  mediaPlanFieldRow: IMediaPlanFieldRow
  setFieldValue: (value: number) => void
  disabled?: boolean
  availableLinkedLookupOptions?: LinkedLookupValue[]
  isFilteredLookupValuesVisible: boolean
  errorMessage: string
  isEnforcedLink: boolean
  enforcedErrorMessage: string
  encouragedErrorMessage: string
  linkedLookupConnection: LinkedLookup
  dependentMediaPlanField: IMediaPlanFieldRow | IMediaPlanTemplateFields
  isReferencedFieldMandatory: boolean
  isEnforcedCombinationError: boolean
}

export const renderMasteredListOptionsByAvailability = f =>
  f.options.map(option => (
    <Select.Option
      key={option.value}
      value={option.value}
      {...option.optionProps}
    >
      <Tooltip
        title={option.label}
      >
        {option.label}
      </Tooltip>
    </Select.Option>
  ))

export const TemplatePlanFieldValueMasteredListSelectComponent: FunctionComponent<IProps> = ({
  masteredListsData,
  mediaPlanFieldDataType,
  mediaPlanFieldRow,
  setFieldValue,
  disabled = false,
  availableLinkedLookupOptions = [],
  isFilteredLookupValuesVisible,
  errorMessage,
  isEnforcedLink,
  enforcedErrorMessage,
  encouragedErrorMessage,
  linkedLookupConnection,
  dependentMediaPlanField,
  isReferencedFieldMandatory,
  isEnforcedCombinationError
}) => {
  if (!MasteredListConfig[mediaPlanFieldDataType]) {
    return null
  }

  const { dataSourceKey, idKey, displayKey } = MasteredListConfig[mediaPlanFieldDataType]
  const dataSource = masteredListsData[dataSourceKey] as TMasteredListDataSourceItem[]

  const notSelectedValues = dataSource?.filter(item =>
    !linkedLookupConnection?.linkedLookupValues?.some(
      v => item[idKey] === v.dependentValueId
    )
  ) || []
  const filteredValues = dataSource?.filter(item =>
    availableLinkedLookupOptions?.some(option => item[idKey] === option.dependentValueId)
  ) || []
  const allValues = [...filteredValues, ...notSelectedValues]

  const validOptions = allValues.map(option => ({ value: option[idKey], label: option[displayKey] }))

  const invalidOptions = dataSource?.filter(item =>
    !availableLinkedLookupOptions.some(
      option => item[idKey] === option.dependentValueId
    ) && !notSelectedValues.some((v) => item[idKey] === v[idKey])
  ).map(option => ({
    value: option[idKey],
    label: option[displayKey],
    optionProps: {
      disabled: isEnforcedLink,
      className: 'not-valid'
    }
  })) || []

  const options = [
    ...(validOptions.length ? [{
      label: isEnforcedLink ? 'Valid' : 'Recommended',
      options: validOptions
    }] : []),
    ...(invalidOptions.length ? [{
      label: isEnforcedLink ? 'Not valid' : 'Not recommended',
      options: invalidOptions
    }] : [])
  ]
  const isDisplayingLookupOptions =
    isFilteredLookupValuesVisible &&
    (
      !!validOptions.length &&
      !!invalidOptions.length ||
      !!invalidOptions.length
    )

  const isInvalidCombination = isFilteredLookupValuesVisible &&
    !validOptions.find(
      option => option.value === Number(mediaPlanFieldRow.defaultValue)
    ) &&
    isFieldOfSameOrHigherLevel(dependentMediaPlanField, mediaPlanFieldRow)

  const isEnforcedLinkInvalid =
    isEnforcedLink &&
    isInvalidCombination &&
    (!dependentMediaPlanField?.defaultValue
      ? isReferencedFieldMandatory
      : dependentMediaPlanField?.defaultValue)
  const isEncouragedLinkInvalid = !isEnforcedLink && isInvalidCombination && dependentMediaPlanField?.defaultValue
  const error = (isEnforcedLinkInvalid && enforcedErrorMessage) || (isEncouragedLinkInvalid && encouragedErrorMessage)
  const hasError = !disabled && (isEnforcedCombinationError || isEnforcedLinkInvalid)

  return (
    <SelectTooltip
      selectProps={{
        placeholder: 'Select Value',
        onChange: setFieldValue,
        defaultValue: mediaPlanFieldRow.defaultValue && Number(mediaPlanFieldRow.defaultValue),
        optionFilterProp: 'children',
        allowClear: true,
        showSearch: true,
        disabled,
        className: classNames({ '--error': hasError })
      }}
      tooltipProps={{
        title:
          disabled
            ? errorMessage
            : error || ''
      }}
    >
      {isDisplayingLookupOptions
        ? options.map((f, index) => (
          <Select.OptGroup key={`${f.label}${index}`} label={f.label}>
            {renderMasteredListOptionsByAvailability(f)}
          </Select.OptGroup>
        ))
        : dataSource &&
          dataSource.map(cf => (
            <Select.Option
              key={cf[idKey]}
              value={cf[idKey]}
            >
              {cf[displayKey]}
            </Select.Option>
          ))}
    </SelectTooltip>
  )
}

export default TemplatePlanFieldValueMasteredListSelectComponent
