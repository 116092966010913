import React, { useCallback, useEffect, useState } from 'react'
import { MSLayoutContentRow, LoadingContainer, useRouteNavigation, useMindshareSelector } from '@mindshare/layout'
import { getDataImports, cleanUpImportListContainer, getDataImport, initialiseImportListContainer } from 'Actions/importActions'
import ImportListComponent from 'Components/Import/ImportListComponent'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import { useAgencyLoadingGif } from 'Hooks/useAgencyLoadingGif'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectDataImports } from '../../selectors'

export const ImportListContainer: React.FC = (): React.ReactElement => {
  const navigate = useRouteNavigation()
  const currentClientId = useAppSelector((state) => state.app.currentClient.id)
  const currentClientDisplayName = useAppSelector((state) => state.app.currentClient.displayName)
  const dataImports = useMindshareSelector(selectDataImports)
  const dispatch = useAppDispatch()
  const [clientSelected] = useState(currentClientId)

  useEffect(() => () => dispatch(cleanUpImportListContainer()), [dispatch])

  useEffect(() => {
    dispatch(getDataImports(currentClientId))

    if (clientSelected && currentClientId !== clientSelected) {
      dispatch(cleanUpImportListContainer())
    }
  }, [dispatch, clientSelected, currentClientId])

  const createNewImport = useCallback(() => navigate('/create-import'), [navigate])
  const editImport = useCallback((importId: number) => {
    dispatch(getDataImport(importId, currentClientId))
    navigate(`/edit-import/${importId}`)
  }, [navigate, dispatch, currentClientId])

  return (
    <>
      <TitleAndButtonsComponent
        buttons={[{
          buttonClick: createNewImport,
          buttonText: 'Create New Import'
        }]}
        subtitle='Your Imports'
        title='Imports'
      />

      <MSLayoutContentRow fillAvailableSpace={true}>
        <ImportListComponent
          currentClientDisplayName={currentClientDisplayName}
          createNewImport={createNewImport}
          dataImports={dataImports}
          editImport={editImport}
        />
      </MSLayoutContentRow>
    </>
  )
}

export const ImportListLoadingContainer: React.FC = () => {
  const agencyLoadingGifLocation = useAgencyLoadingGif()
  const clientId = useAppSelector((state) => state.app.currentClient.id)
  const dispatch = useAppDispatch()
  return (
    <LoadingContainer
      appDataSuccess={!!clientId}
      agencyLoadingGif={agencyLoadingGifLocation}
      initialiseContainer={(loadingComplete) => initialiseImportListContainer(loadingComplete, dispatch, clientId)}
    >
      <ImportListContainer />
    </LoadingContainer>
  )
}

export default ImportListLoadingContainer
