import React, { useCallback, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Dropdown, Spin, Tooltip } from 'antd'
import type { MenuProps } from 'antd'
import { QuestionOutlined, QuestionCircleOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import { MSFeatureSwitch } from '@mindshare/layout'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { PlanInfoTableComponent } from 'Components/MediaPlanVersion/PlanInfoTableComponent'
import { IMediaPlans } from 'Components/MediaPlans/constants/entities/IMediaPlans'
import { TabularLookupModalComponent } from 'Components/TabularLookup/TabularLookupModalComponent'
import ModalComponent from '../ModalComponent'
import { downloadExcelPlan } from './helpers/downloadExcelPlan'

interface IPlanInfoModalComponentProps {
  clientId: number
  displayFlightDates: boolean
  expandHierarchies: boolean
  hasUnsavedChanges: boolean
  mediaPlan: IMediaPlans
  mediaPlanVersionFields?: IMediaPlanVersionField[]
  text: string
}

const PlanInfoDownloadActionsComponent: React.FC<IPlanInfoModalComponentProps> = ({
  clientId,
  displayFlightDates,
  expandHierarchies,
  hasUnsavedChanges,
  mediaPlan,
  mediaPlanVersionFields,
  text
}) => {
  const [helpModalVisible, setHelpModalVisible] = useState(false)
  const [fieldDefinitionModalVisible, setFieldDefinitionModalVisible] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const showHelpModal = () => setHelpModalVisible(true)
  const showFieldDefinitionModal = () => setFieldDefinitionModalVisible(true)

  const hideHelpModal = () => setHelpModalVisible(false)
  const hideFieldDefinitionModal = () => setFieldDefinitionModalVisible(false)

  const onPlanDownloadClick = useCallback(async () => {
    const { mediaPlanId, planName, mediaPlanVersionId } = mediaPlan
    setDownloading(true)
    try {
      await downloadExcelPlan(planName, mediaPlanId, mediaPlanVersionId, clientId, !!expandHierarchies, !!displayFlightDates)
    } finally {
      setDownloading(false)
    }
  }, [clientId, displayFlightDates, expandHierarchies, mediaPlan])

  const infoMenuItems: MenuProps['items'] = [
    {
      key: 'media-plan-input-help',
      label: (
        <span data-testid='media-plan-input-help'>
        Media plan input help
        </span>
      ),
      icon: <QuestionCircleOutlined />,
      onClick: showHelpModal
    },
    {
      key: 'field-definitions',
      label: (
        <span data-testid='field-definitions'>
        Field definitions
        </span>
      ),
      icon: <InfoCircleOutlined />,
      onClick: showFieldDefinitionModal
    }
  ]

  return (
    <div className='tooltip'>
      <MSFeatureSwitch name='TABULAR_LOOKUP'>
        <TabularLookupModalComponent />
      </MSFeatureSwitch>
      <Tooltip title={hasUnsavedChanges ? 'Media Plan must be saved before downloading' : 'Download Media Plan'}>
        <Button
          className='ms-button'
          data-testid={downloading ? 'download-spin-icon' : 'download-plan-button'}
          disabled={hasUnsavedChanges}
          onClick={onPlanDownloadClick}
          tabIndex={-1}
          type='primary'
          icon={downloading ? <Spin /> : <DownloadOutlined />}
        />
      </Tooltip>
      <Dropdown
        menu={{ items: infoMenuItems }}
        trigger={['click']}
        overlayClassName='toolbar__context-menu'
      >
        <Button
          className='ms-button'
          data-testid='tooltip'
          type='primary'
          tabIndex={-1}
          icon={<QuestionOutlined />}
        />
      </Dropdown>

      <ModalComponent
        onCancel={hideHelpModal}
        open={helpModalVisible}
        width={900}
        footer={null}
        modalBody={<ReactMarkdown linkTarget="_blank" source={text} />}
      />

      <ModalComponent
        onCancel={hideFieldDefinitionModal}
        open={fieldDefinitionModalVisible}
        width={900}
        footer={null}
        className='field-definitions-modal'
        modalBody={<PlanInfoTableComponent mediaPlanVersionFields={mediaPlanVersionFields} />}
      />
    </div>
  )
}

export default PlanInfoDownloadActionsComponent
