import React from 'react'
import { DefaultOptionType } from 'antd/lib/select'

export const filterDropdownOptions = (searchText: string, option: DefaultOptionType) => {
  let title: string = ''

  if (typeof option.children === 'string') {
    title = option.children
  } else if (React.isValidElement<{ title: string }>(option.children)) {
    title = option.children.props.title
  }

  return title.toLowerCase().includes(searchText.toLowerCase())
}
