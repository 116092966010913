import React from 'react'
import Moment from 'react-moment'
import { Button, Popconfirm, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { MSVirtualTable, formatEmailToName } from '@mindshare/layout'
import { Client } from 'Apis/generated/clientsApi'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { ICustomExportDetails } from '../constants/entities'
import { useClaimVerification } from '../hooks'


interface IProps {
  currentClient: Client
  dataCustomExports: ICustomExportDetails[]
  geographies: Record<number, string>
  editExport: (e: number) => void
  deleteExport: (apiArg: { id: number }) => Promise<unknown>
  onOpenRunCustomExport: (customExportId: number) => void
}

export const CustomExportList: React.FC<IProps> = ({
  currentClient,
  dataCustomExports,
  geographies,
  editExport,
  deleteExport,
  onOpenRunCustomExport
}) => {
  const { data: hasExportRunClaim } = useClaimVerification({ claimName: 'exportRun', clientName: currentClient.name })

  const columns = [
    {
      title: 'Export Name',
      dataIndex: 'customExportName',
      key: 'customExportName'
    },
    {
      title: 'Geography',
      dataIndex: 'geography',
      key: 'geography'
    },
    {
      title: 'Modified On',
      dataIndex: 'modifiedOn',
      key: 'modifiedOn'
    },
    {
      title: 'Modified By',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy'
    },
    {
      title: '',
      dataIndex: 'buttons',
      className: 'button-cell',
      key: 'buttons'
    }
  ]

  const data = dataCustomExports.map((item) => {
    return {
      key: item.customExportId,
      customExportName: item.customExportName,
      modifiedBy: formatEmailToName(item.modifiedBy || item.createdBy),
      modifiedOn: <Moment format='DD MMM YY'>{item.modifiedOn || item.createdOn}</Moment>,
      geography: geographies[item.geographyHierarchyId],
      buttons: (
        <>
          <Tooltip title='Edit Export'>
            <Button
              data-testid='edit-export'
              icon={<EditOutlined />}
              onClick={() => editExport(item.customExportId)}
              type='link'
            />
          </Tooltip>
          <Popconfirm
            placement='rightTop'
            title={popoverMessage.confirmDeleteCustomExport}
            onConfirm={() => deleteExport({ id: item.customExportId })}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title='Remove Export'>
              <Button
                data-testid='remove-export'
                icon={<DeleteOutlined />}
                type='link'
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip title='Run Export'>
            {hasExportRunClaim && <Button
              data-testid='run-export'
              icon={<PlayCircleOutlined/>}
              onClick={() => onOpenRunCustomExport(item.customExportId)}
              type='link'
            />}
          </Tooltip>
        </>
      )
    }
  })

  return (
    <div className='list-table custom-export-list-table'>
      <MSVirtualTable
        columns={columns}
        dataSource={data}
      />
    </div>
  )
}
