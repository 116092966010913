import { createSelector } from 'reselect'
import { LinkedLookup } from 'Apis/generated/linkedLookupsApi'
import { RootState } from 'Reducers/index'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { getFieldColumnName, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { selectParseData } from './mediaPlansSelectors'

interface ISelectAvailableLinkedLookupOptionsParams {
  flightGroupIndex: number
  flightIndex: number
  linkedLookupConnection: LinkedLookup
  referencedMediaPlanField: IMediaPlanFieldRow | IMediaPlanTemplateFields
}

export const createAvailableLinkedLookupOptionsSelector = () => createSelector(
  selectParseData,
  (state, params: ISelectAvailableLinkedLookupOptionsParams) => params.flightGroupIndex,
  (state, params: ISelectAvailableLinkedLookupOptionsParams) => params.flightIndex,
  (state, params: ISelectAvailableLinkedLookupOptionsParams) => params.linkedLookupConnection,
  (state, params: ISelectAvailableLinkedLookupOptionsParams) => params.referencedMediaPlanField,
  (parseData, flightGroupIndex, flightIndex, linkedLookupConnection, referencedMediaPlanField) => {
    if (!linkedLookupConnection || !referencedMediaPlanField) return null

    return linkedLookupConnection.linkedLookupValues.filter(
      item => {
        const currentReferencedFieldLevelId = referencedMediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId
        const referencedColumnName = getFieldColumnName(referencedMediaPlanField)
        let data

        if (currentReferencedFieldLevelId === FieldLevelType.PLAN) {
          data = parseData
        } else if (currentReferencedFieldLevelId === FieldLevelType.FLIGHT_GROUP) {
          data = parseData.flightGroups[flightGroupIndex]
        } else if (currentReferencedFieldLevelId === FieldLevelType.FLIGHT) {
          data = parseData.flightGroups[flightGroupIndex].flights[flightIndex]
        }

        return (item.referencedValueId.toString() ===
            data?.[referencedColumnName]?.toString())
      }
    )
  }
)

export const selectLinkedLookups = (state: RootState) => state.linkedLookups

export const selectLinkedLookupList = createSelector(
  selectLinkedLookups,
  linkedLookups => linkedLookups.linkedLookupList
)
