import React from 'react'
import {
  Controller,
  Control,
  FieldArrayWithId,
  ControllerRenderProps,
  UseFormSetValue
} from 'react-hook-form'
import { Input, Tooltip } from 'antd'
import classNames from 'classnames'
import { MSTable } from '@mindshare/layout'
import { TabularLookupLevel } from 'Apis/generated/tabularLookupApi'
import { TabularLookupFieldActionsComponent } from 'Components/TabularLookup/TabularLookupFieldActionsComponent'
import { TabularLookupDataTypeSelectComponent } from 'Components/TabularLookup/TabularLookupDataTypeSelectComponent'
import { ITabularLookupFormData } from 'Components/TabularLookup/TabularLookupModalComponent'
import { TabularLookupLevel as LookupLevel } from 'Components/TabularLookup/enums/tabularLookupLevel'

interface ITabularLookupValuesTableProps {
  control: Control<ITabularLookupFormData>
  handleValueNameChange: (e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<ITabularLookupFormData>, index: number) => void
  handleDataTypeChange: (value: number, field: ControllerRenderProps<ITabularLookupFormData>, index: number) => void
  removeRow: (index: number) => void
  addRow: () => void
  fields: Array<FieldArrayWithId<ITabularLookupFormData, 'returnValues'>>
  setValue: UseFormSetValue<ITabularLookupFormData>
  isEditMode: boolean
  isReadOnly: boolean
  currentTabularLookupLevel: TabularLookupLevel
}

export const TabularLookupValuesTableComponent: React.FC<ITabularLookupValuesTableProps> = ({
  control,
  handleValueNameChange,
  handleDataTypeChange,
  removeRow,
  addRow,
  fields,
  setValue,
  isEditMode,
  isReadOnly,
  currentTabularLookupLevel
}) => {
  const columns = [
    {
      title: 'Value Name',
      dataIndex: 'returnValueName',
      key: 'returnValueName',
      render: (_text: string, row: FieldArrayWithId<ITabularLookupFormData, 'returnValues', 'id'>, index: number) => (
        <Controller
          control={control}
          name={`${index}.returnValueName` as any}
          render={({ field, fieldState }) => {
            const errorMessage = (fieldState?.error && fieldState.error.message || '') as string
            const isDisabled = !!(isEditMode && row.returnValueDataTypeId) || isReadOnly

            return (
              <Tooltip title={errorMessage}>
                <Input
                  placeholder='Enter value name'
                  className={classNames({ '--error': !!errorMessage })}
                  onChange={(e) => {
                    field.onChange(e.target.value)
                    setValue(`returnValues.${index}.returnValueName` as any, e.target.value)
                  }}
                  onBlur={(e) => handleValueNameChange(e, field, index)}
                  value={row.returnValueName || undefined}
                  disabled={isDisabled}
                />
              </Tooltip>
            )
          }}
        />
      )
    },
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      render: (_text: string, row: FieldArrayWithId<ITabularLookupFormData, 'returnValues', 'id'>, index: number) => {
        const isDisabled = !!(isEditMode && row.returnValueDataTypeId) || isReadOnly

        return (
          <Controller
            name={`${index}.returnValueDataTypeId` as any}
            control={control}
            render={({ field }) => (
              <TabularLookupDataTypeSelectComponent
                field={field}
                onChange={handleDataTypeChange}
                index={index}
                value={row.returnValueDataTypeId || undefined}
                disabled={isDisabled}
              />
            )}
          />
        )
      }
    },
    {
      title: 'Options',
      dataIndex: 'buttons',
      className: 'button-cell',
      key: 'buttons',
      render: (_text: string, row: FieldArrayWithId<ITabularLookupFormData, 'returnValues', 'id'>, index: number) => {
        const isDisabled = !!(isEditMode && row.returnValueDataTypeId) || isReadOnly
        const canRemove = isEditMode
          ? currentTabularLookupLevel === LookupLevel.Undefined ||
            ((currentTabularLookupLevel === LookupLevel.Plan || currentTabularLookupLevel === LookupLevel.Template) &&
              !row.returnValueDataTypeId)
          : fields.length > 1

        return (
          <TabularLookupFieldActionsComponent
            onRemove={() => removeRow(index)}
            onAdd={addRow}
            canRemove={canRemove}
            disabled={isDisabled}
            isReadOnly={isReadOnly}
          />
        )
      }
    }
  ]

  return (
    <div className='list-table tabular-lookup-list-table'>
      <MSTable
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={fields}
      />
    </div>
  )
}

export default TabularLookupValuesTableComponent
