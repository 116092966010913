import { useMemo, useCallback } from 'react'
import { MediaPlanSubFlight } from 'Apis/generated/mediaPlanVersionsApi'
import { getFlightData } from 'Helpers/flightHelper'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { useAppSelector } from '../../../store'
import { selectFlightGroups } from '../../../selectors'

export const useFlightCommands = (selectedFlights: string[]) => {
  const flightGroups = useAppSelector(selectFlightGroups)

  const getMergeFlightDateData = useCallback((dataKey: string) => {
    if (!dataKey) {
      return { data: {}, isFlight: null }
    }

    return {
      ...getFlightData(flightGroups, dataKey)
    }
  }, [flightGroups])

  const getMergeDateRange = useCallback((startDateKey: string, endDateKey: string) => {
    const { data: mergeStart, isFlight: startIsFlight } = getMergeFlightDateData(startDateKey)
    const { data: mergeEnd, isFlight: endIsFlight } = getMergeFlightDateData(endDateKey)

    const startDate = startIsFlight ? (mergeStart as IFlight).flightStartDate : (mergeStart as MediaPlanSubFlight).subFlightStartDate
    const endDate = endIsFlight ? (mergeEnd as IFlight).flightEndDate : (mergeEnd as MediaPlanSubFlight).subFlightEndDate
    return [startDate, endDate]
  }, [getMergeFlightDateData])

  const isSelectedFlightCellEmpty = useMemo(() => {
    const { data } = getMergeFlightDateData(selectedFlights?.[0])
    return !(data as IFlight)?.merge
  }, [getMergeFlightDateData, selectedFlights])

  return useMemo(
    () => ({ selectedFlights, getMergeDateRange, isSelectedFlightCellEmpty }),
    [selectedFlights, getMergeDateRange, isSelectedFlightCellEmpty]
  )
}
