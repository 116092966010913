import React, { useRef, useEffect, ReactNode, MouseEventHandler, useState, useCallback } from 'react'
import { Tooltip, TooltipProps, Select, SelectProps } from 'antd'

interface ISelectTooltipProps {
  children: ReactNode
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
  tooltipProps?: TooltipProps
  selectProps?: Partial<SelectProps> & Record<string, any>
}

export const SelectTooltip: React.FC<ISelectTooltipProps> = ({
  children,
  onMouseEnter,
  onMouseLeave,
  tooltipProps,
  selectProps
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const handleMouseEnter = useCallback((e: MouseEvent) => {
    setShowTooltip(true)
    if (onMouseEnter) onMouseEnter(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>)
  }, [onMouseEnter])

  const handleMouseLeave = useCallback((e: MouseEvent) => {
    setShowTooltip(false)
    if (onMouseLeave) onMouseLeave(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>)
  }, [onMouseLeave])

  useEffect(() => {
    const span = ref.current

    if (span) {
      span.addEventListener('mouseenter', handleMouseEnter)
      span.addEventListener('mouseleave', handleMouseLeave)

      return () => {
        span.removeEventListener('mouseenter', handleMouseEnter)
        span.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [handleMouseEnter, handleMouseLeave])

  return (
    <Tooltip {...tooltipProps} open={showTooltip && !!tooltipProps.title}>
      <div
        ref={ref}
        className='select-tooltip'
      >
        <Select
          {...selectProps}
          onDropdownVisibleChange={() => setShowTooltip(false)}
        >
          {children}
        </Select>
      </div>
    </Tooltip>
  )
}

export default SelectTooltip
