import { useMemo } from 'react'
import { MediaPlanSubFlightModelExtended } from 'Containers/MediaPlanVersion/SubFlightFieldContainer'

interface IUseSubFlightSelectHelper {
  selectedSubFlights: string[]
  subFlightsDates: MediaPlanSubFlightModelExtended[]
}

export const useSubFlightSelectHelper = ({
  selectedSubFlights,
  subFlightsDates
}: IUseSubFlightSelectHelper) => {
  const firstSelectedFlightIndex = useMemo(
    () => (selectedSubFlights?.[0]?.split('-') ?? [])[1],
    [selectedSubFlights]
  )

  const lastSelectedFlightIndex = useMemo(
    () => (selectedSubFlights?.[selectedSubFlights.length - 1]?.split('-') ?? [])[1],
    [selectedSubFlights]
  )

  const firstSelectedSubFlightIndex = useMemo(
    () => (selectedSubFlights?.[0]?.split('-') ?? [])[4],
    [selectedSubFlights]
  )

  const lastSelectedSubFlightIndex = useMemo(
    () => (selectedSubFlights?.[selectedSubFlights.length - 1]?.split('-') ?? [])[4],
    [selectedSubFlights]
  )

  const firstSelectedSubFlight = useMemo(
    () => (subFlightsDates.find(subFlight => (
      subFlight?.flightIndex === Number(firstSelectedFlightIndex) &&
      subFlight.subFlightIndex === Number(firstSelectedSubFlightIndex)
    ))),
    [subFlightsDates, firstSelectedFlightIndex, firstSelectedSubFlightIndex]
  )

  const lastSelectedSubFlight = useMemo(
    () => (subFlightsDates.find(subFlight => (
      subFlight?.flightIndex === Number(lastSelectedFlightIndex) &&
      subFlight.subFlightIndex === Number(lastSelectedSubFlightIndex)
    ))),
    [subFlightsDates, lastSelectedFlightIndex, lastSelectedSubFlightIndex]
  )

  return useMemo(
    () => ({
      firstSelectedFlightIndex,
      lastSelectedFlightIndex,
      firstSelectedSubFlightIndex,
      lastSelectedSubFlightIndex,
      firstSelectedSubFlight,
      lastSelectedSubFlight
    }),
    [
      firstSelectedFlightIndex,
      lastSelectedFlightIndex,
      firstSelectedSubFlightIndex,
      lastSelectedSubFlightIndex,
      firstSelectedSubFlight,
      lastSelectedSubFlight
    ]
  )
}
