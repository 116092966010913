import { useMemo } from 'react'
import { CLIENT_HIERARCHY_TYPES } from '@mindshare/layout'
import { useAppSelector } from '../../../store'
import { createSelectedParentHierarchySelector } from '../../../selectors'

if (CLIENT_HIERARCHY_TYPES.length > 4) {
  throw new Error('A new hierarchy was added and needs to be added as a separate selector in the hook')
}

export const useSelectedParentHierarchies = (params) => {
  const selectParentHierarchy = useMemo(createSelectedParentHierarchySelector, [])

  const hierarchy0 = useAppSelector(state => selectParentHierarchy(state, { ...params, hierarchyName: CLIENT_HIERARCHY_TYPES[0] }))
  const hierarchy1 = useAppSelector(state => selectParentHierarchy(state, { ...params, hierarchyName: CLIENT_HIERARCHY_TYPES[1] }))
  const hierarchy2 = useAppSelector(state => selectParentHierarchy(state, { ...params, hierarchyName: CLIENT_HIERARCHY_TYPES[2] }))
  const hierarchy3 = useAppSelector(state => selectParentHierarchy(state, { ...params, hierarchyName: CLIENT_HIERARCHY_TYPES[3] }))

  const selectedParentHierarchies = useMemo(() => {
    return {
      [CLIENT_HIERARCHY_TYPES[0]]: hierarchy0,
      [CLIENT_HIERARCHY_TYPES[1]]: hierarchy1,
      [CLIENT_HIERARCHY_TYPES[2]]: hierarchy2,
      [CLIENT_HIERARCHY_TYPES[3]]: hierarchy3
    }
  }, [
    hierarchy0, hierarchy1, hierarchy2, hierarchy3
  ])

  return selectedParentHierarchies
}
