import React, { useState, useCallback } from 'react'
import { Input, Select, Button } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { FieldTag } from 'Apis/generated/mediaPlanFieldsApi'
import { PlanExportSettingsModalComponent } from 'Components/PlanExportSettingsModalComponent'
import TooltipButton from './TooltipButton'

export interface ITemplateTitleComponentProps {
  disabled?: boolean
  inputLabel: 'Template Title' | 'Front Sheet Name'
  inputValue: string
  onTemplateNameChange: (value: string) => void
  onLeftAlignExportChange: (leftAlign: boolean) => void
  onHideSortOrderExportChange: (value: boolean) => void
  onVerticalAlignmentExportChange: (value: boolean) => void
  placeholder: 'Enter Template Title' | 'Enter Front Sheet Title'
  tags: FieldTag[] | null
  title: 'Create Template' | 'Edit Front Sheet' | null | 'Create Front Sheet' | 'Edit Template'
  tooltip: 'templateEdit' | 'createEditFrontSheetPlanSelection' | 'createEditFrontSheetViewEditor'
  leftAlignNumbersInExcelExport: boolean
  hideSortOrderInExcelExport: boolean
  verticalAlignment: boolean
}

const Option = Select.Option

export const TemplateTitleComponent: React.FC<ITemplateTitleComponentProps> = ({
  disabled,
  inputLabel,
  inputValue,
  onTemplateNameChange,
  onLeftAlignExportChange,
  onHideSortOrderExportChange,
  onVerticalAlignmentExportChange,
  placeholder,
  tags,
  title,
  tooltip,
  leftAlignNumbersInExcelExport,
  hideSortOrderInExcelExport,
  verticalAlignment
}) => {
  const [planExportSettingsModalVisible, setPlanExportSettingsModalVisible] = useState<boolean>(false)

  const showPlanExportSettingsModal = useCallback(() => setPlanExportSettingsModalVisible(true), [])
  const hidePlanExportSettingsModal = useCallback(() => setPlanExportSettingsModalVisible(false), [])

  return (
    <>
      <div className='ms-template-title'>
        <h1>{title}</h1>
        <div className='ms-template-title__inner'>
          <div className='ms-template-title__title'>
            <p>{inputLabel}</p>
            <Input
              className='ms-input'
              disabled={disabled}
              data-testid='title-input'
              placeholder={placeholder}
              value={inputValue}
              onChange={(e) => onTemplateNameChange(e.target.value)}
            />
            <Button
              type='link'
              tabIndex={-1}
              icon={<SettingOutlined />}
              onClick={showPlanExportSettingsModal}
              data-testid='plan-export-settings-btn'
            >
          Plan export settings
            </Button>
          </div>
          {tags && (
            <div className='ms-template-title__tags'>
              <label>Select Fields By Tags</label>
              <Select
                optionFilterProp='children'
                className='ms-select'
                placeholder='Tags'
                allowClear={true}
                showSearch={true}
              >
                {tags.map(tag =>
                  <Option value={tag.fieldTagId} key={tag.fieldTagId}>{tag.fieldTagName}</Option>
                )}
              </Select>
            </div>
          )}
        </div>
        {tooltip !== 'templateEdit' && <TooltipButton tooltip={tooltip} />}
      </div>

      <PlanExportSettingsModalComponent
        hideModal={hidePlanExportSettingsModal}
        visible={planExportSettingsModalVisible}
        leftAlignNumbersInExcelExport={leftAlignNumbersInExcelExport}
        onLeftAlignExportChange={onLeftAlignExportChange}
        hideSortOrderInExcelExport={hideSortOrderInExcelExport}
        onHideSortOrderExportChange={onHideSortOrderExportChange}
        verticalAlignment={verticalAlignment}
        onVerticalAlignmentExportChange={onVerticalAlignmentExportChange}
        disabled={disabled}
      />
    </>
  )
}

export default TemplateTitleComponent
