import {
  formatLowerCaseFirstLetter,
  getClientHierarchyID,
  msCurrencyParser,
  msIntegerParser,
  msNumberParser,
  IMSHierarchies,
  formatUpperCaseFirstLetter,
  msPercentParser,
  getHierarchyID
} from '@mindshare/layout'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { ClientMediaPartner } from 'Apis/generated/clientMediaPartnersApi'
import { ClientCampaign } from 'Apis/generated/clientCampaignsApi'
import { FinanceAudience, FinanceBookingCategory } from 'Apis/generated/financeDataApi'
import { Agency } from 'Apis/generated/buyingAgenciesApi'
import {
  getFieldColumnName,
  IMediaPlanMetaField,
  IMediaPlanTemplateFields,
  isAggregatedOrCalculated
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFieldDataTypeExtended } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IPoint } from 'Helpers/selectionHelper'
import { allOf } from 'Helpers/conditionsHelper'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'

import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { parseDateStringIntoMediaPlanFormat } from './calendarHelper'
import { getValueOfCorrectType } from './flightHelper'

export interface IPasteObject {
  mediaPlanVersionFieldId: number
  clientMediaPlanFieldId: number
  columnName: string
  dataType: IFieldDataTypeExtended
  clientFieldValues: ClientFieldValue[]
  isCalculatedOrAggregated: boolean
  mediaPlanFieldId: number
}

export const getFlightColumnNames = (
  sortedFlightFields: IMediaPlanMetaField[],
  fieldCount: number,
  fieldIndex: number
): IPasteObject[] => {
  let count = 0
  return sortedFlightFields.map((field, index) => {
    if (index >= fieldIndex && count <= fieldCount - 1) {
      count++
      return {
        mediaPlanVersionFieldId: field.mediaPlanVersionFieldId,
        clientMediaPlanFieldId: field.clientMediaPlanField.clientMediaPlanFieldId,
        columnName: formatLowerCaseFirstLetter(field.clientMediaPlanField.mediaPlanField.columnName),
        dataType: field.clientMediaPlanField.mediaPlanField.fieldDataType,
        clientFieldValues: field.clientMediaPlanField.clientFieldValues,
        isCalculatedOrAggregated: isAggregatedOrCalculated(field),
        mediaPlanFieldId: field.clientMediaPlanField.mediaPlanField.mediaPlanFieldId
      }
    }
    return undefined
  }).filter((c) => c)
}

export const getFlightGroupNames = (sortedFlightGroupFields: IMediaPlanMetaField[], fieldIndex: number, fieldCount: number): [number, IPasteObject[]] => {
  let count = 0
  const columnNames: IPasteObject[] = sortedFlightGroupFields.map((field, index) => {
    if (index >= fieldIndex && count <= fieldCount - 1) {
      count++
      return {
        mediaPlanVersionFieldId: field.mediaPlanVersionFieldId,
        clientMediaPlanFieldId: field.clientMediaPlanField.clientMediaPlanFieldId,
        columnName: formatLowerCaseFirstLetter(field.clientMediaPlanField.mediaPlanField.columnName),
        clientFieldValues: field.clientMediaPlanField.clientFieldValues,
        dataType: field.clientMediaPlanField.mediaPlanField.fieldDataType,
        isCalculatedOrAggregated: isAggregatedOrCalculated(field),
        mediaPlanFieldId: field.clientMediaPlanField.mediaPlanField.mediaPlanFieldId
      }
    }
    return undefined
  }).filter(c => c)
  return [count, columnNames]
}

export const getMergeFlights = (flightGroups: IFlightGroup, flightGroupIndex: number, flightIndex?: number): Array<{
  flight: IFlight
  flightGroupIndex: number
  index: number
}> => {
  const affectedFlightGroups = flightGroups.map((fg, fgIndex) => {
    if (fgIndex < flightGroupIndex) {
      return null
    }

    return {
      ...fg,
      fgIndex
    }
  }).filter(Boolean)

  return [...affectedFlightGroups.flatMap(({ flights, fgIndex }) => flights.map((flight, index) => ({
    flight,
    flightGroupIndex: fgIndex,
    index
  })).filter(({ flight, index }) => (
    flight.merge && (fgIndex > flightGroupIndex || fgIndex === flightGroupIndex && index >= flightIndex)
  )))]
}

export const getFlightColumNames = (
  sortedFlightFields: IMediaPlanMetaField[],
  count: number,
  fieldCount: number
): IPasteObject[] => {
  return sortedFlightFields.map((field) => {
    if (count <= fieldCount - 1) {
      count++
      return {
        mediaPlanVersionFieldId: field.mediaPlanVersionFieldId,
        clientMediaPlanFieldId: field.clientMediaPlanField.clientMediaPlanFieldId,
        columnName: formatLowerCaseFirstLetter(field.clientMediaPlanField.mediaPlanField.columnName),
        clientFieldValues: field.clientMediaPlanField.clientFieldValues,
        dataType: field.clientMediaPlanField.mediaPlanField.fieldDataType,
        isCalculatedOrAggregated: isAggregatedOrCalculated(field),
        mediaPlanFieldId: field.clientMediaPlanField.mediaPlanField.mediaPlanFieldId
      }
    } else {
      return null
    }
  }).filter(c => c)
}

export const parseDataType = async (
  value: string,
  fieldDataType: FieldDataType,
  clientFieldValues: ClientFieldValue[],
  masteredListsData: IMasteredListsData,
  hierarchies: Partial<IMSHierarchies>,
  createUniqueStringFn: () => Promise<any>,
  financeList: IMediaPlanVersionFinanceListFields,
  currentMasteredHierarchies: Partial<IMSHierarchies>,
  cachedMasteredHierarchies: Partial<IMSHierarchies>,
  columnName: string
) => {
  switch (fieldDataType) {
    case FieldDataType.CLIENT_DEFINED_LIST:
      return clientFieldValues.find(cfv => cfv.valueDisplayName === value)
        ?.clientFieldValueId
    case FieldDataType.UNIQUE_STRING:
      // eslint-disable-next-line no-case-declarations
      let clientFieldValueId = clientFieldValues.find(
        cfv => cfv.valueDisplayName === value
      )?.clientFieldValueId
      if (!clientFieldValueId) {
        clientFieldValueId = await createUniqueStringFn()
      }
      return clientFieldValueId
    case (FieldDataType.MEDIA_PARTNERS):
      return masteredListsData.mediaPartners.find(mp => mp.mediaPartnerValue === value).clientMediaPartnerId
    case (FieldDataType.CLIENT_CAMPAIGNS):
      return masteredListsData.clientCampaigns.find(cc => cc.clientCampaignName === value).clientCampaignId
    case (FieldDataType.CLIENT_AGENCY_LIST):
      return masteredListsData.clientAgencies.find(ca => ca.agencyDisplayName === value).agencyId
    case (FieldDataType.BRAND_HIERARCHY):
      return getClientHierarchyID(hierarchies, 'brand', value)
    case FieldDataType.BUSINESS_HIERARCHY:
      return getClientHierarchyID(hierarchies, 'business', value)
    case FieldDataType.GEOGRAPHY_HIERARCHY:
      return getClientHierarchyID(hierarchies, 'geography', value)
    case FieldDataType.MEDIA_HIERARCHY:
      return getClientHierarchyID(hierarchies, 'media', value)
    case FieldDataType.DATE:
      return parseDateStringIntoMediaPlanFormat(value)
    case FieldDataType.PERCENTAGE:
      return msPercentParser(value)
    case FieldDataType.INTEGER:
      return msIntegerParser(value)
    case FieldDataType.CURRENCY:
      return msCurrencyParser(value)
    case FieldDataType.DECIMAL:
      return msNumberParser(value)
    case FieldDataType.FINANCE_BOOKING_CATEGORY_LIST:
      // eslint-disable-next-line no-case-declarations
      const financeBookingListValues = financeList[
        formatUpperCaseFirstLetter(columnName)
      ]?.data as FinanceBookingCategory[]
      return financeBookingListValues?.find(
        item => item.bookingCategoryName === value
      )?.financeBookingCategoryId
    case FieldDataType.FINANCE_BUYING_AUDIENCE_LIST:
    case FieldDataType.FINANCE_TARGET_AUDIENCE_LIST:
      // eslint-disable-next-line no-case-declarations
      const financeAudienceValues = financeList[
        formatUpperCaseFirstLetter(columnName)
      ]?.data as FinanceAudience[]
      return financeAudienceValues?.find(item => item.audienceName === value)
        ?.financeAudienceId
    case FieldDataType.FINANCE_STATION_HIERARCHY:
      return getHierarchyID(currentMasteredHierarchies, 'financeStation', value) || getHierarchyID(cachedMasteredHierarchies, 'financeStation', value)
    case FieldDataType.FINANCE_PRODUCT_HIERARCHY:
      return getHierarchyID(currentMasteredHierarchies, 'financeProduct', value) || getHierarchyID(cachedMasteredHierarchies, 'financeProduct', value)
    default:
      return getValueOfCorrectType(value, fieldDataType)
  }
}

export const isSameDataType = (sourceField: IMediaPlanTemplateFields, targetField?: IMediaPlanTemplateFields) => {
  const sourceFieldDataType = sourceField.clientMediaPlanField.mediaPlanField.fieldDataTypeId
  const targetFieldDataType = targetField?.clientMediaPlanField.mediaPlanField.fieldDataTypeId

  return sourceFieldDataType === targetFieldDataType
}

export const isClientFieldValueInList = (
  clientFieldValueId: ClientFieldValue['clientFieldValueId'],
  clientFieldValues: ClientFieldValue[]
) => !!clientFieldValues?.some((clientFieldValue) => clientFieldValue.clientFieldValueId === clientFieldValueId)

export const isMediaPartnerInList = (
  clientMediaPartnerId: ClientMediaPartner['clientMediaPartnerId'],
  masteredListsData: IMasteredListsData) => masteredListsData.mediaPartners.some(mediaPartner => mediaPartner.clientMediaPartnerId === clientMediaPartnerId)

export const isClientCampaignInList = (
  clientCampaignId: ClientCampaign['clientCampaignId'],
  masteredListsData: IMasteredListsData) => masteredListsData.clientCampaigns.some(clientCampaign => clientCampaign.clientCampaignId === clientCampaignId)

export const isClientAgencyInList = (
  agencyId: Agency['agencyId'],
  masteredListsData: IMasteredListsData) => masteredListsData.clientAgencies.some(clientAgency => clientAgency.agencyId === agencyId)

export const canPasteFlightGroup = (copiedFlightGroup, flightGroupFields, shift, masteredListsData) =>
  flightGroupFields.every((sourceFlightGroupField, index, self) => {
    const columnName = getFieldColumnName(sourceFlightGroupField)

    // validate data type and value availability of the flight group field if it was copied
    if (columnName in copiedFlightGroup) {
      const sourceValue = copiedFlightGroup[columnName]
      const targetFlightGroupField = self[index + shift.flightGroupFieldIndex]

      switch (sourceFlightGroupField.clientMediaPlanField.mediaPlanField.fieldDataTypeId) {
        case FieldDataType.CLIENT_DEFINED_LIST:
        case FieldDataType.UNIQUE_STRING: {
          return allOf(
            isSameDataType(sourceFlightGroupField, targetFlightGroupField),
            isClientFieldValueInList(sourceValue, targetFlightGroupField.clientMediaPlanField.clientFieldValues)
          )
        }

        case FieldDataType.MEDIA_PARTNERS: {
          return allOf(
            isSameDataType(sourceFlightGroupField, targetFlightGroupField),
            isMediaPartnerInList(sourceValue, masteredListsData)
          )
        }

        case FieldDataType.CLIENT_CAMPAIGNS: {
          return allOf(
            isSameDataType(sourceFlightGroupField, targetFlightGroupField),
            isClientCampaignInList(sourceValue, masteredListsData)
          )
        }

        case FieldDataType.CLIENT_AGENCY_LIST: {
          return allOf(
            isSameDataType(sourceFlightGroupField, targetFlightGroupField),
            isClientAgencyInList(sourceValue, masteredListsData)
          )
        }

        default: {
          return isSameDataType(sourceFlightGroupField, targetFlightGroupField)
        }
      }
    }

    // otherwise we can assume it is valid
    return true
  })

export const getShiftFromSourceToTarget = (copiedFlightGroup: Partial<IFlightGroup>, target: IPoint, flightGroupFields: IMediaPlanTemplateFields[]) => {
  const sourceFlightGroupFieldIndex = flightGroupFields.findIndex((flightGroupField) => {
    const columnName = getFieldColumnName(flightGroupField)

    return copiedFlightGroup.hasOwnProperty(columnName)
  })

  return target.flightGroupFieldIndex - sourceFlightGroupFieldIndex
}

export const shiftCopiedFlightGroup = (copiedFlightGroup: Partial<IFlightGroup>, flightGroupFields: IMediaPlanTemplateFields[], shift: number) => {
  return flightGroupFields.reduce((shiftedCopy, flightGroupField, index, self) => {
    const columnName = getFieldColumnName(flightGroupField)
    const shiftedField = self[index + shift]

    if (copiedFlightGroup.hasOwnProperty(columnName) && shiftedField) {
      const shiftedColumnName = getFieldColumnName(shiftedField)

      return {
        ...shiftedCopy,
        [shiftedColumnName]: copiedFlightGroup[columnName]
      }
    }

    return shiftedCopy
  }, {})
}

export const getStartOfCopy = (flightGroups: IFlightGroup[], flightGroupFields: IMediaPlanMetaField[], copy: any): IPoint => {
  const flightGroupIndex = flightGroups.findIndex(({ mediaPlanFlightGroupId }) => copy[mediaPlanFlightGroupId])
  const flightGroupFieldIndex = flightGroupFields.findIndex((flightGroupField) => {
    const columnName = getFieldColumnName(flightGroupField)
    const flightGroupId = flightGroups[flightGroupIndex]?.mediaPlanFlightGroupId

    return copy[flightGroupId]?.hasOwnProperty(columnName)
  })

  return {
    flightGroupIndex,
    flightGroupFieldIndex
  }
}

export const getShiftDistance = (source: IPoint, destination: IPoint): IPoint => ({
  flightGroupFieldIndex: destination.flightGroupFieldIndex - source.flightGroupFieldIndex,
  flightGroupIndex: destination.flightGroupIndex - source.flightGroupIndex
})
