import { createSelector } from 'reselect'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { findByFieldLevelId, availableFlightAndSubFlights, fieldsInPlanByLevel, availableFieldsByLevel } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFlightGroupIndex } from 'Helpers/flightHelper'
import { filterRichText } from 'Helpers/richTextHelpers'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { selectMediaPlans, selectSelectedFlights } from './mediaPlansSelectors'

export const selectCurrentMediaPlanVersion = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.currentMediaPlanVersion
)

export const selectCurrentMediaPlanVersionFields = createSelector(
  selectCurrentMediaPlanVersion,
  currentMediaPlanVersion => currentMediaPlanVersion && currentMediaPlanVersion.mediaPlanVersionFields
)

export const selectCurrentMediaPlanVersionFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  currentMediaPlanVersionFields => findByFieldLevelId(currentMediaPlanVersionFields, FieldLevelType.FLIGHT)
)

export const selectCurrentMediaPlanVersionSubFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  currentMediaPlanVersionFields => findByFieldLevelId(currentMediaPlanVersionFields, FieldLevelType.SUB_FLIGHT)
)

export const selectParseData = createSelector(
  selectCurrentMediaPlanVersion,
  currentMediaPlanVersion => currentMediaPlanVersion.parseData
)

export const selectCalendarView = createSelector(
  selectParseData,
  parseData => parseData.calendarView as CalendarView
)

export const selectPlanStartDate = createSelector(
  selectParseData,
  parseData => parseData.planStartDate
)

export const selectPlanEndDate = createSelector(
  selectParseData,
  parseData => parseData.planEndDate
)

export const selectFlightGroups = createSelector(
  selectParseData,
  (parseData): IFlightGroup[] => parseData.flightGroups
)

export const createFlightGroupFlightsSelector = () => createSelector(
  selectFlightGroups,
  (state, flightGroupIndex: number) => flightGroupIndex,
  (flightGroups, flightGroupIndex) => flightGroups[flightGroupIndex]?.flights
)

const emptySelectedFlights = []
export const createFlightGroupSelectedFlightsSelector = () => createSelector(
  selectSelectedFlights,
  (_state, flightGroupIndex: number) => flightGroupIndex,
  (selectedFlights, flightGroupIndex) => {
    const filteredSelectedFlights = selectedFlights.filter(selectedKey => getFlightGroupIndex(selectedKey) === flightGroupIndex)
    if (filteredSelectedFlights.length) {
      return filteredSelectedFlights
    }
    return emptySelectedFlights
  }
)

export const selectMediaPlanVersionId = createSelector(
  selectParseData,
  parseData => parseData.mediaPlanVersionId
)

const emptyChosenFlightFields = []
export const createAvailableFlightAndSubFlightsSelector = () =>
  createSelector(
    selectCurrentMediaPlanVersionFields,
    selectFlightGroups,
    (_state, flightGroupIndex) => flightGroupIndex,
    (mediaPlanVersionFields, flightGroups, flightGroupIndex) => {
      const flightAndSubFlights = availableFlightAndSubFlights(
        mediaPlanVersionFields,
        flightGroups[flightGroupIndex]?.chosenFlightFields
      )

      if (flightAndSubFlights.length) {
        return flightAndSubFlights
      }
      return emptyChosenFlightFields
    }
  )

export const selectVersionLevelFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.PLAN).filter(field => field.clientMediaPlanField.mediaPlanField.fieldDataTypeId !== FieldDataType.RICH_TEXT)
)

export const selectAvailablePlanFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    availableFieldsByLevel(mediaPlanVersionFields, FieldLevelType.PLAN).filter(field => field.clientMediaPlanField.mediaPlanField.fieldDataTypeId !== FieldDataType.RICH_TEXT)
)

export const selectRichTextPlanLevelFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    fieldsInPlanByLevel(mediaPlanVersionFields, FieldLevelType.PLAN).filter(filterRichText)
)

export const selectFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    fieldsInPlanByLevel(
      mediaPlanVersionFields,
      FieldLevelType.FLIGHT
    )
)

export const selectAvailableFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    availableFieldsByLevel(
      mediaPlanVersionFields,
      FieldLevelType.FLIGHT
    )
)

export const selectRichTextAvailablePlanLevelFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    availableFieldsByLevel(mediaPlanVersionFields, FieldLevelType.PLAN).filter(filterRichText)
)

export const selectHasAvailableFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    availableFieldsByLevel(mediaPlanVersionFields, FieldLevelType.FLIGHT).length > 0
)

export const createHasOverlapDatesSelector = () =>
  createSelector(
    selectMediaPlans,
    (_state, flightGroupIndex) => flightGroupIndex,
    (mediaPlans, flightGroupIndex) =>
      mediaPlans.flightGroupWarnings.find(x => x.flightGroupIndex === flightGroupIndex)?.hasOverlapFlights || false
  )

export const createInvalidFlightsByFlightGroupSelector = () =>
  createSelector(
    selectMediaPlans,
    (_state, flightGroupIndex) => flightGroupIndex,
    (mediaPlans, flightGroupIndex) =>
      mediaPlans.invalidFlights.filter(i => i.flightGroupIndex === flightGroupIndex)
  )

export const createFlightsDatesSelector = () =>
  createSelector(
    selectMediaPlans,
    (_state, flightGroupIndex) => flightGroupIndex,
    (mediaPlans, flightGroupIndex) =>
      mediaPlans.currentMediaPlanVersion.parseData.flightGroups[flightGroupIndex]?.flights?.map((flight, index) => ({
        flightStartDate: flight.flightStartDate,
        flightEndDate: flight.flightEndDate,
        mediaPlanFlightId: flight.mediaPlanFlightId,
        merge: flight.merge,
        width: flight.width,
        flightIndex: index
      })).filter(c => c.merge) || []
  )

export const selectHasSubFlightFields = createSelector(
  selectCurrentMediaPlanVersionFields,
  mediaPlanVersionFields =>
    findByFieldLevelId(
      mediaPlanVersionFields,
      FieldLevelType.SUB_FLIGHT
    )?.length > 0
)

export const createEnableDropDownFieldSelector = () =>
  createSelector(
    selectFlightGroups,
    (_state, flightGroupIndex) => flightGroupIndex,
    (flightGroups, flightGroupIndex) =>
      flightGroups[flightGroupIndex]?.chosenFlightFields?.some(c => c.clientMediaPlanFieldId === 0)
  )
