import { useCallback } from 'react'
import { usePostTabularLookupMutation, usePutTabularLookupsByLookupIdMutation } from 'Apis/enhancedTabularLookupApi'
import { useNotificationMessage } from 'Hooks/CustomHooks'
import { NotificationType } from 'Constants/enums/NotificationType'
import { useAppSelector } from '../../../store'

const { SUCCESS, ERROR } = NotificationType

export const useTabularLookupOnSubmit = () => {
  const currentClientId = useAppSelector(state => state.app.currentClient.id)
  const [postTabularLookup, { isSuccess: isSuccessCreate, isError: isErrorCreate, error: errorCreate }] = usePostTabularLookupMutation()
  const [putTabularLookup, { isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate }] = usePutTabularLookupsByLookupIdMutation()

  useNotificationMessage(
    isErrorCreate,
    errorCreate && 'data' in errorCreate
      ? (Array.isArray(errorCreate.data) ? errorCreate.data.join('\n') : JSON.stringify(errorCreate.data))
      : 'Something went wrong',
    ERROR
  )
  useNotificationMessage(
    isErrorUpdate,
    errorUpdate && 'data' in errorUpdate
      ? (Array.isArray(errorUpdate.data) ? errorUpdate.data.join('\n') : JSON.stringify(errorUpdate.data))
      : 'Something went wrong',
    ERROR
  )
  useNotificationMessage(isSuccessCreate, 'Tabular Lookup has been created', SUCCESS)
  useNotificationMessage(isSuccessUpdate, 'Tabular Lookup has been updated', SUCCESS)

  return useCallback(
    async (values) => {
      const {
        lookupName,
        tabularDateDimensionTypeId,
        fieldsDimension,
        returnValues,
        isEditableAtPlanLevel,
        isTemplate,
        lookupId,
        currentTabularLookup
      } = values

      const payload = {
        tabularLookupId: 0,
        lookupName,
        tabularDateDimensionTypeId,
        fieldsDimension: fieldsDimension.map((d, idx) => ({
          ...d,
          sortOrder: idx
        })),
        returnValues: returnValues.filter((v) => v.returnValueName && v.returnValueDataTypeId > 0).map((v) => ({
          tabularLookupReturnValueId: 0,
          tabularLookupId: 0,
          returnValueName: v.returnValueName,
          returnValueDataTypeId: v.returnValueDataTypeId,
          factValueSets: []
        }))
      }
      let result

      if (lookupId) {
        const createPayload = {
          ...currentTabularLookup,
          returnValues
        }

        result = await putTabularLookup({
          clientId: currentClientId,
          lookupId,
          tabularLookupWeb: isTemplate ? { ...createPayload, isEditable: isEditableAtPlanLevel } : createPayload
        })
      } else {
        result = await postTabularLookup({
          clientId: currentClientId,
          tabularLookup: isTemplate ? { ...payload, isEditable: isEditableAtPlanLevel } : payload
        })
      }

      return result
    },
    [currentClientId, putTabularLookup, postTabularLookup]
  )
}
