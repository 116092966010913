import React, { CSSProperties, FunctionComponent, memo } from 'react'
import { IMSHierarchies, formatUpperCaseFirstLetter } from '@mindshare/layout'
import { IMediaPlanTemplateFields, getFieldColumnName, IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import FlightGroupFieldContainer from 'Containers/MediaPlanVersion/FlightGroupFieldContainer'
import FlightGroupRow from 'Containers/MediaPlanVersion/FlightGroupRow'
import FlightsTableContainer from 'Containers/MediaPlanVersion/FlightsTableContainer'
import FlightsGridLabelsContainer from 'Containers/MediaPlanVersion/FlightsGridLabelsContainer'
import FlightsGridSectionContainer from 'Containers/MediaPlanVersion/FlightsGridSectionContainer'
import ButtonFlightGroupComponent from 'Components/MediaPlanVersion/ButtonFlightGroupComponent'
import { useFlightGroupCommands } from 'Hooks/useFlightGroupCommands'
import type { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { SubtotalsFlightGroupFieldContainer } from 'Containers/MediaPlanVersion/SubtotalsFlightGroupFieldContainer'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { IPoint } from 'Helpers/selectionHelper'
import { CalendarView } from 'Components/MediaPlanVersion/constants/entities/IMediaPlanVersion'
import { useDataRelevantForLevel } from 'Components/MediaPlanVersion/hooks/useDataRelevantForLevel'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import { usePlanLinkedLookups } from 'Components/MediaPlanVersion/hooks/usePlanLinkedLookups'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'

interface IProps extends Omit<ReturnType<typeof useFlightGroupCommands>, 'selectFlightGroups'> {
  calculationMode: boolean
  flightGroupArrayLength: number
  masteredListsData: IMasteredListsData
  planHierarchies: IMSHierarchies
  mediaPlanFields: IMediaPlanTemplateFields[]
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  unfilteredHierarchies: IMSHierarchies
  displayFlightDates: boolean
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  collapsedViewField: string
  compressedCalendarView: boolean
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  clickableFieldProps: IClickableFieldProps
  availableFlightFields?: IMediaPlanMetaField[]
  isLoadingHierarchies: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  getFieldError: (clientMediaPlanFieldId: number, flightGroup: IFlightGroup) => string
  calendarView: CalendarView
  isSubtotalGroup: boolean
  originalFlightGroupIndex: number
  flightGroupIndex: number
  isCollapsed: boolean
  canDragFlightGroup: boolean
  selectedFlightGroupsCount: number
  flightGroup: IFlightGroup
  collapsedFlightGroups: number[]
  sortedFlightFields: IMediaPlanTemplateFields[]
  fillPoint: IPoint
  onSelect: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    point: IPoint,
    flightGroupSelection: Partial<IFlightGroup>,
    isSelected: boolean
  ) => void
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
}

const FlightGroupContainerInner: FunctionComponent<IProps> = ({
  calculationMode,
  flightGroupArrayLength,
  masteredListsData,
  mediaPlanFields,
  availableMediaPlanFields,
  tokenHandler,
  onAvailableFieldSelected,
  handleAddField,
  removePlanField,
  getStickyStyle,
  unfilteredHierarchies,
  displayFlightDates,
  expandedHierarchiesFlightGroupsValues,
  collapsedViewField,
  compressedCalendarView,
  masteredDataHelperValues,
  planHierarchies,
  clickableFieldProps,
  availableFlightFields,
  isLoadingHierarchies,
  financeListFieldsData,
  getFieldError,
  calendarView,
  flightGroupIndex,
  isSubtotalGroup,
  originalFlightGroupIndex,
  isCollapsed,
  canDragFlightGroup,
  selectedFlightGroupsCount,
  flightGroup,
  collapsedFlightGroups,
  sortedFlightFields,
  fillPoint,
  onSelect,
  canInsertFlightGroup,
  resetFlightGroups,
  copyFlightGroups,
  insertFlightGroups,
  upsertFlightGroups,
  toggleFlightGroup,
  deleteFlightGroup,
  duplicateFlightGroup,
  clearSelection,
  checkIsCopied,
  checkCanUpsert,
  checkCanCopySelection,
  linkedLookupFieldsHelperValues
}: IProps) => {
  return (
    <FlightGroupRow
      flightGroupId={flightGroup.mediaPlanFlightGroupId}
      flightGroupIndex={flightGroupIndex}
      isCopied={checkIsCopied(flightGroup)}
      isCollapsed={isCollapsed}
      canCopyFlightGroup={checkCanCopySelection}
      onInsertFlightGroups={insertFlightGroups}
      onClearSelection={clearSelection}
      isSubtotalGroup={isSubtotalGroup}
    >
      <ButtonFlightGroupComponent
        collapse={isCollapsed}
        flightGroupArrayLength={flightGroupArrayLength}
        flightGroupId={flightGroup.mediaPlanFlightGroupId}
        flightGroupIndex={flightGroupIndex}
        canInsert={canInsertFlightGroup}
        getStickyStyle={getStickyStyle}
        onCollapse={toggleFlightGroup}
        onDeleteFlightGroup={deleteFlightGroup}
        onDuplicateFlightGroup={duplicateFlightGroup}
        onSelect={onSelect}
        onReset={resetFlightGroups}
        onCopy={copyFlightGroups}
        onInsert={insertFlightGroups}
        isSubtotalGroup={isSubtotalGroup}
        canDragFlightGroup={canDragFlightGroup}
        selectedFlightGroupsCount={selectedFlightGroupsCount}
      />
      {!isSubtotalGroup ? (
        mediaPlanFields.map((flightGroupField, flightGroupFieldIndex) => {
          const canFill = !!fillPoint && fillPoint.flightGroupIndex === originalFlightGroupIndex && fillPoint.flightGroupFieldIndex === flightGroupFieldIndex
          const dataKey = `${flightGroupField.mediaPlanVersionFieldId > 0
            ? flightGroupField.mediaPlanVersionFieldId
            : flightGroupField.clientMediaPlanFieldId
          }-${originalFlightGroupIndex}-${flightGroupFieldIndex}`
          return (
            <FlightGroupFieldContainer
              availableMediaPlanFields={availableMediaPlanFields}
              calculationMode={calculationMode}
              collapse={isCollapsed}
              collapsedFlightGroups={collapsedFlightGroups}
              flightGroupIndex={flightGroupIndex}
              flightGroupFieldIndex={flightGroupFieldIndex}
              key={flightGroupField.mediaPlanVersionFieldId}
              masteredListsData={masteredListsData}
              mediaPlanField={flightGroupField}
              tokenHandler={tokenHandler}
              getStickyStyle={getStickyStyle}
              onAvailableFieldSelected={onAvailableFieldSelected}
              onSelectFlightGroups={onSelect}
              onResetFlightGroups={resetFlightGroups}
              onCopyFlightGroups={copyFlightGroups}
              onUpsertFlightGroups={upsertFlightGroups}
              isCopied={checkIsCopied(flightGroup, flightGroupField)}
              isNewlyAdded={flightGroup.mediaPlanFlightGroupId < 0}
              canUpsert={checkCanUpsert}
              canCopy={checkCanCopySelection}
              onClearSelection={clearSelection}
              canFill={canFill}
              dataFillKey={dataKey}
              linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
              unfilteredHierarchies={unfilteredHierarchies}
              expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
              masteredDataHelperValues={masteredDataHelperValues}
              flightGroupId={flightGroup.mediaPlanFlightGroupId}
              clickableFieldProps={clickableFieldProps}
              originalIndex={originalFlightGroupIndex}
              isLoadingHierarchies={isLoadingHierarchies}
              financeListFieldsData={financeListFieldsData}
            />
          )
        })) : (
        mediaPlanFields.map((flightGroupField) => {
          const columnName = getFieldColumnName(flightGroupField)
          const fieldId = flightGroupField.mediaPlanTemplateFieldId || flightGroupField.mediaPlanVersionFieldId
          const collapsedFlightGroup = collapsedFlightGroups.includes(fieldId)

          return (
            <SubtotalsFlightGroupFieldContainer
              key={flightGroupField.mediaPlanVersionFieldId}
              flightGroupField={flightGroupField}
              fieldValue={flightGroup[`subtotal${formatUpperCaseFirstLetter(columnName)}`]}
              fieldId={fieldId}
              collapsedFlightGroup={collapsedFlightGroup}
              getStickyStyle={getStickyStyle}
              flightGroupIndex={flightGroupIndex}
              expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
              hierarchies={planHierarchies}
            />
          )
        })
      )}
      {
        calendarView !== 'table'
          ? (
            <>
              <FlightsGridLabelsContainer
                flightGroup={flightGroup}
                calculationMode={calculationMode}
                handleAddField={handleAddField}
                flightGroupIndex={flightGroupIndex}
                onAvailableFieldSelected={onAvailableFieldSelected}
                removePlanField={removePlanField}
                getStickyStyle={getStickyStyle}
                getFieldError={getFieldError}
                isCollapsed={isCollapsed}
                collapsedViewField={collapsedViewField}
                clickableFieldProps={clickableFieldProps}
                isSubtotalGroup={isSubtotalGroup}
                availableFlightFields={availableFlightFields}
                displayFlightDates={displayFlightDates}
              />
              <FlightsGridSectionContainer
                calculationMode={calculationMode}
                handleAddField={handleAddField}
                flightGroupId={flightGroup.mediaPlanFlightGroupId}
                flightGroupIndex={flightGroupIndex}
                masteredListsData={masteredListsData}
                onAvailableFieldSelected={onAvailableFieldSelected}
                removePlanField={removePlanField}
                tokenHandler={tokenHandler}
                calendarView={calendarView}
                unfilteredHierarchies={unfilteredHierarchies}
                linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
                isCollapsed={isCollapsed}
                collapsedViewField={collapsedViewField}
                compressedCalendarView={compressedCalendarView}
                clickableFieldProps={clickableFieldProps}
                masteredDataHelperValues={masteredDataHelperValues}
                isSubtotalGroup={isSubtotalGroup}
                flightGroup={flightGroup}
                availableFlightFields={availableFlightFields}
                displayFlightDates={displayFlightDates}
                financeListFieldsData={financeListFieldsData}
              />
            </>
          )
          : (
            <FlightsTableContainer
              calculationMode={calculationMode}
              collapse={isCollapsed}
              tokenHandler={tokenHandler}
              unfilteredHierarchies={unfilteredHierarchies}
              masteredListsData={masteredListsData}
              onAvailableFieldSelected={onAvailableFieldSelected}
              flightGroupIndex={flightGroupIndex}
              flightFields={sortedFlightFields}
              getStickyStyle={getStickyStyle}
              linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
              clickableFieldProps={clickableFieldProps}
              masteredDataHelperValues={masteredDataHelperValues}
              financeListFieldsData={financeListFieldsData}
              collapsedFlightGroups={collapsedFlightGroups}
            />
          )
      }
    </FlightGroupRow>
  )
}

const FlightGroupContainer = memo(FlightGroupContainerInner)

const FlightGroupContainerWrapper: FunctionComponent<Omit<IProps, 'linkedLookupFieldsHelperValues'>> = ({ flightGroup, ...props }) => {
  const dataRelevantForLevel = useDataRelevantForLevel(props.flightGroupIndex)
  const memoizedFlightLevelGroupData = useDeepEqualMemoized(dataRelevantForLevel)
  const linkedLookupFieldsHelperValues = usePlanLinkedLookups(memoizedFlightLevelGroupData)

  const memoizedFlightGroup = useDeepEqualMemoized(flightGroup)

  return <FlightGroupContainer {...props} flightGroup={memoizedFlightGroup} linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues} />
}

export default memo(FlightGroupContainerWrapper)
