import React from 'react'
import { Button, Tooltip } from 'antd'
import { EditOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

interface ITabularLookupFieldActionsProps {
  onRemove: () => void
  onAdd: () => void
  onEdit?: () => void
  canRemove: boolean
  disabled: boolean
  isReadOnly: boolean
}

export const TabularLookupFieldActionsComponent: React.FC<ITabularLookupFieldActionsProps> = ({
  onAdd,
  onRemove,
  onEdit,
  canRemove,
  disabled,
  isReadOnly
}) => (
  <div className='icon-group'>
    <Tooltip title={'Edit value'}>
      <Button
        data-testid='edit-value'
        type='link'
        icon={<EditOutlined />}
        onClick={onEdit}
        disabled={isReadOnly}
      />
    </Tooltip>
    <Tooltip title='Add value'>
      <Button
        className='plus-icon icon-btn'
        data-testid='plus-icon'
        type='link'
        icon={<PlusCircleOutlined />}
        onClick={onAdd}
        disabled={isReadOnly}
      />
    </Tooltip>
    {
      canRemove && (
        <Tooltip title='Remove value'>
          <Button
            className='minus-icon'
            data-testid='remove-value'
            type='link'
            icon={<MinusCircleOutlined />}
            onClick={onRemove}
            disabled={disabled}
          />
        </Tooltip>
      )
    }
  </div>
)

export default TabularLookupFieldActionsComponent

