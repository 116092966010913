import { message } from 'antd'
import moment from 'moment/moment'
import {
  useLazyGetTabularLookupsByLookupIdQuery,
  PutTabularLookupsByLookupIdApiResponse,
  GetTabularLookupsByLookupIdApiArg,
  TabularLookupWeb
} from 'Apis/generated/tabularLookupApi'
import { usePutTabularLookupsByLookupIdMutation } from 'Apis/enhancedTabularLookupApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { UseMutationCommand } from '../../../redux/types'

interface ITabularLookupWebExtended extends TabularLookupWeb {
  deletedOn?: string
}

export const useDeleteTabularLookup = (): UseMutationCommand<
Omit<GetTabularLookupsByLookupIdApiArg, 'clientId'>,
PutTabularLookupsByLookupIdApiResponse
> => {
  const { data: currentClient } = useCurrentClient()
  const [getTabularLookup, { isLoading: loadingTabularLookup }] = useLazyGetTabularLookupsByLookupIdQuery()
  const [deleteTabularLookup, { isLoading: deletingTabularLookup }] = usePutTabularLookupsByLookupIdMutation()

  return {
    isLoading: deletingTabularLookup || loadingTabularLookup,
    execute: async ({ lookupId }) => {
      try {
        const tabularLookup = await getTabularLookup({ lookupId, clientId: currentClient.id }).unwrap()

        const response = await deleteTabularLookup({
          lookupId: lookupId?.toString(),
          clientId: currentClient.id,
          tabularLookupWeb: {
            ...tabularLookup,
            deletedOn: moment().utc().startOf('day').toISOString()
          } as ITabularLookupWebExtended
        }).unwrap()

        message.success('Tabular lookup has been successfully deleted')

        return response
      } catch {
        message.error('Something went wrong')
      }
    }
  }
}
