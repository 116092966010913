import React, { FunctionComponent, CSSProperties, useMemo } from 'react'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { getClientHierarchyValue, getHierarchyValue, IHierarchyName, IClientHierarchyName, IMSHierarchies } from '@mindshare/layout'
import MediaPlanInputComponent from 'Components/MediaPlanInputComponent'
import EmptyCellComponent from 'Components/MediaPlanVersion/EmptyCellComponent'
import { IMediaPlanTemplateFields, getFieldId } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { numberFormatter } from 'Helpers/numberHelper'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { HIERARCHY_TYPES_MAP } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { FieldDataType } from 'Constants/enums/FieldDataType'

interface IProps {
  flightGroupField: IMediaPlanTemplateFields
  fieldValue: string
  fieldId: number
  collapsedFlightGroup: boolean
  getStickyStyle: (type: StickyColumnType, id: number) => CSSProperties
  flightGroupIndex: number
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  hierarchies: IMSHierarchies
}

export const SubtotalsFlightGroupFieldContainer: FunctionComponent<IProps> = ({
  flightGroupField,
  fieldValue,
  fieldId,
  collapsedFlightGroup,
  getStickyStyle,
  flightGroupIndex,
  expandedHierarchiesFlightGroupsValues,
  hierarchies
}) => {
  const id = getFieldId(flightGroupField)

  const expandedHierarchiesValues = useMemo(() => {
    return expandedHierarchiesFlightGroupsValues[flightGroupIndex]
  }, [expandedHierarchiesFlightGroupsValues, flightGroupIndex])

  const expandedHierarchyValue = useMemo(() => {
    if (
      !flightGroupField.isExpandedHierarchyField ||
      !expandedHierarchiesValues
    ) {
      return null
    }
    const expandedHierarchyNode =
      expandedHierarchiesValues[flightGroupField.expandedHierarchyTypeId][
        flightGroupField.expandedHierarchyLevel
      ]
    const hierarchyType =
      HIERARCHY_TYPES_MAP[flightGroupField.expandedHierarchyTypeId]
    const isMasteredHierarchyType =
      Number(flightGroupField.expandedHierarchyTypeId) ===
        FieldDataType.FINANCE_STATION_HIERARCHY ||
      Number(flightGroupField.expandedHierarchyTypeId) ===
        FieldDataType.FINANCE_PRODUCT_HIERARCHY ||
      Number(flightGroupField.expandedHierarchyTypeId) ===
        FieldDataType.COST_BUYING_ROUTE_HIERARCHY
    const expandedHierarchyDisplayValue =
      expandedHierarchyNode &&
      (isMasteredHierarchyType
        ? getHierarchyValue(
          hierarchies,
          hierarchyType as IHierarchyName,
          expandedHierarchyNode.id
        )
        : getClientHierarchyValue(
          hierarchies,
          hierarchyType as IClientHierarchyName,
          expandedHierarchyNode.id
        ))

    return expandedHierarchyDisplayValue ?? ''
  }, [flightGroupField, expandedHierarchiesValues, hierarchies])
  const value = expandedHierarchyValue ?? fieldValue

  return (
    <td
      key={flightGroupField.mediaPlanVersionFieldId}
      className={classNames('flightGroup__field -subtotal', {
        '-collapsed': collapsedFlightGroup,
        '-internal':
          flightGroupField.clientMediaPlanField.isApportioned && value
      })}
      data-testid='flight-group-field-container'
      style={getStickyStyle(StickyColumnType.flightGroup, id)}
    >
      {value ? (
        <Tooltip
          title={numberFormatter(
            value,
            flightGroupField.clientMediaPlanField.mediaPlanField
              .fieldDataTypeId
          )}
        >
          <div className='fieldInput '>
            {!collapsedFlightGroup ? (
              <MediaPlanInputComponent
                id={fieldId}
                mediaPlanField={flightGroupField}
                value={value}
                newValue={value}
                readOnly={true}
                disabled={true}
                fieldTypeClasses='input-flight-group-value'
              />
            ) : (
              <div className='-value' data-testid='collapse-value'>
                {numberFormatter(
                  value,
                  flightGroupField.clientMediaPlanField.mediaPlanField
                    .fieldDataTypeId
                )}
              </div>
            )}
          </div>
        </Tooltip>
      ) : (
        <EmptyCellComponent fieldLabel={flightGroupField.fieldLabel} />
      )}
    </td>
  )
}

export default SubtotalsFlightGroupFieldContainer
