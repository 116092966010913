import React, { CSSProperties, FunctionComponent } from 'react'
import { removePlanField } from 'Actions/mediaPlansActions'
import CalendarComponent from 'Components/Calendar/CalendarComponent'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import FlightsHeaderComponent from 'Components/MediaPlanVersion/FlightsHeaderComponent'
import { flightStartDate, flightEndDate } from 'Components/MediaPlanVersion/constants/entities/FlightDates'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectFlightFields, selectHasAvailableFlightFields, selectPlanStartDate, selectPlanEndDate, selectCalendarView } from '../../selectors'

interface IProps {
  handleAddField: (fieldType: FieldLevelType) => void
  getStickyStyle?: (type: StickyColumnType, id?: number) => CSSProperties
  compressedCalendarView: boolean
}

export const FlightsHeaderContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    handleAddField,
    getStickyStyle,
    compressedCalendarView
  } = props

  const startDayOfWeek = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion.parseData?.startDayOfWeek || 'Monday')
  const planStartDate = useAppSelector(selectPlanStartDate)
  const planEndDate = useAppSelector(selectPlanEndDate)
  const calendarView = useAppSelector(selectCalendarView)
  const flightFields = useAppSelector(selectFlightFields)
  const hasAvailableFlightFields = useAppSelector(selectHasAvailableFlightFields)
  const dispatch = useAppDispatch()

  const removeField = (mediaPlanField: IMediaPlanTemplateFields) => dispatch(removePlanField(mediaPlanField))

  const sortedFlightFields = flightFields && [...flightFields, flightStartDate, flightEndDate].sort((a, b) => a.sortOrder - b.sortOrder)

  if (calendarView !== 'table') {
    return (
      <div className='flight-level-calendar'>
        <CalendarComponent
          calendarView={calendarView}
          startDate={planStartDate}
          endDate={planEndDate}
          dayStartOfWeek={startDayOfWeek}
          compressedCalendarView={compressedCalendarView}
        />
      </div>
    )
  } else {
    return (
      <FlightsHeaderComponent
        hasAvailableFlightFields={hasAvailableFlightFields}
        flightFields={sortedFlightFields}
        removePlanField={removeField}
        handleAddField={handleAddField}
        getStickyStyle={getStickyStyle}
      />
    )
  }
}

export default FlightsHeaderContainer

