import {
  formatUpperCaseFirstLetter,
  getClientHierarchyTypeDetails,
  getClientHierarchyValue,
  getHierarchyTypeDetails,
  getHierarchyValue,
  IMSHierarchies,
  msCurrencyFormatter,
  msNumberFormatter,
  msPercentFormatter
} from '@mindshare/layout'
import { FinanceAudience, FinanceBookingCategory } from 'Apis/generated/financeDataApi'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { formatDate } from './formatDate'

export const readClipboard = async (type: string = 'text/html'): Promise<string> => {
  const clipboardItems = await navigator.clipboard.read()
  if (!clipboardItems) return null
  const blob = await clipboardItems[0]?.getType(type)
  if (!blob) return null
  return await blob.text()
}

export const hasMAPClipboardData = (data: string): boolean => data && /^<!--\s*[^]*?\s*--><table>/.test(data)

export const getParsedClipboardData = <T>(rawData: string): T | string =>
  hasMAPClipboardData(rawData) ? (JSON.parse(rawData.match(/<!--([\s\S]*?)-->/)[1])) as T : rawData

export const writeClipboard = async <T>(
  data: T,
  flightGroupFields: IMediaPlanTemplateFields[],
  hierarchies: IMSHierarchies,
  masteredListsData: IMasteredListsData,
  financeListFieldsData: IMediaPlanVersionFinanceListFields
) => {
  const type = 'text/html'
  const blob = new Blob([`<!--${JSON.stringify(data)}-->${getHtmlTableString(data, flightGroupFields, hierarchies, masteredListsData, financeListFieldsData)}`], { type })
  await navigator.clipboard.write([new ClipboardItem({ [type]: blob })])
}

const getHtmlTableString = <T>(
  data: T,
  flightGroupFields: IMediaPlanTemplateFields[],
  hierarchies: IMSHierarchies,
  masteredListsData: IMasteredListsData,
  financeListFieldsData: IMediaPlanVersionFinanceListFields
) => {
  let tableStr = '<table>'
  Object.keys(data).forEach((rowId) => {
    tableStr += '<tr>'
    for (const [colName, colValue] of Object.entries(data[rowId])) {
      const field = flightGroupFields.find(f => f.clientMediaPlanField.mediaPlanField.columnName === formatUpperCaseFirstLetter(colName))
      if (field) {
        let colText: string | number
        const { fieldDataTypeId } = field.clientMediaPlanField.mediaPlanField
        switch (fieldDataTypeId) {
          case FieldDataType.CLIENT_DEFINED_LIST:
          case FieldDataType.UNIQUE_STRING:
            colText = field.clientMediaPlanField.clientFieldValues?.find(cf => cf.clientFieldValueId === colValue)?.valueDisplayName
            break
          case FieldDataType.BRAND_HIERARCHY:
          case FieldDataType.BUSINESS_HIERARCHY:
          case FieldDataType.GEOGRAPHY_HIERARCHY:
          case FieldDataType.MEDIA_HIERARCHY:
            // eslint-disable-next-line no-case-declarations
            const { hierarchyType: clientHierarchyType } = getClientHierarchyTypeDetails(colName, hierarchies)
            colText = getClientHierarchyValue(hierarchies, clientHierarchyType, Number(colValue))
            break
          case FieldDataType.FINANCE_PRODUCT_HIERARCHY:
          case FieldDataType.FINANCE_STATION_HIERARCHY:
            // eslint-disable-next-line no-case-declarations
            const { hierarchyType: masteredHierarchyType } = getHierarchyTypeDetails(colName, hierarchies)
            colText = getHierarchyValue(hierarchies, masteredHierarchyType, Number(colValue))
            break
          case FieldDataType.FINANCE_BOOKING_CATEGORY_LIST:
            // eslint-disable-next-line no-case-declarations
            const financeBookingListValues = financeListFieldsData[formatUpperCaseFirstLetter(colName)]?.data as FinanceBookingCategory[]
            colText = financeBookingListValues?.find(item => item.financeBookingCategoryId === colValue)?.bookingCategoryName
            break
          case FieldDataType.FINANCE_BUYING_AUDIENCE_LIST:
          case FieldDataType.FINANCE_TARGET_AUDIENCE_LIST:
            // eslint-disable-next-line no-case-declarations
            const financeAudienceValues = financeListFieldsData[formatUpperCaseFirstLetter(colName)]?.data as FinanceAudience[]
            colText = financeAudienceValues?.find(item => item.financeAudienceId === colValue)?.audienceName
            break
          case FieldDataType.MEDIA_PARTNERS:
            colText = masteredListsData.mediaPartners.find(mp => mp.clientMediaPartnerId === colValue)?.mediaPartnerValue
            break
          case FieldDataType.CLIENT_CAMPAIGNS:
            colText = masteredListsData.clientCampaigns.find(cc => cc.clientCampaignId === colValue)?.clientCampaignName
            break
          case FieldDataType.CLIENT_AGENCY_LIST:
            colText = masteredListsData.clientAgencies.find(ca => ca.agencyId === colValue)?.agencyDisplayName
            break
          case FieldDataType.DATE:
            colText = formatDate(colValue.toString(), 'DD-MMM-YYYY')
            break
          case FieldDataType.PERCENTAGE:
            colText = msPercentFormatter(colValue.toString())
            break
          case FieldDataType.INTEGER:
            colText = msNumberFormatter(colValue.toString())
            break
          case FieldDataType.CURRENCY:
            colText = msCurrencyFormatter(colValue.toString())
            break
          case FieldDataType.DECIMAL:
            colText = msNumberFormatter(colValue.toString())
            break
          default:
            colText = colValue.toString()
        }
        tableStr += `<td>${colText ?? ''}</td>`
      }
    }
    tableStr += '</tr>'
  })
  tableStr += '</table>'
  return tableStr
}
