import React, { CSSProperties, FC, memo, useMemo, useState } from 'react'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import { IMSHierarchies } from '@mindshare/layout'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import { getFieldId, IMediaPlanTemplateFields, getFieldColumnName, IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { IPoint } from 'Helpers/selectionHelper'
import { useFieldValue, useClickableField, IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { useFlightGroupSelection } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelection'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import FieldContainer from './FieldTypeSwitchContainer'

interface IFlightGroupFieldActions {
  canReset: boolean
  canCopy: boolean
  canUpsert: boolean
  onReset: () => void
  onCopy: () => void
  onUpsert: () => void
  children: React.JSX.Element
}

const FlightGroupFieldActions: FC<IFlightGroupFieldActions> = ({
  children,
  canReset,
  canCopy,
  canUpsert,
  onReset,
  onCopy,
  onUpsert
}) => (
  <Dropdown
    menu={{
      items: [
        {
          key: 'copy',
          label: 'Copy',
          disabled: !canCopy,
          onClick: onCopy
        },
        {
          key: 'paste',
          label: 'Paste',
          disabled: !canUpsert,
          onClick: onUpsert
        },
        {
          key: 'clear-data',
          label: 'Clear Data',
          disabled: !canReset,
          onClick: onReset
        }
      ]
    }}
    trigger={['contextMenu']}
    overlayClassName='flightGroup__context-menu'
  >
    {children}
  </Dropdown>
)

interface IProps {
  collapse: boolean
  calculationMode: boolean
  mediaPlanField: IMediaPlanTemplateFields
  collapsedFlightGroups: number[]
  masteredListsData: IMasteredListsData
  flightGroupIndex: number
  flightGroupFieldIndex: number
  availableMediaPlanFields: IMediaPlanTemplateFields[]
  tokenHandler: (token: string) => void
  getStickyStyle: (type: StickyColumnType, id: number) => CSSProperties
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  onSelectFlightGroups: (event: React.MouseEvent<HTMLElement, MouseEvent>, point: IPoint, flightGroupSelection: Partial<IFlightGroup>, isSelected: boolean) => void
  onResetFlightGroups: (flightGroupSelection: Partial<IFlightGroup>) => void
  onCopyFlightGroups: (flightGroupSelection: Partial<IFlightGroup>) => void
  onUpsertFlightGroups: (
    clientId: number,
    value: string | number,
    mediaPlanField: IMediaPlanMetaField,
    flightGroupSelection: Partial<IFlightGroup>,
    flightGroupIndex: number,
    flightGroupFieldIndex: number,
    flightIndex?: number
  ) => void
  isCopied: boolean
  isNewlyAdded: boolean
  canUpsert: (upsertAt: IPoint) => Promise<boolean>
  canCopy: (flightGroupSelection: Partial<IFlightGroup>) => boolean
  onClearSelection: () => void
  canFill: boolean
  dataFillKey: string
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  unfilteredHierarchies: IMSHierarchies
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  flightGroupId: number
  clickableFieldProps: IClickableFieldProps
  originalIndex: number
  isLoadingHierarchies: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
}

export const FlightGroupFieldContainer = (props: IProps) => {
  const {
    flightGroupIndex,
    flightGroupFieldIndex,
    calculationMode,
    mediaPlanField,
    collapsedFlightGroups,
    masteredListsData,
    availableMediaPlanFields,
    isCopied,
    isNewlyAdded,
    canUpsert,
    canCopy,
    tokenHandler,
    onAvailableFieldSelected,
    onSelectFlightGroups,
    onResetFlightGroups,
    onCopyFlightGroups,
    onUpsertFlightGroups,
    getStickyStyle,
    onClearSelection,
    canFill,
    dataFillKey,
    linkedLookupFieldsHelperValues,
    unfilteredHierarchies,
    expandedHierarchiesFlightGroupsValues,
    masteredDataHelperValues,
    flightGroupId,
    clickableFieldProps,
    originalIndex,
    isLoadingHierarchies,
    financeListFieldsData
  } = props
  const [isPasteEnabled, setIsPasteEnabled] = useState(false)
  const { isFieldValidForClick } = useClickableField(clickableFieldProps)
  const id = getFieldId(mediaPlanField)
  const columnName = getFieldColumnName(mediaPlanField)
  const valid = isFieldValidForClick(mediaPlanField)

  const fieldValue = useFieldValue({
    fieldLevelId: mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId,
    columnName,
    flightGroupIndex
  })

  const { isSelected, flightGroupSelection } = useFlightGroupSelection({ mediaPlanFlightGroupId: flightGroupId, mediaPlanFieldColumnName: columnName }) ?? {
    isSelected: false,
    flightGroupSelection: null
  }

  const { data: currentClient } = useCurrentClient()
  const expandedHierarchiesValues = useMemo(() => {
    return expandedHierarchiesFlightGroupsValues[originalIndex]
  }, [expandedHierarchiesFlightGroupsValues, originalIndex])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
    const handlers = {
      'Escape': onClearSelection
    }
    const handlerKey = [(event.ctrlKey || event.metaKey) && 'ctrl', event.code].filter(Boolean).join('+')
    const handler = handlers[handlerKey]

    if (handler) {
      handler()
    }
  }

  return (
    <FlightGroupFieldActions
      canReset={isSelected}
      canCopy={isSelected && canCopy(flightGroupSelection)}
      canUpsert={isPasteEnabled}
      onReset={() => onResetFlightGroups(flightGroupSelection)}
      onCopy={() => onCopyFlightGroups(flightGroupSelection)}
      onUpsert={() => onUpsertFlightGroups(currentClient.id, fieldValue, mediaPlanField, flightGroupSelection, flightGroupIndex, flightGroupFieldIndex)}
    >
      {id !== 0 ? (
        <td
          className={classNames({
            'flightGroup__field': true,
            '-valid': valid && mediaPlanField.fieldLabel !== 'Sort Order',
            '-collapsed': collapsedFlightGroups.includes(id),
            '-calculated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.CALCULATED,
            '-aggregated': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.AGGREGATED,
            '-internal': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.INTERNAL,
            '-shown-empty': mediaPlanField.templateFieldTypeId === TemplateFieldTypes.INTERNAL && !mediaPlanField.isReadOnly && isNewlyAdded,
            '-selected': isSelected,
            '-copied': isCopied
          })}
          onClick={(event) => onSelectFlightGroups(event, { flightGroupIndex, flightGroupFieldIndex }, flightGroupSelection, isSelected)}
          onContextMenu={async (event) => {
            onSelectFlightGroups(event, { flightGroupIndex, flightGroupFieldIndex }, flightGroupSelection, isSelected)
            setIsPasteEnabled(await canUpsert({ flightGroupFieldIndex, flightGroupIndex }))
          }}
          data-testid='flight-group-field-container'
          data-key={dataFillKey}
          style={getStickyStyle(StickyColumnType.flightGroup, id)}
          key={mediaPlanField.mediaPlanVersionFieldId}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
        >
          <FieldContainer
            calculationMode={calculationMode}
            field={mediaPlanField}
            masteredListsData={masteredListsData}
            tokenHandler={tokenHandler}
            valid={valid}
            flightGroupIndex={flightGroupIndex}
            defaultClass='input-flight-group-value'
            collapsedFlightGroup={collapsedFlightGroups.includes(id)}
            linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
            unfilteredHierarchies={unfilteredHierarchies}
            expandedHierarchiesValues={expandedHierarchiesValues}
            masteredDataHelperValues={masteredDataHelperValues}
            isLoadingHierarchies={isLoadingHierarchies}
            financeListFieldsData={financeListFieldsData}
          />
          {canFill && <div className='fill-handle' />}
        </td>
      ) : (
        <td className='flightGroup__field'>
          <FieldAvailableComponent
            onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue)}
            availableMediaPlanFields={availableMediaPlanFields}
          />
        </td>
      )}
    </FlightGroupFieldActions>
  )
}

export default memo(FlightGroupFieldContainer)
