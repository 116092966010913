import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTabularLookups: build.query<
      GetTabularLookupsApiResponse,
      GetTabularLookupsApiArg
    >({
      query: (queryArg) => ({
        url: `/tabularLookups`,
        params: {
          clientId: queryArg.clientId,
          mediaPlanId: queryArg.mediaPlanId,
          templateId: queryArg.templateId,
          includeDeleted: queryArg.includeDeleted,
        },
      }),
    }),
    getTabularLookupsByLookupId: build.query<
      GetTabularLookupsByLookupIdApiResponse,
      GetTabularLookupsByLookupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/tabularLookups/${queryArg.lookupId}`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    putTabularLookupsByLookupId: build.mutation<
      PutTabularLookupsByLookupIdApiResponse,
      PutTabularLookupsByLookupIdApiArg
    >({
      query: (queryArg) => ({
        url: `/tabularLookups/${queryArg.lookupId}`,
        method: "PUT",
        body: queryArg.tabularLookupWeb,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    postTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsTemplateTemplateId:
      build.mutation<
        PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsTemplateTemplateIdApiResponse,
        PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsTemplateTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/tabularLookups/${queryArg.lookupId}/ReturnValues/${queryArg.returnValueId}/valueSets/template/${queryArg.templateId}`,
          method: "POST",
          body: queryArg.tabularLookupFactValueSetWeb,
          params: {
            clientId: queryArg.clientId,
          },
        }),
      }),
    postTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsMediaPlanPlanId:
      build.mutation<
        PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsMediaPlanPlanIdApiResponse,
        PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsMediaPlanPlanIdApiArg
      >({
        query: (queryArg) => ({
          url: `/tabularLookups/${queryArg.lookupId}/ReturnValues/${queryArg.returnValueId}/valueSets/mediaPlan/${queryArg.planId}`,
          method: "POST",
          body: queryArg.tabularLookupFactValueSetWeb,
          params: {
            clientId: queryArg.clientId,
            templateLinkedValueSetId: queryArg.templateLinkedValueSetId,
          },
        }),
      }),
    putTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlan:
      build.mutation<
        PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanApiResponse,
        PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanApiArg
      >({
        query: (queryArg) => ({
          url: `/tabularLookups/${queryArg.lookupId}/returnValues/${queryArg.returnValueId}/valueSets/${queryArg.valueSetId}/mediaPlan`,
          method: "PUT",
          body: queryArg.tabularLookupFactValueSetWeb,
          params: {
            clientId: queryArg.clientId,
            mediaPlanId: queryArg.mediaPlanId,
          },
        }),
      }),
    putTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanTemplate:
      build.mutation<
        PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanTemplateApiResponse,
        PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/tabularLookups/${queryArg.lookupId}/returnValues/${queryArg.returnValueId}/valueSets/${queryArg.valueSetId}/mediaPlanTemplate`,
          method: "PUT",
          body: queryArg.tabularLookupFactValueSetWeb,
          params: {
            clientId: queryArg.clientId,
            mediaPlanTemplateId: queryArg.mediaPlanTemplateId,
          },
        }),
      }),
    getTabularLookupsDateDimensionTypes: build.query<
      GetTabularLookupsDateDimensionTypesApiResponse,
      GetTabularLookupsDateDimensionTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/tabularLookups/dateDimensionTypes`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    getTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanId:
      build.query<
        GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanIdApiResponse,
        GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanIdApiArg
      >({
        query: (queryArg) => ({
          url: `/tabularLookups/${queryArg.lookupId}/returnValues/${queryArg.valueId}/valueSets/mediaPlan/${queryArg.mediaPlanId}`,
          params: {
            clientId: queryArg.clientId,
          },
        }),
      }),
    getTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateId:
      build.query<
        GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateIdApiResponse,
        GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateIdApiArg
      >({
        query: (queryArg) => ({
          url: `/tabularLookups/${queryArg.lookupId}/returnValues/${queryArg.valueId}/valueSets/mediaPlanTemplate/${queryArg.mediaPlanTemplateId}`,
          params: {
            clientId: queryArg.clientId,
          },
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetTabularLookupsApiResponse =
  /** status 200 Returns list of tabular lookup without factValueSets */ TabularLookupWeb[];
export type GetTabularLookupsApiArg = {
  clientId: number;
  mediaPlanId?: number;
  templateId?: number;
  includeDeleted?: boolean;
};
export type GetTabularLookupsByLookupIdApiResponse =
  /** status 200 Returns list of tabular lookup without factValueSets */ TabularLookupWeb;
export type GetTabularLookupsByLookupIdApiArg = {
  clientId: number;
  lookupId?: number;
};
export type PutTabularLookupsByLookupIdApiResponse =
  /** status 200 Add new return value to tabular lookup and change it's editability */ TabularLookupWeb;
export type PutTabularLookupsByLookupIdApiArg = {
  clientId: number;
  lookupId?: string;
  tabularLookupWeb: TabularLookupWeb;
};
export type PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsTemplateTemplateIdApiResponse =
  /** status 200 Creates fact value set for tabular lookup return value */ TabularLookupFactValueSetWeb;
export type PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsTemplateTemplateIdApiArg =
  {
    clientId: number;
    lookupId?: number;
    returnValueId?: number;
    templateId?: number;
    tabularLookupFactValueSetWeb: TabularLookupFactValueSetWeb;
  };
export type PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsMediaPlanPlanIdApiResponse =
  /** status 200 Creates fact value set for tabular lookup return value */ TabularLookupFactValueSetWeb;
export type PostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsMediaPlanPlanIdApiArg =
  {
    clientId: number;
    lookupId?: number;
    returnValueId?: number;
    planId?: number;
    templateLinkedValueSetId?: number;
    tabularLookupFactValueSetWeb: TabularLookupFactValueSetWeb;
  };
export type PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanApiResponse =
  /** status 200 Updates fact value set for mediaPlan */ TabularLookupFactValueSetWeb;
export type PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanApiArg =
  {
    clientId: number;
    returnValueId?: number;
    mediaPlanId?: number;
    lookupId?: string;
    valueSetId?: string;
    tabularLookupFactValueSetWeb: TabularLookupFactValueSetWeb;
  };
export type PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanTemplateApiResponse =
  /** status 200 Updates fact value set for mediaPlanTemplate */ TabularLookupFactValueSetWeb;
export type PutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanTemplateApiArg =
  {
    clientId: number;
    returnValueId?: number;
    mediaPlanTemplateId?: number;
    lookupId?: string;
    valueSetId?: string;
    tabularLookupFactValueSetWeb: TabularLookupFactValueSetWeb;
  };
export type GetTabularLookupsDateDimensionTypesApiResponse =
  /** status 200 Returns list of tabular lookup date dimension types */ TabularLookupDateDimensionType[];
export type GetTabularLookupsDateDimensionTypesApiArg = {
  clientId: number;
};
export type GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanIdApiResponse =
  /** status 200 Returns tabular lookup fact value sets by valueId and mediaPlanId */ TabularLookupFactValueSetWeb[];
export type GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanIdApiArg =
  {
    clientId: number;
    valueId?: number;
    mediaPlanId?: number;
    lookupId?: string;
  };
export type GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateIdApiResponse =
  /** status 200 Returns tabular lookup fact value sets by valueId and mediaPlanTemplateId */ TabularLookupFactValueSetWeb[];
export type GetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateIdApiArg =
  {
    clientId: number;
    valueId?: number;
    mediaPlanTemplateId?: number;
    lookupId?: string;
  };
export type TabularLookupLevel = 0 | 1 | 2;
export type TabularLookupDimension = {
  tabularLookupDimensionId?: number;
  tabularLookupId?: number;
  mediaPlanFieldId?: number;
  sortOrder?: number;
};
export type TabularLookupDimensionValue = {
  tabularLookupDimensionValueId?: number;
  tabularLookupFactValueSetId?: number;
  tabularLookupDimensionId?: number;
  rowNumber?: number;
  lookupValueId?: number;
};
export type TabularLookupDateDimensionValue = {
  tabularLookupDateDimensionValueId?: number;
  tabularLookupFactValueSetId?: number;
  columnNumber?: number;
  dateNumeric?: number | null;
  startDate?: string | null;
  endDate?: string | null;
};
export type TabularLookupFact = {
  tabularLookupFactId?: number;
  tabularLookupFactValueSetId?: number;
  rowNumber?: number;
  columnNumber?: number | null;
  factInt?: number | null;
  factDecimal?: number | null;
  factString?: string | null;
};
export type TabularLookupFactValueSetWeb = {
  tabularLookupFactValueSetId?: number;
  tabularLookupReturnValueId?: number;
  validFrom?: string;
  validTo?: string;
  isDisabled?: boolean;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string | null;
  fieldsAxis?: {
    [key: string]: TabularLookupDimensionValue[] | null;
  } | null;
  dateAxis?: {
    [key: string]: TabularLookupDateDimensionValue;
  } | null;
  lookupSpace?: {
    [key: string]: TabularLookupFact;
  } | null;
};
export type TabularLookupReturnValueWeb = {
  tabularLookupReturnValueId?: number;
  tabularLookupId?: number;
  returnValueName?: string | null;
  returnValueDataTypeId?: number;
  factValueSets?: TabularLookupFactValueSetWeb[] | null;
};
export type TabularLookupWeb = {
  tabularLookupId?: number;
  lookupName?: string | null;
  tabularDateDimensionTypeId?: number;
  isEditable?: boolean;
  lookupLevel?: TabularLookupLevel;
  fieldsDimension?: TabularLookupDimension[] | null;
  returnValues?: TabularLookupReturnValueWeb[] | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string | null;
  deletedBy?: string | null;
  deletedOn?: string | null;
};
export type TabularLookupDateDimensionType = {
  tabularLookupDateDimensionTypeId?: number;
  dateDimensionTypeName?: string | null;
};
export const {
  useGetTabularLookupsQuery,
  useLazyGetTabularLookupsQuery,
  useGetTabularLookupsByLookupIdQuery,
  useLazyGetTabularLookupsByLookupIdQuery,
  usePutTabularLookupsByLookupIdMutation,
  usePostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsTemplateTemplateIdMutation,
  usePostTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsMediaPlanPlanIdMutation,
  usePutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanMutation,
  usePutTabularLookupsByLookupIdReturnValuesAndReturnValueIdValueSetsValueSetIdMediaPlanTemplateMutation,
  useGetTabularLookupsDateDimensionTypesQuery,
  useLazyGetTabularLookupsDateDimensionTypesQuery,
  useGetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanIdQuery,
  useLazyGetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanMediaPlanIdQuery,
  useGetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateIdQuery,
  useLazyGetTabularLookupsByLookupIdReturnValuesAndValueIdValueSetsMediaPlanTemplateMediaPlanTemplateIdQuery,
} = injectedRtkApi;
