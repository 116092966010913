import React, { useCallback, useMemo, useRef } from 'react'
import { Modal } from 'antd'
import isEmpty from 'lodash/isEmpty'
import xor from 'lodash/xor'
import { isNumber, isBlank, IMSHierarchies, showMSSuccessMessage } from '@mindshare/layout'
import * as mediaPlanActions from 'Actions/mediaPlansActions'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { getFieldColumnName, IMediaPlanMetaField, IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { getParsedClipboardData, hasMAPClipboardData, readClipboard, writeClipboard } from 'Helpers/clipboardHelpers'
import { useValueRef } from 'Hooks/useValueRef'
import {
  createFlightGroupRangeSelection,
  createFlightGroupSelection,
  createRangeFromPoints,
  IPoint
} from 'Helpers/selectionHelper'
import { pick } from 'Helpers/objectHelper'
import {
  canPasteFlightGroup,
  getShiftDistance,
  getStartOfCopy
} from 'Helpers/pasteHelper'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { useAppDispatch } from '../store'
import { IMasteredListsData } from './useMasteredListFieldsData'

export const useFlightGroupCommands = ({
  flightGroups,
  flightGroupFields,
  hierarchies,
  financeListFieldsData,
  masteredListsData,
  currentMasteredHierarchies,
  cachedMasteredHierarchies
}: {
  flightGroups: IFlightGroup[]
  flightGroupFields: IMediaPlanTemplateFields[]
  hierarchies: IMSHierarchies
  financeListFieldsData: IMediaPlanVersionFinanceListFields
  masteredListsData: IMasteredListsData
  currentMasteredHierarchies: Partial<IMSHierarchies>
  cachedMasteredHierarchies: Partial<IMSHierarchies>
}) => {
  const dispatch = useAppDispatch()

  const lastVisitedPointRef = useRef<IPoint>()

  const flightGroupsRef = useValueRef<IFlightGroup[]>(flightGroups)

  const flightGroupFieldsRef = useValueRef<IMediaPlanTemplateFields[]>(flightGroupFields)

  const masteredListsDataRef = useValueRef<Pick<IMasteredListsData, 'clientCampaigns' | 'clientAgencies' | 'mediaPartners'>>(masteredListsData)

  const checkIsCopied = useCallback(
    // TODO: MDSGLOMPT-5652 Should be fixed in next tickets - temporarily disabled copied fligtGroups highlighting
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (flightGroup: IFlightGroup, flightGroupField?: IMediaPlanTemplateFields) => {
      // TODO: This function enables highlighting of copied fligtGroups
      // TODO: This functionality should be revised for new copy paste mechanism using clipboard
      // TODO: At current the function will always return true if flightGroup selection is in the clipboard
      // const clipboardData = await readClipboard()
      // if (!hasMAPClipboardData(clipboardData)) {
      //   return false
      // }
      // const copiedFlightGroup = getParsedClipboardData<Partial<IFlightGroup>>(clipboardData)?.[flightGroup.mediaPlanFlightGroupId] ?? {}
      // const columnName = flightGroupField ? getFieldColumnName(flightGroupField) : ''

      // return copiedFlightGroup.hasOwnProperty('mediaPlanFlightGroupId') || copiedFlightGroup.hasOwnProperty(columnName)
      return false
    },
    []
  )

  const checkCanCopySelection = useCallback(
    (flightGroupSelection: Partial<IFlightGroup>): boolean => {
      const flightGroupSelections = Object.values(flightGroupSelection ?? {}).filter(obj => !isEmpty(obj) && !obj.subtotal)
      if (!flightGroupSelections?.length) {
        return false
      }

      if (flightGroupSelections?.every(selectedFlightGroup => selectedFlightGroup.hasOwnProperty('mediaPlanFlightGroupId'))) {
        return true
      }

      const selectedFieldsOfFirst = [...new Set(Object.keys(flightGroupSelections[0] ?? {}))]

      return flightGroupSelections.every(selectedFlightGroup => xor(Object.keys(selectedFlightGroup), selectedFieldsOfFirst).length === 0)
    },
    []
  )

  const canInsertFlightGroup = useCallback(
    async () => {
      const clipboardData = await readClipboard()
      if (!hasMAPClipboardData(clipboardData)) {
        return false
      }
      const onlyFlightGroupsInClipboard = Object.values(getParsedClipboardData(clipboardData))?.every(copiedRow => copiedRow.hasOwnProperty('mediaPlanFlightGroupId'))
      return onlyFlightGroupsInClipboard
    },
    []
  )

  const isUpsertAtCorrectPosition = useCallback((clipboardData: string, upsertAt: IPoint) => {
    const copiedFlightGroups = getParsedClipboardData<Partial<IFlightGroup>>(clipboardData)
    const startsAt = getStartOfCopy(flightGroupsRef.current, flightGroupFieldsRef.current, copiedFlightGroups)
    const shift = getShiftDistance(startsAt, upsertAt)

    return Object.values(copiedFlightGroups).every((copiedFlightGroup) => canPasteFlightGroup(
      copiedFlightGroup,
      flightGroupFieldsRef.current,
      shift,
      masteredListsDataRef.current
    ))
  }, [flightGroupFieldsRef, flightGroupsRef, masteredListsDataRef])

  const checkCanUpsert = useCallback(
    async (upsertAt: IPoint, allowNonMapPaste = false) => {
      const clipboardData = await readClipboard()
      const isMapPaste = hasMAPClipboardData(clipboardData)

      return (allowNonMapPaste && !isMapPaste) || (isMapPaste && isUpsertAtCorrectPosition(clipboardData, upsertAt))
    },
    [isUpsertAtCorrectPosition])

  const selectFlightGroups = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      currentPoint: IPoint,
      flightGroupSelection: Partial<IFlightGroup>,
      isSelected: boolean
    ) => {
      const flightGroup: IFlightGroup =
        flightGroupsRef.current[currentPoint.flightGroupIndex]
      const flightGroupField =
        flightGroupFieldsRef.current[currentPoint.flightGroupFieldIndex]
      const shouldToggleSelection = event.ctrlKey || event.metaKey
      const shouldSelectRange = event.shiftKey && !!lastVisitedPointRef.current
      const shouldPreserveSelection =
        (event.button === 2 && isSelected) ||
        flightGroupField?.templateFieldTypeId === TemplateFieldTypes.INTERNAL

      // 1. Open a context menu after clicking on a selected cell or row
      if (shouldPreserveSelection) {
        return
      }

      // 2. Select range of cells or rows
      if (shouldSelectRange) {
        const [inclusiveFrom, inclusiveTo] = createRangeFromPoints(
          lastVisitedPointRef.current,
          currentPoint
        )
        const flightGroupsRange = flightGroupsRef.current.slice(
          inclusiveFrom.flightGroupIndex,
          inclusiveTo.flightGroupIndex
        )
        const flightGroupFieldsRange = flightGroupFieldsRef.current.slice(
          inclusiveFrom.flightGroupFieldIndex,
          inclusiveTo.flightGroupFieldIndex
        )
        const newSelection = createFlightGroupRangeSelection(
          flightGroupsRange,
          flightGroupFieldsRange
        )

        dispatch(
          mediaPlanActions.replaceCurrentFlightGroupSelection(newSelection)
        ); return
      }

      // 3. Remember the current point as a starting point of a range selection
      // eslint-disable-next-line functional/immutable-data
      lastVisitedPointRef.current = currentPoint

      const columnName =
        flightGroupField && getFieldColumnName(flightGroupField)
      const newFlightGroupSelection = createFlightGroupSelection(
        flightGroup,
        columnName,
        flightGroupSelection?.[flightGroup.mediaPlanFlightGroupId],
        shouldToggleSelection
      )

      // 4. Add row or cell to selection if not selected, otherwise remove.
      if (shouldToggleSelection) {
        dispatch(
          mediaPlanActions.replaceFlightGroupSelection(
            newFlightGroupSelection,
            flightGroup?.mediaPlanFlightGroupId
          )
        ); return
      }

      // Clear copied and selected flights
      dispatch(mediaPlanActions.setSelectedFlights([]))
      dispatch(mediaPlanActions.setSelectedDates([]))
      dispatch(mediaPlanActions.copyFlight(null))
      // 5. Replace selection with a single row or cell.
      dispatch(
        mediaPlanActions.replaceCurrentFlightGroupSelection({
          [flightGroup.mediaPlanFlightGroupId]: newFlightGroupSelection
        })
      )
    },
    [flightGroupFieldsRef, flightGroupsRef, dispatch]
  )

  const clearSelection = useCallback(() => {
    dispatch(mediaPlanActions.clearFlightGroupSelection())
    // eslint-disable-next-line functional/immutable-data
    lastVisitedPointRef.current = undefined
  }, [dispatch])

  const copyFlightGroups = useCallback((flightGroupSelection: Partial<IFlightGroup>) => {
    const copiedFlightGroups = flightGroupsRef.current
      .reduce<typeof flightGroupSelection>((previousValue, flightGroup) => {
      const selectedFlightGroup = flightGroupSelection?.[flightGroup.mediaPlanFlightGroupId]

      if (selectedFlightGroup) {
        return {
          ...previousValue,
          [flightGroup.mediaPlanFlightGroupId]: pick<IFlightGroup, keyof IFlightGroup>(flightGroup, ...Object.keys(selectedFlightGroup))
        }
      }

      return previousValue
    }, {})

    writeClipboard(copiedFlightGroups, flightGroupFields, hierarchies, masteredListsData, financeListFieldsData)

    showMSSuccessMessage({ message: 'Copied to clipboard.' })

    // Clear copied and selected flights
    dispatch(mediaPlanActions.setSelectedFlights([]))
    dispatch(mediaPlanActions.setSelectedDates([]))
    dispatch(mediaPlanActions.copyFlight(null))
  }, [flightGroupsRef, dispatch, flightGroupFields, hierarchies, masteredListsData, financeListFieldsData])

  const resetFlightGroups = useCallback((flightGroupSelection: Partial<IFlightGroup>) => {
    Modal.confirm({
      title: 'Are you sure you would like to clear all selected data?',
      okText: 'Yes',
      onOk () {
        dispatch(mediaPlanActions.resetFlightGroups(flightGroupSelection))
      },
      cancelText: 'No'
    })
  }, [dispatch])

  const insertFlightGroups = useCallback(async (
    { shift, withFlights, flightGroupIndex, flightGroupSelection, eventIndex }:
    { shift: 0 | 1; withFlights: boolean; flightGroupIndex: number; flightGroupSelection?: Partial<IFlightGroup>; eventIndex?: number }
  ) => {
    const clipboardData = await readClipboard()
    if (!hasMAPClipboardData(clipboardData)) {
      return
    }

    const parsedClipboardData = getParsedClipboardData(clipboardData)
    const isFlightGroupFieldCopied = Object.values(parsedClipboardData).every((copiedFlightGroup) => !copiedFlightGroup.mediaPlanFlightGroupId)
    if (isFlightGroupFieldCopied) {
      return
    }

    const newFlightGroups = Object.values(parsedClipboardData ?? {})
      .filter(Boolean)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ mediaPlanFlightGroupId, flights, ...flightGroup }: IFlightGroup) => withFlights
        ? ({ ...flightGroup, flights })
        : flightGroup
      )

    if (typeof eventIndex === 'number') {
      dispatch(mediaPlanActions.insertFlightGroups({
        flightGroups: newFlightGroups,
        insertAt: eventIndex + 1
      }))

      return
    }

    // eslint-disable-next-line functional/immutable-data
    const withHighestOrder = Object.values(flightGroupSelection)
      .filter(Boolean)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .pop()
    const shouldInsertWithKeyboard = !isNumber(shift)

    if (!withHighestOrder) {
      return
    }

    const withHighestOrderIndex = flightGroupsRef.current.findIndex((flightGroup) =>
      flightGroup.mediaPlanFlightGroupId === withHighestOrder.mediaPlanFlightGroupId
    )
    const insertAt = shouldInsertWithKeyboard
      // insert below selected element with the highest sortOrder
      ? withHighestOrderIndex + 1
      // insert above/below the focused element
      : Math.max(flightGroupIndex + shift, 0)

    dispatch(mediaPlanActions.insertFlightGroups({
      flightGroups: newFlightGroups,
      insertAt
    }))
  }, [dispatch, flightGroupsRef])

  const upsertFlightGroups = useCallback(
    (
      clientId: number,
      flightGroupSelection: Partial<IFlightGroup>,
      clipboardData: string,
      mediaPlanField: IMediaPlanMetaField,
      flightGroupIndex: number,
      flightIndex?: number
    ) => {
      const parsedData = getParsedClipboardData<Partial<IFlightGroup>>(clipboardData)
      if (typeof parsedData === 'string') {
        dispatch(
          mediaPlanActions.pasteData(
            clientId,
            parsedData,
            mediaPlanField,
            flightGroupIndex,
            hierarchies,
            masteredListsData,
            financeListFieldsData,
            currentMasteredHierarchies,
            cachedMasteredHierarchies,
            flightIndex
          )
        )
      } else if (typeof parsedData === 'object') {
        dispatch(mediaPlanActions.upsertFlightGroups({
          flightGroups: Object.values(parsedData),
          flightGroupFields: flightGroupFieldsRef.current,
          flightGroupSelection
        }))
      }
    },
    [dispatch, flightGroupFieldsRef, financeListFieldsData, currentMasteredHierarchies, cachedMasteredHierarchies, hierarchies, masteredListsData]
  )

  const handleUpsertFlightGroups = useCallback(
    async (
      clientId: number,
      value: string | number,
      mediaPlanField: IMediaPlanMetaField,
      flightGroupSelection: Partial<IFlightGroup>,
      flightGroupIndex: number,
      flightIndex?: number
    ) => {
      let clipboardData = await readClipboard()
      if (!hasMAPClipboardData(clipboardData)) {
        clipboardData = await readClipboard('text/plain')
      }
      if (!clipboardData) {
        return
      }

      if (!isBlank(value)) {
        Modal.confirm({
          title: 'There\'s already data here. Do you want to replace it?',
          okText: 'Replace',
          onOk () {
            upsertFlightGroups(clientId, flightGroupSelection, clipboardData, mediaPlanField, flightGroupIndex, flightIndex)
          },
          cancelText: 'Cancel'
        })
      } else {
        upsertFlightGroups(clientId, flightGroupSelection, clipboardData, mediaPlanField, flightGroupIndex, flightIndex)
      }
    },
    [upsertFlightGroups]
  )

  const duplicateFlightGroup = useCallback((flightGroupIndex: number) => {
    dispatch(mediaPlanActions.duplicateFlightGroup(flightGroupIndex))
  }, [dispatch])

  const toggleFlightGroup = useCallback((flightGroupId: number) => {
    dispatch(mediaPlanActions.collapseFlightGroup(flightGroupId))
  }, [dispatch])

  const deleteFlightGroup = useCallback((flightGroupIndex: number) => {
    dispatch(mediaPlanActions.deleteFlightGroup(flightGroupIndex))
  }, [dispatch])

  return useMemo(() => ({
    canInsertFlightGroup,
    selectFlightGroups,
    resetFlightGroups,
    copyFlightGroups,
    insertFlightGroups,
    upsertFlightGroups: handleUpsertFlightGroups,
    toggleFlightGroup,
    deleteFlightGroup,
    duplicateFlightGroup,
    clearSelection,
    checkIsCopied,
    checkCanUpsert,
    checkCanCopySelection
  }), [
    canInsertFlightGroup,
    copyFlightGroups,
    deleteFlightGroup,
    duplicateFlightGroup,
    checkIsCopied,
    insertFlightGroups,
    handleUpsertFlightGroups,
    resetFlightGroups,
    selectFlightGroups,
    toggleFlightGroup,
    clearSelection,
    checkCanUpsert,
    checkCanCopySelection
  ])
}
