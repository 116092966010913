import React, { useMemo } from 'react'
import { Typography, Divider } from 'antd'
import { useGetTabularLookupsQuery } from 'Apis/enhancedTabularLookupApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { TabularLookupLevel } from 'Components/TabularLookup/enums/tabularLookupLevel'
import { useAppSelector } from '../../store'
import { selectCurrentMediaPlan } from '../../selectors'
import { TabularLookupListComponent } from './TabularLookupListComponent'

const { Title } = Typography

interface ITabularLookupPlanListProps {
  nextStep: () => void
  setTabularLookupId: (id: number) => void
  isTemplate: boolean
}

export const TabularLookupPlanListComponent: React.FC<ITabularLookupPlanListProps> = ({ nextStep, setTabularLookupId, isTemplate }) => {
  const { data: currentClient } = useCurrentClient()
  const currentMediaPlan = useAppSelector(selectCurrentMediaPlan)
  const { data: tabularLookups = [] } = useGetTabularLookupsQuery(
    { clientId: currentClient?.id, mediaPlanId: currentMediaPlan.mediaPlanId, templateId: currentMediaPlan.mediaPlanTemplateId }
  )
  const planLevelTabularLookups = useMemo(() => tabularLookups.filter((l) => l.lookupLevel === TabularLookupLevel.Plan), [tabularLookups])
  const templateLevelTabularLookups = useMemo(() => tabularLookups.filter((l) => l.lookupLevel === TabularLookupLevel.Template), [tabularLookups])

  return (
    <>
      {planLevelTabularLookups?.length > 0 && (
        <>
          <Title level={4}>Plan</Title>
          <TabularLookupListComponent
            tabularLookups={planLevelTabularLookups}
            nextStep={nextStep}
            setTabularLookupId={setTabularLookupId}
            isTemplate={isTemplate}
          />
        </>
      )}
      {templateLevelTabularLookups?.length > 0 && (
        <>
          <Divider />
          <Title level={4}>Template</Title>
          <TabularLookupListComponent
            tabularLookups={templateLevelTabularLookups}
            canRemove={false}
            nextStep={nextStep}
            setTabularLookupId={setTabularLookupId}
            isTemplate={isTemplate}
          />
        </>
      )}
    </>
  )
}

export default TabularLookupPlanListComponent
