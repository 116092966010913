import React from 'react'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import RichTextAvailableComponent from 'Components/RichTextAvailableComponent'
import { useAppSelector } from '../../store'
import { selectRichTextAvailablePlanLevelFields } from '../../selectors'
import PlanLevelRichTextFieldContainer from './PlanLevelRichTextFieldContainer'

interface IProps {
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  richTextPlanLevelFields: IMediaPlanTemplateFields[]
}

const PlanLevelRichTextFieldsContainer = ({
  removePlanField,
  onAvailableFieldSelected,
  richTextPlanLevelFields
}: IProps) => {
  const availablePlanFields = useAppSelector(selectRichTextAvailablePlanLevelFields)

  return (
    <>
      {
        richTextPlanLevelFields.map((mediaPlanField) => {
          const id = mediaPlanField.mediaPlanTemplateFieldId || mediaPlanField.mediaPlanVersionFieldId

          if (id === 0) {
            return (
              <RichTextAvailableComponent
                key={mediaPlanField.clientMediaPlanFieldId}
                availableMediaPlanFields={availablePlanFields}
                onAvailableFieldSelected={onAvailableFieldSelected} />
            )
          } else {
            return (
              <PlanLevelRichTextFieldContainer
                key={mediaPlanField.clientMediaPlanFieldId}
                mediaPlanField={mediaPlanField}
                removePlanField={removePlanField}
              />
            )
          }
        })
      }
    </>
  )
}

export default PlanLevelRichTextFieldsContainer
