import { useCallback } from 'react'
import { message } from 'antd'
import {
  createRedistribution,
  updateRedistribution
} from 'Actions/mediaPlansActions'
import { checkPercentageAmount } from 'Helpers/redistributionHelpers'
import { formApiRedistribution } from '../helpers/formApiRedistribution'
import { useAppDispatch, useAppSelector } from '../../../store'

export const useRedistributionOnSubmit = () => {
  const mediaPlanId = useAppSelector(state =>
    state.mediaPlans.currentMediaPlanVersion
      .parseData?.mediaPlanId || ''
  )
  const currentClientId = useAppSelector(state => state.app.currentClient.id)
  const dispatch = useAppDispatch()

  return useCallback(
    async values => {
      const { redistributionId, redistributeByValues } = values
      const isPercentageAmountValid = checkPercentageAmount(
        redistributeByValues
      )
      const action = redistributionId
        ? updateRedistribution(
          mediaPlanId,
          redistributionId,
          formApiRedistribution(values),
          currentClientId
        )
        : createRedistribution(mediaPlanId, formApiRedistribution(values), currentClientId)

      if (!isPercentageAmountValid) {
        message.error('The sum of percentages should be 100')
      } else {
        const result = dispatch(action)
        return await result
      }
    },
    [dispatch, mediaPlanId, currentClientId]
  )
}
