import { RootState } from 'Reducers/index'
import { createSelector } from 'reselect'

export const selectImports = (state: RootState) => state.imports

export const selectCurrentDataImport = createSelector(
  selectImports,
  imports => imports.currentDataImport
)

export const selectDataImports = createSelector(
  selectImports,
  imports => imports.dataImports
)
