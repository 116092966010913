import React, { useMemo } from 'react'
import { TabularLookupCreateStepComponent } from 'Components/TabularLookup/TabularLookupCreateStepComponent'
import { IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { useAppSelector } from '../../store'
import { selectCurrentMediaPlanVersion } from '../../selectors'

interface ITabularLookupPlanCreateProps {
  isTemplate: boolean
  tabularLookupId: number
}

export const TabularLookupPlanCreateComponent: React.FC<ITabularLookupPlanCreateProps> = (props) => {
  const currentMediaPlanVersion = useAppSelector(selectCurrentMediaPlanVersion)
  const lookupMediaPlanVersionFields = useMemo(
    () =>
      currentMediaPlanVersion.mediaPlanVersionFields.filter(
        (f: IMediaPlanVersionField) =>
          f.clientMediaPlanField.mediaPlanField.fieldDataType?.isLookupType
      ),
    [currentMediaPlanVersion.mediaPlanVersionFields]
  )

  return (
    <TabularLookupCreateStepComponent
      fields={lookupMediaPlanVersionFields}
      {...props}
    />
  )
}

export default TabularLookupPlanCreateComponent
