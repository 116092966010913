import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Button, Tooltip } from 'antd'
import classNames from 'classnames'
import { TabularLookupReturnValueWeb, TabularLookupDimension, useGetTabularLookupsByLookupIdQuery } from 'Apis/generated/tabularLookupApi'
import { IWizardStep, useWizardComponent } from 'Hooks/useWizardComponent'
import { TabularLookupTemplateListComponent } from 'Components/TabularLookup/TabularLookupTemplateListComponent'
import { TabularLookupTemplateCreateComponent } from 'Components/TabularLookup/TabularLookupTemplateCreateComponent'
import { Nullable } from 'Helpers/INullable'
import { TabularLookupPlanListComponent } from 'Components/TabularLookup/TabularLookupPlanListComponent'
import { TabularLookupPlanCreateComponent } from 'Components/TabularLookup/TabularLookupPlanCreateComponent'
import { useTabularLookupOnSubmit } from 'Components/TabularLookup/hooks/useTabularLookupOnSubmit'
import { initialValues } from 'Components/TabularLookup/hooks/useTabularLookupInitialValues'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import ModalComponent from '../ModalComponent'
import WizardFooterComponent from '../WizardFooterComponent'

export interface ITabularLookupFormData {
  lookupName: Nullable<string>
  fieldsDimension: TabularLookupDimension[]
  tabularDateDimensionTypeId: Nullable<number>
  isEditableAtPlanLevel: boolean
  returnValues: TabularLookupReturnValueWeb[]
}

interface ITabularLookupModalProps {
  isTemplate?: boolean
}

export const TabularLookupModalComponent: React.FC<ITabularLookupModalProps> = ({ isTemplate }) => {
  const form = useForm<ITabularLookupFormData>({
    defaultValues: initialValues
  })
  const { watch, reset, formState: { errors } } = form
  const lookupName = watch('lookupName')
  const fieldsDimension = watch('fieldsDimension')
  const tabularDateDimensionTypeId = watch('tabularDateDimensionTypeId')
  const isEditableAtPlanLevel = watch('isEditableAtPlanLevel')
  const returnValues = watch('returnValues')

  const [visible, setVisible] = useState<boolean>(false)
  const [tabularLookupId, setTabularLookupId] = useState<number>(null)
  const { data: currentClient } = useCurrentClient()
  const { data: currentTabularLookup } = useGetTabularLookupsByLookupIdQuery(
    { clientId: currentClient?.id, lookupId: tabularLookupId },
    { skip: !tabularLookupId }
  )

  const showModal = useCallback(() => setVisible(true), [])
  const hideModal = useCallback(() => {
    setVisible(false)
    reset(initialValues)
  }, [reset])

  const hasError = !!Object.keys(errors).length

  const onSubmit = useTabularLookupOnSubmit()

  const submitAndProceed = useCallback(async () => {
    const result = await onSubmit({
      lookupName,
      tabularDateDimensionTypeId,
      fieldsDimension,
      returnValues,
      isEditableAtPlanLevel,
      isTemplate,
      lookupId: tabularLookupId,
      currentTabularLookup
    })

    if ('error' in result) {
      return false
    }
    hideModal()
    return true
  }, [
    hideModal,
    lookupName,
    tabularDateDimensionTypeId,
    isEditableAtPlanLevel,
    fieldsDimension,
    returnValues,
    isTemplate,
    onSubmit,
    tabularLookupId,
    currentTabularLookup
  ])

  const wizardSteps: IWizardStep[] = useMemo(() => [
    {
      content: null,
      nextBtnConfig: {
        label: 'Add New'
      }
    },
    {
      content: (
        <FormProvider {...form}>
          {isTemplate ? (
            <TabularLookupTemplateCreateComponent
              isTemplate={isTemplate}
              tabularLookupId={tabularLookupId}
            />
          )
            : (
              <TabularLookupPlanCreateComponent
                isTemplate={isTemplate}
                tabularLookupId={tabularLookupId}
              />
            )}
        </FormProvider>
      ),
      nextBtnConfig: {
        label: 'Save',
        onBeforeNext: submitAndProceed,
        disabled: hasError || !fieldsDimension.length || !tabularDateDimensionTypeId,
        tooltipText: 'Please select fields before proceeding'
      },
      prevBtnConfig: {
        label: 'Back'
      }
    }
  ], [
    submitAndProceed,
    fieldsDimension.length,
    hasError,
    tabularDateDimensionTypeId,
    isTemplate,
    form,
    tabularLookupId
  ])

  const { currentStep, nextStep, prevStep, resetSteps } = useWizardComponent(wizardSteps)

  useEffect(() => {
    if (!visible && currentStep) {
      resetSteps()
    }
  }, [currentStep, visible, resetSteps])

  const handlePrevStep = useCallback(() => {
    reset(initialValues)
    setTabularLookupId(null)
    prevStep()
  }, [reset, prevStep])

  return (
    <>
      <Tooltip title='Tabular Data'>
        <Button
          className='ms-button'
          data-testid='tabular-data-button'
          onClick={showModal}
          type='primary'
        >
          Tabular Data
        </Button>
      </Tooltip>
      <ModalComponent
        className={classNames('tabular-lookup', { 'media-plan': !isTemplate })}
        data-testid='tabular-lookup-modal'
        title='Tabular Lookups'
        modalBody={currentStep === 0 ? (
          isTemplate ? (
            <TabularLookupTemplateListComponent
              nextStep={nextStep}
              setTabularLookupId={setTabularLookupId}
              isTemplate={isTemplate}
            />
          ) : (
            <TabularLookupPlanListComponent
              nextStep={nextStep}
              setTabularLookupId={setTabularLookupId}
              isTemplate={isTemplate}
            />
          )
        ) : wizardSteps[currentStep].content}
        footer={
          <WizardFooterComponent
            nextBtnConfig={{ ...wizardSteps[currentStep].nextBtnConfig, onClick: nextStep }}
            prevBtnConfig={wizardSteps[currentStep].prevBtnConfig ? { ...wizardSteps[currentStep].prevBtnConfig, onClick: handlePrevStep } : null}
          />
        }
        open={visible}
        onCancel={hideModal}
        width={900}
      />
    </>
  )
}

export default TabularLookupModalComponent
