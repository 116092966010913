import React from 'react'
import { Typography } from 'antd'
import { msPercentFormatter } from '@mindshare/layout'
import { ClientMediaPlanFieldRead } from 'Apis/generated/mediaPlanVersionsApi'
import { IRedistributeByValues } from 'Components/Redistribution/constants/entities/IRedistribution'
import { formatFieldValue } from 'Components/Redistribution/helpers/formatFieldValue'
import { useAppSelector } from '../../../store'
import { selectCurrentRedistribution, selectCurrentMediaPlanVersionFields } from '../../../selectors'

const { Paragraph, Text } = Typography

export const RedistributionDetailsComponent = () => {
  const currentRedistribution = useAppSelector(selectCurrentRedistribution)
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)

  if (!currentRedistribution) return null

  const mediaPlanField = mediaPlanVersionFields.find(
    field =>
      field.clientMediaPlanFieldId ===
      currentRedistribution.redistributeByClientMediaPlanFieldId
  )
  const fieldToRedistributeLabel =
    currentRedistribution.redistributeClientMediaPlanField?.fieldLabel ||
    currentRedistribution.redistributeClientMediaPlanField?.mediaPlanField
      .fieldLabel
  const fieldToRedistributeByLabel =
    currentRedistribution.redistributeByClientMediaPlanField?.fieldLabel ||
    currentRedistribution.redistributeByClientMediaPlanField?.mediaPlanField
      .fieldLabel
  const fieldDataTypeId = (mediaPlanField?.clientMediaPlanField?.mediaPlanField.fieldDataType)?.fieldDataTypeId ||
  ((mediaPlanField as ClientMediaPlanFieldRead)?.mediaPlanField?.fieldDataType)?.fieldDataTypeId

  return (
    <div className="redistribution-details">
      <Paragraph>Selected item details:</Paragraph>
      <div>
        <Text>Field to redistribute:</Text>
        <Text>{fieldToRedistributeLabel}</Text>
      </div>
      <div>
        <Text>Field to redistribute by:</Text>
        <Text>{fieldToRedistributeByLabel}</Text>
      </div>
      <div>
        <Paragraph>Redistribute by values:</Paragraph>
        <ul>
          {currentRedistribution.redistributeByValues.map((item: IRedistributeByValues) => {
            const displayValue = formatFieldValue(item.value, fieldDataTypeId)

            return (
              <li key={item.redistributeByValueId}>
                <Text>{displayValue}:</Text>
                <Text>{msPercentFormatter(item.percentage)}</Text>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
