import { useMemo } from 'react'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { createMediaPlanFieldErrorSelector } from '../../../selectors'
import { useAppSelector } from '../../../store'

export const useMediaPlanFieldError = (mediaPlanField: IMediaPlanTemplateFields) => {
  const selectMediaPlanFieldError = useMemo(createMediaPlanFieldErrorSelector, [])

  return useAppSelector(state => selectMediaPlanFieldError(state, mediaPlanField))
}
