import { createApi } from '@reduxjs/toolkit/query/react'
import { mediaPlanService } from '@mindshare/services'
const { baseQuery } = mediaPlanService

export const baseApi = createApi({
  baseQuery,
  tagTypes: [
    'clientAliasFields',
    'templateAliasFields',
    'templateList',
    'customExportsList',
    'customExportItem',
    'latestFlags',
    'displayTypes',
    'currencyDisplays',
    'fieldDataTypeOperators',
    'customExportsClientMediaPlanFields',
    'controlFields',
    'linkedLookupsList',
    'linkedLookupItem',
    'linkedLookupsMediaPlanFields',
    'clientSettings',
    'tabularLookupList'
  ],
  endpoints: () => ({})
})

export type BaseQueryApiArg = {
  clientId: number
}
