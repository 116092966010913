import React, { FunctionComponent, useCallback } from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import FieldAvailableComponent from 'Components/FieldAvailableComponent'
import { useAvailableFlightAndSubFlights } from 'Components/MediaPlanVersion/hooks/useAvailableFlightAndSubFlights'
import { useEnableDropDownField } from 'Components/MediaPlanVersion/hooks/useEnableDropDownField'

interface IProps {
  calculationMode: boolean
  flightGroupIndex: number
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
}

export const EditListFlightContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    calculationMode,
    flightGroupIndex,
    handleAddField,
    onAvailableFieldSelected
  } = props
  const handleEditList = useCallback(() => {
    handleAddField(FieldLevelType.FLIGHT, flightGroupIndex)
  }, [handleAddField, flightGroupIndex])

  const availableFlightsFields = useAvailableFlightAndSubFlights(flightGroupIndex)
  const enableDropDownField = useEnableDropDownField(flightGroupIndex)

  return (
    <>
      {enableDropDownField && (
        <FieldAvailableComponent
          onAvailableFieldSelected={(selectedValue: string) => onAvailableFieldSelected(selectedValue, flightGroupIndex)}
          availableMediaPlanFields={availableFlightsFields}
        />
      )
      }
      {
        availableFlightsFields.length > 0 && (
          <Button
            icon={<EditOutlined />}
            type='primary'
            className='btn-edit-list'
            onClick={handleEditList}
            disabled={calculationMode}
            tabIndex={-1}
          >
            Edit List
          </Button>
        )
      }
    </>
  )
}

export default EditListFlightContainer
