import React, { FunctionComponent } from 'react'
import { useMindshareSelector } from '@mindshare/layout'
import { selectCurrentClient } from '../../selectors'

const ReportContainer: FunctionComponent = () => {
  const currentClient = useMindshareSelector(selectCurrentClient)
  return (
    <iframe src={currentClient.mptReportUrl} width="100%" height="100%"/>
  )
}
export default ReportContainer
