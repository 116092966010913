import { useMemo } from 'react'
import { TabularLookupWeb } from 'Apis/generated/tabularLookupApi'
import { ITabularLookupFormData } from 'Components/TabularLookup/TabularLookupModalComponent'

export const initialValues: ITabularLookupFormData = {
  lookupName: undefined,
  fieldsDimension: [],
  tabularDateDimensionTypeId: undefined,
  returnValues: [
    {
      returnValueName: undefined,
      returnValueDataTypeId: undefined
    }
  ],
  isEditableAtPlanLevel: false
}

const getInitialValuesFromCurrentTabularLookup = (values: TabularLookupWeb) => ({
  lookupName: values.lookupName,
  fieldsDimension: values.fieldsDimension,
  tabularDateDimensionTypeId: values.tabularDateDimensionTypeId,
  returnValues: values.returnValues,
  isEditableAtPlanLevel: values.isEditable
})

export const useTabularLookupInitialValues = (tabularLookup: TabularLookupWeb | undefined) =>
  useMemo(
    () =>
      tabularLookup
        ? getInitialValuesFromCurrentTabularLookup(tabularLookup)
        : initialValues,
    [tabularLookup]
  )
