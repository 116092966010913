import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Tooltip } from 'antd'
import { FileSyncOutlined } from '@ant-design/icons'
import moment from 'moment'
import {
  ChildTemplate,
  TemplateSyncJobOption,
  useGetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesQuery,
  useGetSyncTemplateOptionsQuery,
  usePostSyncTemplateJobMutation
} from 'Apis/generated/templateSyncApi'
import { useMediaPlanTemplatesQuery } from 'Apis/enhancedTemplatesApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { IWizardStep, useWizardComponent } from 'Hooks/useWizardComponent'
import { useNotificationMessage } from 'Hooks/CustomHooks'
import { NotificationType } from 'Constants/enums/NotificationType'
import { useAppSelector } from '../../store'
import { selectCurrentMediaPlanTemplate, selectClients } from '../../selectors'
import ModalComponent from '../ModalComponent'
import WizardFooterComponent from '../WizardFooterComponent'
import TemplateSyncTemplatesSelectionStepComponent from './TemplateSyncTemplatesSelectionStepComponent'
import TemplateSyncOptionsSelectionStepComponent from './TemplateSyncOptionsSelectionStepComponent'
import TemplateSyncSummaryStepComponent from './TemplateSyncSummaryStepComponent'

const { SUCCESS, ERROR } = NotificationType

const defaultValues = {
  selectedClientTemplates: [],
  selectedLinkedTemplates: [],
  selectedTemplateOptions: [],
  selectedTemplateOptionsPerFields: {}
}

export interface ITemplateSyncChildTemplate extends ChildTemplate {
  clientDisplayName: string
  mediaPlanTemplateDisplayName: string
}

export interface ITemplateSyncOption extends TemplateSyncJobOption {
  isPerField: boolean
  templateSyncOptionName: string
}

export interface ITemplateSyncOptionPerField {
  fieldLabel: string
  fieldLevelId: number
  mediaPlanFieldLevelId: number
}

export interface ITemplateSyncFormData {
  selectedLinkedTemplates: ITemplateSyncChildTemplate[]
  selectedClientId: number
  selectedClientTemplates: ITemplateSyncChildTemplate[]
  selectedTemplateOptions: ITemplateSyncOption[]
  selectedTemplateOptionsPerFields: Record<number, ITemplateSyncOptionPerField[]>
  useExistingTemplate: boolean
}

interface ITemplateSyncModalComponentProps {
  isPublishDisabled: boolean
  onAfterPublish: () => void
}

const TemplateSyncModalComponent: React.FC<ITemplateSyncModalComponentProps> = ({ isPublishDisabled, onAfterPublish }) => {
  const form = useForm<ITemplateSyncFormData>({ defaultValues })
  const { control, setValue, watch, reset } = form

  const [visible, setVisible] = useState(false)
  const showModal = useCallback(() => setVisible(true), [])
  const hideModal = useCallback(() => {
    setVisible(false)
    reset(defaultValues)
  }, [reset])

  const selectedLinkedTemplatesList = watch('selectedLinkedTemplates')
  const selectedClientId = watch('selectedClientId')
  const useExistingTemplate = watch('useExistingTemplate')
  const selectedClientTemplatesList = watch('selectedClientTemplates')
  const selectedTemplateOptions = watch('selectedTemplateOptions')
  const selectedTemplateOptionsPerFields = watch('selectedTemplateOptionsPerFields')

  const mediaPlanTemplate = useAppSelector(selectCurrentMediaPlanTemplate)
  const { data: currentClient } = useCurrentClient()
  const { data: linkedChildTemplatesList, isLoading: isLinkedChildTemplatesLoading } = useGetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesQuery(
    { clientId: currentClient?.id, parentMediaPlanTemplateId: mediaPlanTemplate.mediaPlanTemplateId },
    { skip: !visible }
  )
  const { data: clientTemplatesList } = useMediaPlanTemplatesQuery({ clientId: selectedClientId }, { skip: !visible || !selectedClientId || !useExistingTemplate })
  const { data: templateOptionsList } = useGetSyncTemplateOptionsQuery({ clientId: currentClient?.id }, { skip: !visible })

  const clients = useAppSelector(selectClients)

  const [postSyncTemplateJob, { isLoading: isPostSyncTemplateJobLoading, isSuccess, isError, error }] = usePostSyncTemplateJobMutation()

  useNotificationMessage(
    isError,
    error && 'data' in error
      ? (Array.isArray(error.data) ? error.data.join('\n') : JSON.stringify(error.data))
      : 'Template sync job failed',
    ERROR
  )
  useNotificationMessage(isSuccess, 'Request submitted', SUCCESS)

  const submitAndProceed = useCallback(async () => {
    const result = await postSyncTemplateJob({
      clientId: currentClient?.id,
      postTemplateSyncJob: {
        templateSyncJobName: `${mediaPlanTemplate.templateName}_${moment().format('YYYY-MM-DD hh:mm:ss')}`,
        parentMediaPlanTemplateId: mediaPlanTemplate.mediaPlanTemplateId,
        childTemplates: [...selectedLinkedTemplatesList, ...selectedClientTemplatesList].map(t => ({
          clientId: t.clientId,
          mediaPlanTemplateId: t.mediaPlanTemplateId,
          isNewTemplate: t.isNewTemplate
        })),
        templateSyncJobOptions: selectedTemplateOptions.reduce((acc, o) => ([
          ...acc,
          ...(o.isPerField ? selectedTemplateOptionsPerFields[o.templateSyncOptionId].map(of => ({
            templateSyncOptionId: o.templateSyncOptionId,
            mediaPlanFieldLevelId: of.mediaPlanFieldLevelId
          })) : [{
            templateSyncOptionId: o.templateSyncOptionId,
            mediaPlanFieldLevelId: null
          }])
        ]), [])
      }
    })
    if ('error' in result) {
      return false
    }
    hideModal()
    onAfterPublish()
    return true
  }, [
    hideModal,
    onAfterPublish,
    currentClient?.id,
    mediaPlanTemplate.mediaPlanTemplateId,
    mediaPlanTemplate.templateName,
    postSyncTemplateJob,
    selectedClientTemplatesList,
    selectedLinkedTemplatesList,
    selectedTemplateOptions,
    selectedTemplateOptionsPerFields
  ])

  const wizardSteps: IWizardStep[] = useMemo(() => [
    {
      content: (
        <TemplateSyncTemplatesSelectionStepComponent
          control={control}
          setValue={setValue}
          clients={clients.filter(i => i.id !== currentClient?.id)}
          clientTemplatesList={clientTemplatesList}
          isLinkedChildTemplatesLoading={isLinkedChildTemplatesLoading}
          linkedChildTemplatesList={linkedChildTemplatesList}
          selectedClientId={selectedClientId}
          selectedClientTemplatesList={selectedClientTemplatesList}
          selectedLinkedTemplatesList={selectedLinkedTemplatesList}
          useExistingTemplate={useExistingTemplate}
        />
      ),
      nextBtnConfig: {
        label: 'Next',
        disabled: (selectedLinkedTemplatesList.length + selectedClientTemplatesList.length) < 1,
        tooltipText: 'Please select at least one template before proceeding'
      }
    },
    {
      content: (
        <TemplateSyncOptionsSelectionStepComponent
          control={control}
          setValue={setValue}
          isClientManaged={currentClient?.clientManaged}
          selectedTemplateOptionsPerFields={selectedTemplateOptionsPerFields}
          templateFieldsList={mediaPlanTemplate.mediaPlanTemplateFields}
          templateOptionsList={templateOptionsList}
        />
      ),
      nextBtnConfig: {
        label: 'Apply changes',
        disabled:
          !selectedTemplateOptions.length ||
          selectedTemplateOptions.some(opt =>
            opt.isPerField && selectedTemplateOptionsPerFields[opt.templateSyncOptionId]?.filter(i => i.mediaPlanFieldLevelId === null).length
          ),
        tooltipText: 'Please select at least one action before proceeding'
      },
      prevBtnConfig: {
        label: 'Back'
      }
    },
    {
      content: (
        <TemplateSyncSummaryStepComponent
          selectedClientTemplatesList={selectedClientTemplatesList}
          selectedLinkedTemplatesList={selectedLinkedTemplatesList}
          selectedTemplateOptions={selectedTemplateOptions}
          selectedTemplateOptionsPerFields={selectedTemplateOptionsPerFields}
        />
      ),
      nextBtnConfig: {
        disabled: isPostSyncTemplateJobLoading,
        label: 'Push template changes',
        onBeforeNext: submitAndProceed
      },
      prevBtnConfig: {
        label: 'Back'
      }
    }
  ], [
    submitAndProceed,
    control,
    setValue,
    clients,
    clientTemplatesList,
    currentClient,
    isLinkedChildTemplatesLoading,
    isPostSyncTemplateJobLoading,
    linkedChildTemplatesList,
    mediaPlanTemplate.mediaPlanTemplateFields,
    selectedClientId,
    selectedClientTemplatesList,
    selectedLinkedTemplatesList,
    selectedTemplateOptions,
    selectedTemplateOptionsPerFields,
    templateOptionsList,
    useExistingTemplate
  ])

  const { currentStep, nextStep, prevStep, resetSteps } = useWizardComponent(wizardSteps)

  useEffect(() => {
    if (!visible && currentStep) {
      resetSteps()
    }
  }, [currentStep, visible, resetSteps])

  return (
    <>
      <Tooltip title={isPublishDisabled ? 'Template must be saved before publishing' : 'Publish template'}>
        <Button
          className='ms-button'
          data-testid='publish-template-button'
          disabled={isPublishDisabled}
          onClick={showModal}
          type='primary'
          icon={<FileSyncOutlined />}
        />
      </Tooltip>
      <ModalComponent
        className='template-sync'
        title='Push template updates to clients'
        modalBody={wizardSteps[currentStep].content}
        footer={
          <WizardFooterComponent
            nextBtnConfig={{ ...wizardSteps[currentStep].nextBtnConfig, onClick: nextStep }}
            prevBtnConfig={wizardSteps[currentStep].prevBtnConfig ? { ...wizardSteps[currentStep].prevBtnConfig, onClick: prevStep } : null}
          />
        }
        open={visible}
        onCancel={hideModal}
        width={900}
      />
    </>
  )
}

export default TemplateSyncModalComponent
