/* eslint-disable no-fallthrough */
import { createSelector } from 'reselect'

import { MediaPlanTemplate } from 'Apis/generated/templatesApi'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { selectParseData } from './mediaPlansSelectors'

interface ISelectedParentHierarchiesParams {
  hierarchyName: string
  fieldLevelId: number
  flightGroupIndex?: number
}

export const createSelectedParentHierarchySelector = () => createSelector(
  selectParseData,
  (state) => state.mediaPlanTemplates.currentMediaPlanTemplate,
  (state, params: ISelectedParentHierarchiesParams) => params.fieldLevelId,
  (state, params: ISelectedParentHierarchiesParams) => params.flightGroupIndex,
  (state, params: ISelectedParentHierarchiesParams) => params.hierarchyName,
  (parseData, currentTemplate: MediaPlanTemplate, fieldLevelId: number, flightGroupIndex: number, hierarchyName: string) => {
    switch (fieldLevelId) {
      case FieldLevelType.FLIGHT: {
        return parseData.flightGroups[flightGroupIndex]?.[`${hierarchyName}HierarchyValue`]
      }
      case FieldLevelType.FLIGHT_GROUP: {
        return parseData[`${hierarchyName}HierarchyValue`]
      }
      case FieldLevelType.PLAN: {
        return currentTemplate?.[`${hierarchyName}HierarchyId`]
      }
    }
  }
)
