import React, { FunctionComponent, useCallback } from 'react'
import { Button, Typography, List, Row, Col, Checkbox } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons'
import { MSPercentInput, IMSHierarchies } from '@mindshare/layout'
import { IRedistributeByValues } from 'Components/Redistribution/constants/entities/IRedistribution'
import { defaultContainerHeight } from 'Components/Redistribution/constants/redistribution'
import { formatFieldValue } from 'Components/Redistribution/helpers/formatFieldValue'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { splitPercentageEvenly, getFieldValuesByLevelId, getUniqueFieldValues } from 'Helpers/redistributionHelpers'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useAppDispatch, useAppSelector } from '../../../store'
import { clearSelectedRedistributeByFields } from '../../../redux/actions/mediaPlansActions'
import { selectCurrentMediaPlanVersionFields, selectParseData } from '../../../selectors'

const { Text, Paragraph } = Typography

interface IRedistributionFieldValues {
  redistributeByValues: IRedistributeByValues[]
}

interface IUpdateFieldValue {
  percentage: number
}

interface IFieldValue {
  value: string | number
  id: number
  percentage?: number
}

interface IProps {
  updateFieldValue?: (value: IUpdateFieldValue, name: string, idx: number) => void
  removeFieldValue?: (name: string, value: string) => void
  addFieldValue?: (value: IFieldValue, name: string) => void
  fieldToRedistributeById: number
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  financeList: IMediaPlanVersionFinanceListFields
}

export const RedistributionPercentageSetup: FunctionComponent<IProps &
IRedistributionFieldValues> = ({
  updateFieldValue,
  removeFieldValue,
  addFieldValue,
  redistributeByValues,
  fieldToRedistributeById,
  hierarchies,
  masteredListsData,
  financeList
}) => {
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)
  const parseData = useAppSelector(selectParseData)

  const mediaPlanField = mediaPlanVersionFields.find((field) => field.clientMediaPlanFieldId === fieldToRedistributeById)
  const fieldValues = getFieldValuesByLevelId(mediaPlanField, parseData, masteredListsData, hierarchies, financeList)
  const uniqueFieldValues = getUniqueFieldValues(fieldValues)
  const fieldLabel = mediaPlanField?.fieldLabel || ''
  const fieldDataTypeId = mediaPlanField?.clientMediaPlanField.mediaPlanField.fieldDataTypeId
  const dispatch = useAppDispatch()

  const handleChange = useCallback((checked, item, name) => {
    const newItem = {
      value: item.value,
      percentage: null,
      id: item.id,
      valueId: item.valueId
    }

    if (checked) {
      addFieldValue(newItem, name)
    } else {
      removeFieldValue(name, item.value)
    }

    dispatch(clearSelectedRedistributeByFields())
  }, [addFieldValue, removeFieldValue, dispatch])

  const setPercentageValues = useCallback(() => {
    const values = splitPercentageEvenly(redistributeByValues.length)
    redistributeByValues.forEach((_value, idx) => {
      updateFieldValue({ percentage: values[idx] }, 'redistributeByValues', idx)
    })
  }, [redistributeByValues, updateFieldValue])

  return (
    <div className="redistribution-form">
      <Row gutter={[40, 16]}>
        <Col span={12}>
          <Paragraph className="col-title">Select <b>{fieldLabel}</b> values to redistribute by:</Paragraph>
          <List
            bordered={true}
            dataSource={uniqueFieldValues}
            style={{ height: defaultContainerHeight }}
            renderItem={(item: IFieldValue, idx) => {
              const displayValue = formatFieldValue(item.value, fieldDataTypeId)

              return (
                <List.Item key={idx}>
                  <Checkbox
                    onChange={e => {
                      handleChange(
                        e.target.checked,
                        item,
                        'redistributeByValues'
                      )
                    }}
                    checked={
                      !!redistributeByValues.find(
                        value =>
                          value.value ===
                        item.value
                      )
                    }
                    aria-label='Values to redistribute'
                  >
                    {displayValue}
                  </Checkbox>
                </List.Item>
              )
            }}
          ></List>
        </Col>

        {redistributeByValues.length > 0 && (
          <Col span={12}>
            <div className="redistribution-header-container">
              <Paragraph className="col-title">Set percentage:</Paragraph>
              <Button type="primary" onClick={setPercentageValues} data-testid="split-evenly">
                Split evenly
              </Button>
            </div>
            <List
              bordered={true}
              dataSource={redistributeByValues}
              style={{ height: defaultContainerHeight }}
              renderItem={(item, idx) => {
                const displayValue = formatFieldValue(item.value, fieldDataTypeId)

                return (
                  <List.Item key={idx}>
                    <Text>{displayValue}</Text>
                    <div className="redistribution-item">
                      <MSPercentInput
                        value={item.percentage}
                        onChange={value =>
                          updateFieldValue(
                            { percentage: value },
                            'redistributeByValues',
                            idx
                          )
                        }
                        aria-label="Redistribute By Values"
                        className="redistribution-percent-field"
                      />
                      <Button
                        data-testid="delete-redistribution-button"
                        type="link"
                        icon={<CloseCircleFilled />}
                        onClick={() => {
                          removeFieldValue('redistributeByValues', item.value)
                          dispatch(clearSelectedRedistributeByFields())
                        }
                        }
                      />
                    </div>
                  </List.Item>
                )
              }
              }
            ></List>
          </Col>
        )}
      </Row>
    </div>
  )
}
