import { createSelector } from 'reselect'
import { selectMediaPlans } from './mediaPlansSelectors'

export const selectRedistributeMode = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.redistributeModalVisible
)

export const selectRedistributeByFields = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.selectedRedistributeByFields
)

export const selectCurrentRedistribution = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.currentRedistribution
)

export const selectRedistributions = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.redistributions
)
