import React, { useCallback, useEffect } from 'react'
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useFieldArray
} from 'react-hook-form'
import { Select, Checkbox, Input, Divider, Row, Col, Tooltip } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import classNames from 'classnames'
import { MSLayoutTitleRow } from '@mindshare/layout'
import { useGetTabularLookupsDateDimensionTypesQuery, useGetTabularLookupsByLookupIdQuery } from 'Apis/generated/tabularLookupApi'
import { useGetTabularLookupsQuery } from 'Apis/enhancedTabularLookupApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { IMediaPlanTemplateFields, IMediaPlanVersionField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { getDisplayLabel } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { checkUniqueField } from 'Helpers/redistributionHelpers'
import { tabularLookupTooltipMessage } from 'Constants/enums/TooltipMessages'
import { checkUniqueFieldArray } from 'Components/TabularLookup/helpers/checkUniqueFieldArray'
import { TabularLookupValuesTableComponent } from 'Components/TabularLookup/TabularLookupValuesTableComponent'
import { ITabularLookupFormData } from 'Components/TabularLookup/TabularLookupModalComponent'
import { useTabularLookupInitialValues } from 'Components/TabularLookup/hooks/useTabularLookupInitialValues'
import { TabularLookupLevel } from 'Components/TabularLookup/enums/tabularLookupLevel'

const { Option } = Select
const title = {
  ADD_TABULAR_LOOKUP: 'Add Tabular Lookup',
  EDIT_TABULAR_LOOKUP: 'Edit Tabular Lookup',
  VIEW_TABULAR_LOOKUP: 'View Tabular Lookup'
}

interface ITabularLookupCreateStepProps {
  isTemplate: boolean
  fields: IMediaPlanTemplateFields[] | IMediaPlanVersionField[]
  tabularLookupId: number
}

export const TabularLookupCreateStepComponent: React.FC<ITabularLookupCreateStepProps> = ({ isTemplate, fields, tabularLookupId }) => {
  const { data: currentClient } = useCurrentClient()
  const { data: dateDimensions } = useGetTabularLookupsDateDimensionTypesQuery(
    { clientId: currentClient?.id }
  )
  const { data: tabularLookups } = useGetTabularLookupsQuery(
    { clientId: currentClient?.id }
  )
  const { data: currentTabularLookup } = useGetTabularLookupsByLookupIdQuery(
    { clientId: currentClient?.id, lookupId: tabularLookupId },
    { skip: !tabularLookupId, refetchOnMountOrArgChange: true }
  )
  const isEditMode = currentTabularLookup && currentTabularLookup.tabularLookupId > 0
  const isReadOnly = !isTemplate && currentTabularLookup?.lookupLevel === TabularLookupLevel.Template && !currentTabularLookup.isEditable
  const initialValues = useTabularLookupInitialValues(currentTabularLookup)

  const { control, setValue, setError, clearErrors, watch, reset, formState: { errors } } = useFormContext<ITabularLookupFormData>()
  const { fields: returnValuesFields, append, remove } = useFieldArray({
    control,
    name: 'returnValues'
  })
  const isEditableAtPlanLevel = watch('isEditableAtPlanLevel')
  const returnValues = watch('returnValues')
  const lookupName = watch('lookupName')
  const fieldsDimension = watch('fieldsDimension')
  const tabularDateDimensionTypeId = watch('tabularDateDimensionTypeId')

  useEffect(() => {
    if (isEditMode) {
      reset(initialValues)
    }
  }, [isEditMode, initialValues, reset])

  const addRow = useCallback(() => {
    append({
      returnValueName: null,
      returnValueDataTypeId: null
    })
  }, [append])

  const removeRow = useCallback((index: number) => {
    remove(index)
  }, [remove])

  const handleValueNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<ITabularLookupFormData>, index: number) => {
    field.onBlur()

    if (checkUniqueFieldArray(returnValues, 'returnValueName', e.target.value)) {
      clearErrors(field.name)
    } else {
      setError(field.name, { type: 'duplicatedValueName', message: tabularLookupTooltipMessage.duplicatedValueName })
    }

    setValue(`returnValues.${index}.returnValueName` as any, e.target.value)
  }, [clearErrors, returnValues, setError, setValue])

  const handleDataTypeChange = useCallback((value: number, field: ControllerRenderProps<ITabularLookupFormData>, index: number) => {
    field.onChange(value)
    setValue(`returnValues.${index}.returnValueDataTypeId` as any, value)
  }, [setValue])

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<ITabularLookupFormData, 'lookupName'>) => {
    field.onBlur()

    if (checkUniqueField(tabularLookups, e.target.value, 'lookupName')) {
      clearErrors(field.name)
    } else {
      setError(field.name, { type: 'duplicatedName', message: tabularLookupTooltipMessage.duplicatedName })
    }

    setValue(field.name, e.target.value)
  }, [clearErrors, setError, setValue, tabularLookups])

  const handleDateDimensionsChange = useCallback((value: number, field: ControllerRenderProps<ITabularLookupFormData, 'tabularDateDimensionTypeId'>) => {
    field.onChange(value)
    setValue(field.name, value)
  }, [setValue])

  const handleFieldsDimensionChange = useCallback((values: number[], field: ControllerRenderProps<ITabularLookupFormData, 'fieldsDimension'>) => {
    const updatedValues = values.map((nextValue) => {
      const existingField = field.value?.find(v => v.mediaPlanFieldId === nextValue)

      return existingField ? {
        ...existingField,
        mediaPlanFieldId: existingField.mediaPlanFieldId
      } : {
        mediaPlanFieldId: nextValue,
        sortOrder: 0,
        tabularLookupDimensionId: undefined,
        tabularLookupId: undefined
      }
    }, [])

    field.onChange(updatedValues)
    setValue('fieldsDimension', updatedValues)
  }, [setValue])

  const handleEditableAtPlanLevelChange = useCallback((e: CheckboxChangeEvent, field: ControllerRenderProps<ITabularLookupFormData, 'isEditableAtPlanLevel'>) => {
    field.onChange(e.target.checked)
    setValue('isEditableAtPlanLevel', e.target.checked)
  }, [setValue])

  return (
    <>
      <MSLayoutTitleRow extraClass='tabular-lookup__header'>
        {isReadOnly
          ? title.VIEW_TABULAR_LOOKUP
          : isEditMode
            ? title.EDIT_TABULAR_LOOKUP
            : title.ADD_TABULAR_LOOKUP}
      </MSLayoutTitleRow>
      <Row gutter={[12, 8]}>
        <Col span={6}>
          <label htmlFor='lookupName'>Name</label>
          <Controller
            name='lookupName'
            control={control}
            render={({ field }) => {
              const errorMessage = (errors[field.name] && errors[field.name].message || '') as string

              return (
                <Tooltip title={errorMessage}>
                  <Input
                    placeholder='Name'
                    id='lookupName'
                    className={classNames({ '--error': !!errorMessage })}
                    onBlur={e => handleNameChange(e, field)}
                    value={lookupName}
                    disabled={isEditMode || isReadOnly}
                  />
                </Tooltip>
              )
            }
            }
          />
        </Col>
        <Col span={6}>
          <label htmlFor='fieldsDimension'>Dimensions</label>
          <Controller
            name='fieldsDimension'
            control={control}
            render={({ field }) => (
              <Select
                id='fieldsDimension'
                className='ms-select'
                onChange={(e) => handleFieldsDimensionChange(e, field)}
                mode='multiple'
                placeholder='Dimensions'
                allowClear={true}
                showSearch={true}
                optionFilterProp='children'
                style={{ width: '100%' }}
                maxTagCount={1}
                value={fieldsDimension.map((f) => f.mediaPlanFieldId)}
                disabled={isEditMode || isReadOnly}
              >
                {fields.map((f: IMediaPlanTemplateFields | IMediaPlanVersionField) => (
                  <Option
                    key={f.clientMediaPlanField.clientMediaPlanFieldId}
                    value={f.clientMediaPlanField.mediaPlanField.mediaPlanFieldId}
                  >
                    {getDisplayLabel(f.clientMediaPlanField)}
                  </Option>
                ))}
              </Select>
            )}
          />
        </Col>
        <Col span={6}>
          <label htmlFor='tabularDateDimensionTypeId'>Date Dimensions</label>
          <Controller
            name='tabularDateDimensionTypeId'
            control={control}
            render={({ field }) => (
              <Select
                id='tabularDateDimensionTypeId'
                className='ms-select'
                onChange={(e) => handleDateDimensionsChange(e, field)}
                placeholder='Date dimensions'
                allowClear={true}
                showSearch={true}
                optionFilterProp='children'
                style={{ width: '100%' }}
                value={tabularDateDimensionTypeId}
                disabled={isEditMode || isReadOnly}
              >
                {dateDimensions?.map((d) => (
                  <Option key={d.tabularLookupDateDimensionTypeId} value={d.tabularLookupDateDimensionTypeId}>
                    {d.dateDimensionTypeName}
                  </Option>
                ))}
              </Select>
            )}
          />
        </Col>
        {isTemplate && (
          <Col span={6}>
            <div className='tabular-lookup__edit-checkbox'>
              <Controller
                name='isEditableAtPlanLevel'
                control={control}
                render={({ field }) => (
                  <Checkbox
                    data-testid='editable-at-plan-level'
                    checked={isEditableAtPlanLevel}
                    onChange={(e) => handleEditableAtPlanLevelChange(e, field)}
                  >
                    <label>Editable at plan level</label>
                  </Checkbox>
                )}
              />
            </div>
          </Col>
        )}
      </Row>
      <Divider />
      <TabularLookupValuesTableComponent
        control={control}
        handleValueNameChange={handleValueNameChange}
        handleDataTypeChange={handleDataTypeChange}
        removeRow={removeRow}
        addRow={addRow}
        fields={returnValuesFields}
        setValue={setValue}
        isEditMode={isEditMode}
        isReadOnly={isReadOnly}
        currentTabularLookupLevel={currentTabularLookup?.lookupLevel}
      />
    </>
  )
}

export default TabularLookupCreateStepComponent
