/* eslint-disable functional/immutable-data */

import { ChangeEvent, useMemo, useState } from 'react'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import {
  isClientHierarchyType,
  getClientHierarchyBranchIDs,
  getClientHierarchyNode,
  IClientHierarchyName,
  getClientHierarchyIdProp,
  isBlank,
  IClientHierarchy
} from '@mindshare/layout'
import type { DragEndEvent } from '@dnd-kit/core'

import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { TemplateFieldAvailability } from 'Constants/enums/TemplateFieldAvailability'
import { createMediaPlanField } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'
import { getDisplayLabel, IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { IMediaPlanFieldRow, createMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import { IMediaPlanTemplateFields, getDisplayValue, isAggregated } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

export interface IRowFunctions {
  createRow: (fieldLevelId?: number, sortCount?: number) => IMediaPlanFieldRow
  createFromClientMediaPlanField: (field: IClientMediaPlanField, fieldValue: string) => IMediaPlanFieldRow
  createFromClientMediaPlanFields: (currentMediaPlanFields: IClientMediaPlanField[]) => IMediaPlanFieldRow[]
  createFromMediaPlanTemplateFields: (fields: IMediaPlanTemplateFields) => IMediaPlanFieldRow
  findByItemRowId: (itemRow: IMediaPlanFieldRow) => IMediaPlanFieldRow
  addNewFieldRow: (newItem?: IMediaPlanFieldRow) => void
  handleAddButton: () => void
  handleCalculation: (event: CheckboxChangeEvent, itemRow: IMediaPlanFieldRow) => void
  handleEditable: (editable: boolean, itemRow: IMediaPlanFieldRow) => void
  handleFooter: (event: CheckboxChangeEvent, itemRow: IMediaPlanFieldRow) => void
  handleFieldTypeChange: (el: string, itemRow: IMediaPlanFieldRow) => void
  handleHierarchyLevelId: (hierarchyId: number, itemRow: IMediaPlanFieldRow) => void
  handleIncludeLevelsBelow: (event: CheckboxChangeEvent, itemRow: IMediaPlanFieldRow) => void
  handleRemoveButton: (idItemToDelete: string) => void
  handleTitleChange: (labelId: number, itemRow: IMediaPlanFieldRow) => void
  onSortEnd: (currentData: DragEndEvent) => void
  setAlias: (newValue: string, itemRow: IMediaPlanFieldRow) => void
  setAvailability: (fieldAvailabilityId: string | number, itemRow: IMediaPlanFieldRow) => void
  setFieldValue: (event: ChangeEvent<HTMLInputElement> | string | number, itemRow: IMediaPlanFieldRow) => void
  onHierarchyFilterSelectionChange: (hierarchyname: string, id: number) => void
}

export const useTemplateFieldRows = ({
  selectedTab,
  clientMediaPlanFields,
  mediaPlanTemplateFields,
  availableMetaData,
  hierarchies,
  setAvailableMetaData,
  setSelectedHierarchies,
  requiredHierarchies
}) => {
  const [itemList, setItemList] = useState<IMediaPlanFieldRow[]>([])
  const [sortCount, setSortCount] = useState(0)
  const itemListForTab = useMemo(
    () => itemList.filter(c => c.mediaPlanField.fieldLevelId === selectedTab),
    [itemList, selectedTab]
  )
  const clientMediaFieldsByTab = useMemo(
    () => clientMediaPlanFields.filter(c => c.mediaPlanField.fieldLevelId === selectedTab && !c.isDisabled),
    [clientMediaPlanFields, selectedTab]
  )

  const rowFunctions: IRowFunctions = useMemo(() => ({
    createRow (fieldLevelId = selectedTab, sortOrder = sortCount + 1): IMediaPlanFieldRow {
      return createMediaPlanFieldRow({
        sortOrder,
        mediaPlanField: createMediaPlanField({ fieldLevelId })
      })
    },
    createFromClientMediaPlanField (field: IClientMediaPlanField, fieldValue: string): IMediaPlanFieldRow {
      const newItem = rowFunctions.createRow()
      return {
        ...newItem,
        templateFieldTypeId: TemplateFieldTypes.MANUAL,
        templateFieldAvailabilityId: TemplateFieldAvailability.MANDATORY,

        displayLabel: field.mediaPlanField.fieldLabel,
        fieldName: field.mediaPlanField.fieldLabel,
        clientMediaPlanFieldId: field.clientMediaPlanFieldId,

        defaultValue: fieldValue,

        mediaPlanField: {
          ...newItem.mediaPlanField,
          ...field.mediaPlanField,
          defaultValue: fieldValue
        }
      }
    },
    createFromClientMediaPlanFields (currentMediaPlanFields: IClientMediaPlanField[]): IMediaPlanFieldRow[] {
      if (currentMediaPlanFields.length > 0) {
        setSortCount(currentMediaPlanFields.length - 1)
      }
      return currentMediaPlanFields.map((c, index) => {
        const itemRow = rowFunctions.createRow(undefined, index)
        const isHierarchyRequired = c.isMandatory || (c.mediaPlanField.fieldLevelId === FieldLevelType.PLAN &&
          requiredHierarchies[`is${c.mediaPlanField.fieldDataType.dataTypeName.split(' ')[0]}Required`])
          ? TemplateFieldAvailability.MANDATORY
          : TemplateFieldAvailability.DEFAULT
        const templateFieldAvailabilityId = c.mediaPlanField.fieldDataType.isClientHierarchy
          ? isHierarchyRequired
          : TemplateFieldAvailability.MANDATORY

        return {
          ...itemRow,
          templateFieldTypeId: TemplateFieldTypes.MANUAL,
          fieldName: getDisplayLabel(c),
          fieldLabel: '',
          savedFieldLabel: '',
          displayLabel: getDisplayLabel(c),
          columnName: c.columnName,
          mediaPlanField: c.mediaPlanField,
          isMandatory: c.isMandatory,
          clientMediaPlanFieldId: c.clientMediaPlanFieldId,
          templateFieldAvailabilityId,
          clientFieldValues: c.clientFieldValues,
          isEditable: true,
          isComplexCalculation: true,
          // TODO: Disclaimer V1 - this will be changed in V2

          isFooter: c.mediaPlanField.fieldLevelId === FieldLevelType.PLAN &&
            c.mediaPlanField.fieldDataTypeId === FieldDataType.RICH_TEXT
        }
      })
    },
    createFromMediaPlanTemplateFields (m: IMediaPlanTemplateFields): IMediaPlanFieldRow {
      const itemRow = rowFunctions.createRow(undefined, m.sortOrder)

      return {
        ...itemRow,
        mediaPlanTemplateFieldId: m.mediaPlanTemplateFieldId,
        fieldName: getDisplayLabel(m.clientMediaPlanField),
        fieldLabel: m.fieldLabel,
        savedFieldLabel: m.fieldLabel,
        approvedOn: m.approvedOn,
        mediaPlanField: m.clientMediaPlanField.mediaPlanField,
        templateFieldTypeId: m.templateFieldTypeId,
        templateFieldAvailabilityId: m.templateFieldAvailabilityId,
        defaultValue: m.defaultValue,
        displayLabel: getDisplayValue(m),
        isDisabled: m.clientMediaPlanField.isDisabled,
        isEditable: m.isEditable,
        isMandatory: m.clientMediaPlanField.isMandatory,
        clientMediaPlanFieldId: m.clientMediaPlanFieldId,
        functionId: m.functionId,
        calculation: m.calculation,
        clientFieldValues: m.clientMediaPlanField.clientFieldValues,
        isFooter: m.isFooter,
        requiredHierarchyLevelId: m.requiredHierarchyLevelId,
        includeLevelsBelowHierarchy: m.includeLevelsBelowHierarchy,

        isComplexCalculation: mediaPlanTemplateFields
          ? mediaPlanTemplateFields.find((f) => f.clientMediaPlanFieldId === m.clientMediaPlanFieldId).isComplexCalculation
          : itemRow.isComplexCalculation
      }
    },
    findByItemRowId: (itemRow: IMediaPlanFieldRow) => itemList.find(c => c.itemRowId === itemRow.itemRowId),
    addNewFieldRow (newItem = rowFunctions.createRow()) {
      setItemList([...itemList, newItem])
      setSortCount(sortCount + 1)
      const ids = itemList.map(c => c.clientMediaPlanFieldId)
      const newAvailable = availableMetaData.filter(c => !ids.some(x => x === c.clientMediaPlanFieldId))
      setAvailableMetaData(newAvailable)
    },
    handleAddButton: (): void => {
      if (clientMediaFieldsByTab.length !== itemListForTab.length) {
        rowFunctions.addNewFieldRow()
      }
    },
    handleCalculation: (event: CheckboxChangeEvent, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.isComplexCalculation = event.target.checked
      setItemList([...itemList])
    },
    handleHierarchyLevelId: (hierarchyId: number, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.requiredHierarchyLevelId = hierarchyId

      if (!hierarchyId) {
        selectedElement.includeLevelsBelowHierarchy = false
      }
      setItemList([...itemList])
    },
    handleIncludeLevelsBelow: (event: CheckboxChangeEvent, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.includeLevelsBelowHierarchy = event.target.checked
      setItemList([...itemList])
    },
    handleEditable: (isEditable: boolean, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.isEditable = isEditable
      setItemList([...itemList])
    },
    handleFooter: (event: CheckboxChangeEvent, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.isFooter = event.target.checked
      setItemList([...itemList])
    },
    handleFieldTypeChange: (el: string, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.templateFieldTypeId = el ? Number(el) : TemplateFieldTypes.MANUAL
      if (!isAggregated(selectedElement)) {
        selectedElement.isComplexCalculation = false
        selectedElement.calculation = null
        selectedElement.functionId = null
      }
      setItemList([...itemList])
    },
    handleRemoveButton: (idItemToDelete: string): void => {
      const itemToDelete = itemList.find(item => item.itemRowId === idItemToDelete)
      if (itemToDelete !== undefined) {
        setSortCount(sortCount - 1)
        const index = itemList.indexOf(itemToDelete)
        const remove = [...itemList]
        remove.forEach((c, i) => i >= index ? c.sortOrder-- : c)
        remove.splice(index, 1)
        setItemList(remove)
        const available = clientMediaFieldsByTab.find(c => c.clientMediaPlanFieldId === itemToDelete.clientMediaPlanFieldId)

        if (available) {
          availableMetaData.push(available)
          setAvailableMetaData(availableMetaData)
        }
      }
    },
    handleTitleChange: (labelId: number, itemRow: IMediaPlanFieldRow) => {
      const clientMediaPlanFieldsByTab = clientMediaFieldsByTab
      const index = itemList.findIndex(item => item.itemRowId === itemRow.itemRowId)
      const itemListToUpdate = [...itemList]
      let itemRowToSet = itemListToUpdate[index]
      const availableMetaDataToUpdate = [...availableMetaData]

      const currentField = clientMediaFieldsByTab.find(c => c.clientMediaPlanFieldId === itemRowToSet.clientMediaPlanFieldId)
      if (currentField) {
        availableMetaDataToUpdate.push(currentField)
      }

      if (labelId) { // change field
        const selectedField = clientMediaPlanFieldsByTab.find(el => el.clientMediaPlanFieldId === labelId)
        const fieldName = getDisplayLabel(selectedField)
        const clientFieldValues = selectedField.clientFieldValues?.filter(value => !value.isDisabled)
        itemRowToSet = {
          ...itemRowToSet,
          clientMediaPlanFieldId: selectedField.clientMediaPlanFieldId,
          mediaPlanField: selectedField.mediaPlanField,
          fieldName,
          defaultValue: null,
          fieldLabel: null,
          approvedOn: null,
          isFooter: false,
          clientFieldValues
        }

        const indexToRemove = availableMetaDataToUpdate.findIndex(c => c.clientMediaPlanFieldId === labelId)
        availableMetaDataToUpdate.splice(indexToRemove, 1)
      } else { // clear field
        itemRowToSet = {
          ...itemRowToSet,
          defaultValue: null,
          clientMediaPlanFieldId: null,
          fieldName: null,
          fieldLabel: null,
          approvedOn: null,
          clientFieldValues: null
        }
      }

      itemListToUpdate[index] = itemRowToSet
      setItemList(itemListToUpdate)
      setAvailableMetaData(availableMetaDataToUpdate)
    },
    onSortEnd: ({ active, over }: DragEndEvent) => {
      const oldIndex = itemListForTab.findIndex((i) => i.itemRowId === active.id)
      const newIndex = itemListForTab.findIndex((i) => i.itemRowId === over?.id)

      if (oldIndex === newIndex) {
        return
      }
      let items = []
      if (oldIndex > newIndex) {
        items = itemListForTab.map((item, index) => {
          if (index === newIndex) {
            return { ...itemListForTab[oldIndex], sortOrder: item.sortOrder }
          } else if (index >= newIndex && index <= oldIndex && index - 1 >= 0) {
            return { ...itemListForTab[index - 1], sortOrder: item.sortOrder }
          } else {
            return item
          }
        })
      } else {
        items = itemListForTab.map((item, index) => {
          if (index === newIndex) {
            return { ...itemListForTab[oldIndex], sortOrder: item.sortOrder }
          } else if (index >= oldIndex && index <= newIndex && index + 1 <= itemListForTab.length - 1) {
            return { ...itemListForTab[index + 1], sortOrder: item.sortOrder }
          } else {
            return item
          }
        })
      }

      const dataNotInTab = itemList.filter(c => c.mediaPlanField.fieldLevelId !== selectedTab)
      const result = items.concat(dataNotInTab)
      result.sort((a, b) => a.sortOrder - b.sortOrder)
      setItemList(result)
    },
    setAlias: (newValue: string, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.fieldLabel = newValue
      setItemList([...itemList])
    },
    setAvailability: (fieldAvailabilityId: string | number, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      selectedElement.templateFieldAvailabilityId = fieldAvailabilityId ? Number(fieldAvailabilityId) : TemplateFieldAvailability.DEFAULT
      setItemList([...itemList])
    },
    setFieldValue: (event: ChangeEvent<HTMLInputElement> | string | number, itemRow: IMediaPlanFieldRow) => {
      const selectedElement = rowFunctions.findByItemRowId(itemRow)
      const eventTarget = event && (event as ChangeEvent<HTMLInputElement>).target

      const value = eventTarget ? eventTarget.value : event as string | number
      selectedElement.defaultValue = isBlank(value) ? null : value
      setItemList([...itemList])
    },
    onHierarchyFilterSelectionChange: (hierarchyName: string, id: number) => {
      const hierarchyField: IClientMediaPlanField = clientMediaPlanFields.find(({ mediaPlanField }) =>
        mediaPlanField.fieldLevelId === FieldLevelType.PLAN &&
        isClientHierarchyType(mediaPlanField.fieldDataType.dataTypeName, hierarchyName)
      )
      const hierarchyRow: IMediaPlanFieldRow = hierarchyField && itemList.find((row) =>
        row.mediaPlanField.fieldLevelId === FieldLevelType.PLAN && (
          row.mediaPlanField.mediaPlanFieldLevelId === hierarchyField.mediaPlanFieldLevelId ||
          row.clientMediaPlanFieldId === hierarchyField.clientMediaPlanFieldId
        )
      )

      if (hierarchyRow) {
        if (id) {
          const ids = getClientHierarchyBranchIDs(
            hierarchies,
            hierarchyName as IClientHierarchyName,
            Number(hierarchyRow.defaultValue)
          )

          if (!ids.find(x => x === id)) {
            rowFunctions.setFieldValue(id, hierarchyRow)
          }
          rowFunctions.setAvailability(TemplateFieldAvailability.MANDATORY, hierarchyRow)
        }
      } else if (hierarchyField) {
        const selectedNode: IClientHierarchy = id &&
          getClientHierarchyNode(
            hierarchies,
            hierarchyName as IClientHierarchyName,
            Number(id)
          )


        if (selectedNode) {
          rowFunctions.addNewFieldRow(
            rowFunctions.createFromClientMediaPlanField(
              hierarchyField,
              selectedNode[getClientHierarchyIdProp(hierarchyName as IClientHierarchyName)]
            )
          )
        }
      }

      setSelectedHierarchies((val) => ({ ...val, [hierarchyName]: id }))
    }
  }), [
    itemList,
    sortCount,
    selectedTab,
    mediaPlanTemplateFields,
    clientMediaPlanFields,
    itemListForTab,
    clientMediaFieldsByTab,
    hierarchies,
    availableMetaData,
    setAvailableMetaData,
    setSelectedHierarchies,
    requiredHierarchies
  ])

  return {
    templateFieldRows: itemList,
    templateFieldRowsForTab: itemListForTab,
    setTemplateFieldRows: setItemList,
    sortCount,
    setSortCount,
    rowFunctions
  }
}
