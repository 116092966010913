import { FieldLevelType } from 'Constants/enums/FieldLevel'

export type ILevelData = {
  data: any
  fieldsLevel: FieldLevelType
} | null

export const getDataRelevantForLevel = (
  parseData: any,
  flightGroupIndex?: number,
  flightIndex?: number,
  subFlightIndex?: number
): ILevelData => {
  if (subFlightIndex) {
    if ([flightGroupIndex, flightIndex, subFlightIndex].includes(undefined)) {
      return null
    }

    return {
      data: {
        ...parseData,
        flightGroups: null,
        flightGroup: {
          ...parseData.flightGroups[flightGroupIndex],
          flights: null,
          flight: {
            ...parseData.flightGroups[flightGroupIndex].flights[flightIndex],
            subFlights: null,
            subFlight: parseData.flightGroups[flightGroupIndex].flights[flightIndex]?.subFlights[subFlightIndex]
          }
        }
      },
      fieldsLevel: FieldLevelType.SUB_FLIGHT
    }
  }

  if (flightIndex) {
    if ([flightGroupIndex, flightIndex].includes(undefined)) {
      return null
    }

    return {
      data: {
        ...parseData,
        flightGroups: null,
        flightGroup: {
          ...parseData.flightGroups[flightGroupIndex],
          flights: null,
          flight: parseData.flightGroups[flightGroupIndex].flights[flightIndex]
        }
      },
      fieldsLevel: FieldLevelType.FLIGHT
    }
  }

  if (flightGroupIndex) {
    return {
      data: {
        ...parseData,
        flightGroups: null,
        flightGroup: parseData.flightGroups[flightGroupIndex]
      },
      fieldsLevel: FieldLevelType.FLIGHT_GROUP
    }
  }

  return {
    data: parseData,
    fieldsLevel: FieldLevelType.PLAN
  }
}
