import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, message } from 'antd'
import { RootState } from 'Reducers/index'
import { ClientFieldValue } from 'Apis/generated/clientFieldAliasApi'
import { getClientMediaPlanFields, setClientMediaPlanFieldData, submitClientMediaPlanFieldData } from 'Actions/clientMediaPlanFieldActions'
import { MSLayoutContentRow, useRouteNavigation, useMindshareSelector } from '@mindshare/layout'
import { usePostClientSettingsMutation } from 'Apis/enhancedClientSettingsApi'
import ClientDataFilter from 'Components/ClientDataFilter'
import { compare } from 'Helpers/sortHelper'
import DataTermsTable from 'Components/ClientDataTerm/DataTermsTable'
import TitleAndButtonsComponent from 'Components/TitleAndButtonsComponent'
import clientSetupText from 'Components/tooltipContent/Client Setup'
import { IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { useClientSettings } from 'Components/Client/hooks/useClientSettings'
import { appPaths } from 'Constants/appPaths'
import { useAppDispatch } from '../../store'
import { selectCurrentClient } from '../../selectors'

export const ClientDataTermsContainer: React.FunctionComponent = (): React.ReactElement => {
  const navigate = useRouteNavigation()
  const clientMediaPlanFields = useMindshareSelector((state: RootState) => state.mediaPlanDataTerms.clientMediaPlanFields, null, true) as IClientMediaPlanField[]
  const currentClient = useMindshareSelector(selectCurrentClient)
  const { data: clientSettings } = useClientSettings()
  const [setClientSettings] = usePostClientSettingsMutation()
  const dispatch = useAppDispatch()
  const countId = useRef(-1)
  const messageKey = 'messageKey'
  const [saving, setSaving] = useState(false)

  const handleSubmit = () => submitClientMediaFields()
  const handlePrevious = () => navigate(appPaths.clientSetup)
  const updateClientMediaFields = (updatedClientMediaPlanFields: IClientMediaPlanField[]) => dispatch(setClientMediaPlanFieldData(updatedClientMediaPlanFields))
  const getClientMediaFields = useCallback(async () => {
    if (currentClient.id > 0) {
      await dispatch(getClientMediaPlanFields(currentClient.id))
    }
  }, [currentClient.id, dispatch])

  const submitClientMediaFields = async () => {
    message.destroy()
    message.loading({ content: 'Loading...', key: messageKey })
    setSaving(true)
    const error = await submitClientMediaPlanFieldData(clientMediaPlanFields, currentClient.id)

    if (!error) {
      navigate(appPaths.templates)
    } else {
      setSaving(false)
      if (typeof error === 'string') {
        message.error({ content: error, duration: 10, key: messageKey })
      } else if (error.title) {
        message.error({ content: error.title, duration: 10, key: messageKey })
      }
    }
  }

  useEffect(() => {
    getClientMediaFields()
  }, [currentClient.id, getClientMediaFields])

  const handleAliasAddition = (selectedRow: IClientMediaPlanField, newValue: string): void => {
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldLevelId === selectedRow.mediaPlanFieldLevelId ? { ...c, fieldLabel: newValue } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  function handleMandatoryToggle (selectedRow: IClientMediaPlanField): void {
    const oldValue = selectedRow.isMandatory
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldLevelId === selectedRow.mediaPlanFieldLevelId ? { ...c, isMandatory: !oldValue } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleDisabled = (selectedRow: IClientMediaPlanField) => {
    const oldValue = selectedRow.isDisabled
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldLevelId === selectedRow.mediaPlanFieldLevelId ? { ...c, isDisabled: !oldValue } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleIsApportioned = (selectedRow: IClientMediaPlanField) => {
    const oldValue = selectedRow.isApportioned
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldLevelId === selectedRow.mediaPlanFieldLevelId ? { ...c, isApportioned: !oldValue, includeInSubtotals: !oldValue || c.includeInSubtotals } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleIsApportionmentField = (selectedRow: IClientMediaPlanField) => {
    const oldValue = selectedRow.isApportionmentField
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldLevelId === selectedRow.mediaPlanFieldLevelId ? { ...c, isApportionmentField: !oldValue } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleIncludeInSubtotals = (selectedRow: IClientMediaPlanField) => {
    const oldValue = selectedRow.includeInSubtotals
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldLevelId === selectedRow.mediaPlanFieldLevelId ? { ...c, includeInSubtotals: !oldValue } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }


  function handleToggleAliasDisabled (event: any): void {
    setClientSettings({ clientId: currentClient.id, clientSetting: { ...clientSettings, isTemplateAliasingEnabled: event.target.checked } })
  }

  function handleFilter (textFilter: string, dataType: number[], level: number[], tags: number[]): void {
    const textDoesNotMatch = (term: IClientMediaPlanField) => textFilter.length > 0 &&
                                                            !term.fieldLabel?.toLocaleLowerCase().includes(textFilter) &&
                                                            !term.mediaPlanField.fieldLabel?.toLowerCase().includes(textFilter)

    const typeDoesNotMatch = (term: IClientMediaPlanField ) => (dataType.length > 0 &&
                                                              dataType.find(t => t === term.mediaPlanField.fieldDataTypeId) === undefined)

    const levelDoesNotMatch = (term: IClientMediaPlanField) => (level.length > 0 &&
                                                              level.find(t => t === term.mediaPlanField.fieldLevelId) === undefined)

    const tagsDoesNotMatch = (term: IClientMediaPlanField ) => (tags.length > 0 &&
                                                            tags.find(t => term.mediaPlanField.fieldTags?.find(ft => ft.fieldTagId === t)) === undefined)

    const termDoesNotMatch = (term: IClientMediaPlanField ) => (textDoesNotMatch(term) || typeDoesNotMatch(term) ||
                                                                levelDoesNotMatch(term) || tagsDoesNotMatch(term))

    const updatedClientMediaPlanFields = clientMediaPlanFields.map(field => ({ ...field, isVisible: !termDoesNotMatch(field) }))
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const buildClientFieldValue = (inputValue: string, mediaPlanField: IClientMediaPlanField): ClientFieldValue => ({
    valueDisplayName: inputValue,
    sortOrder: mediaPlanField.clientFieldValues?.length + 1,
    // eslint-disable-next-line functional/immutable-data
    clientFieldValueId: --countId.current,
    isDisabled: false,
    mediaPlanFieldId: mediaPlanField.mediaPlanField.mediaPlanFieldId
  })

  const handleDropdownListValue = (value: string, clientMediaPlanField: IClientMediaPlanField): void => {
    const clientFieldValue: ClientFieldValue = buildClientFieldValue(value, clientMediaPlanField)
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldId === clientMediaPlanField.mediaPlanFieldId ? { ...c, clientFieldValues: [...c.clientFieldValues, clientFieldValue] } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleItemDisabledToggle = (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField): void => {
    if (!('clientFieldValues' in clientMediaPlanField)) {
      return
    }

    const indexFound = clientMediaPlanField.clientFieldValues.findIndex(c => c.sortOrder === clientFieldValue.sortOrder &&
                                                                  c.valueDisplayName === clientFieldValue.valueDisplayName)
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c => {
      if (c.mediaPlanFieldId === clientMediaPlanField.mediaPlanFieldId) {
        return {
          ...c,
          clientFieldValues: c.clientFieldValues.map((cv, index) => {
            if (index === indexFound) {
              return { ...cv, isDisabled: !cv.isDisabled }
            } else {
              return cv
            }
          })
        }
      } else {
        return c
      }
    })
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleItemSort = (clientMediaPlanField: IClientMediaPlanField, direction: 'asc' | 'dsc'): void => {
    const sortedValues = [...clientMediaPlanField.clientFieldValues]
    // eslint-disable-next-line functional/immutable-data
    sortedValues.sort((a, b) => compare(a.valueDisplayName.toLowerCase(), b.valueDisplayName.toLowerCase(), direction === 'asc'))
    sortedValues.forEach((v, index) => {
      // eslint-disable-next-line functional/immutable-data
      v.sortOrder = index + 1
    })
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldId === clientMediaPlanField.mediaPlanFieldId ? { ...c, clientFieldValues: sortedValues } : c
    )
    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleItemOrder = (clientFieldValue: ClientFieldValue, clientMediaPlanField: IClientMediaPlanField, direction: 'up' | 'down'): void => {
    if (!('clientFieldValues' in clientMediaPlanField)) {
      return
    }

    const currentIndex = clientMediaPlanField.clientFieldValues.findIndex(c => c.clientFieldValueId === clientFieldValue.clientFieldValueId)
    let position

    if (direction === 'up' && clientFieldValue.sortOrder > 0) {
      position = currentIndex - 1
    } else if (direction === 'down' && clientFieldValue.sortOrder < clientMediaPlanField.clientFieldValues?.length) {
      position = currentIndex + 1
    }

    const updatedValue = { ...clientMediaPlanField.clientFieldValues?.[position] }

    const newClientFieldsValues = clientMediaPlanField.clientFieldValues?.map((c, index) => {
      if (position === index) {
        return { ...c, ...clientFieldValue, sortOrder: c.sortOrder }
      } else if (currentIndex === index) {
        return { ...c, ...updatedValue, sortOrder: c.sortOrder }
      } else {
        return c
      }
    })
    const updatedClientMediaPlanFields = clientMediaPlanFields.map(c =>
      c.mediaPlanFieldId === clientMediaPlanField.mediaPlanFieldId ? { ...c, clientFieldValues: newClientFieldsValues } : c
    )

    updateClientMediaFields(updatedClientMediaPlanFields)
  }

  const handleEditConnections = () => navigate(appPaths.fieldConnections)

  return (
    <>
      <TitleAndButtonsComponent
        title='Client Setup'
        subtitle='Edit current fields'
        extraContent={() => (
          <Checkbox
            data-testid='alias-template'
            className='content-top-right-checkbox'
            checked={clientSettings.isTemplateAliasingEnabled}
            onChange={ (event) => handleToggleAliasDisabled(event)}
          >Enable Template Alias</Checkbox>
        )}
        tooltipText={clientSetupText}
      />

      <MSLayoutContentRow>
        <ClientDataFilter
          handleFilter={handleFilter}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow fillAvailableSpace={true} extraClass='ms-selection-table data-terms-table'>
        <DataTermsTable
          selectedMediaPlanTerms={clientMediaPlanFields.filter(c => c.isVisible)}
          handleMandatoryToggle={(row) => handleMandatoryToggle(row)}
          handleAliasAddition={(row, event) => handleAliasAddition(row, event)}
          handleDisableToggle={(row) => handleDisabled(row)}
          handleDropdownListValue={handleDropdownListValue}
          handleItemDisabledToggle={handleItemDisabledToggle}
          handleItemSort={handleItemSort}
          handleItemOrder={handleItemOrder}
          handleIsApportioned={handleIsApportioned}
          handleIsApportionmentField={handleIsApportionmentField}
          handleIncludeInSubtotals={handleIncludeInSubtotals}
        />
      </MSLayoutContentRow>

      <MSLayoutContentRow extraClass='bottom-button-wrapper'>
        <Button
          type='primary'
          onClick={handleEditConnections}
        >
          Edit Connections
        </Button>
        <Button
          type='primary'
          style={{ margin: '0px 10px' }}
          onClick={handlePrevious}
        >Add Fields</Button>
        <Button
          data-testid='submit-button'
          loading={saving}
          type='primary'
          onClick={handleSubmit}
        >Submit</Button>
      </MSLayoutContentRow>
    </>
  )
}

export default ClientDataTermsContainer
