import { useMemo } from 'react'
import { IFlight } from '../constants/entities/IFlight'

export const useSubFlightsDates = (flights: IFlight[]) => {
  const subFlightDates = useMemo(() => {
    return flights?.map((flight, flightIndex) =>
      flight?.subFlights?.map((subFlight, subFlightIndex) => ({
        subFlightStartDate: subFlight.subFlightStartDate,
        subFlightEndDate: subFlight.subFlightEndDate,
        clientMediaPlanFieldId: subFlight.clientMediaPlanFieldId,
        flightIndex,
        subFlightIndex,
        merge: subFlight.merge,
        width: subFlight.width
      }))
    ).flat()
  },
  [
    flights
  ])

  return subFlightDates
}
