import { useMemo } from 'react'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import { useAppSelector } from '../../../store'
import { selectParseData } from '../../../selectors'
import { getDataRelevantForLevel, ILevelData } from '../helpers/getDataRelevantForLevel'

export const useDataRelevantForLevel = (flightGroupIndex?: number, flightIndex?: number, subFlightIndex?: number): ILevelData => {
  const parseData = useAppSelector(selectParseData)

  const levelsData = useMemo(() => (
    getDataRelevantForLevel(parseData, flightGroupIndex, flightIndex, subFlightIndex)
  ), [parseData, flightGroupIndex, flightIndex, subFlightIndex])

  const memoizedLevelsData = useDeepEqualMemoized(levelsData)

  return memoizedLevelsData
}
