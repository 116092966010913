import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClientMediaPlanFields: build.query<
      GetClientMediaPlanFieldsApiResponse,
      GetClientMediaPlanFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/clientMediaPlanFields`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    postClientMediaPlanFields: build.mutation<
      PostClientMediaPlanFieldsApiResponse,
      PostClientMediaPlanFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/clientMediaPlanFields`,
        method: "POST",
        body: queryArg.body,
        params: {
          clientId: queryArg.clientId,
          validate: queryArg.validate,
        },
      }),
    }),
    putClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAlias:
      build.mutation<
        PutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasApiResponse,
        PutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasApiArg
      >({
        query: (queryArg) => ({
          url: `/clientMediaPlanFields/${queryArg.clientMediaPlanFieldId}/approveAlias`,
          method: "PUT",
          body: queryArg.approveAliasRequest,
          params: {
            clientId: queryArg.clientId,
          },
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetClientMediaPlanFieldsApiResponse =
  /** status 200 Returns list of Client Media Plan Fields */ ClientMediaPlanFieldRead[];
export type GetClientMediaPlanFieldsApiArg = {
  clientId: number;
};
export type PostClientMediaPlanFieldsApiResponse = unknown;
export type PostClientMediaPlanFieldsApiArg = {
  clientId: number;
  validate?: boolean;
  body: ClientMediaPlanField[];
};
export type PutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasApiResponse =
  unknown;
export type PutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasApiArg =
  {
    clientId: number;
    clientMediaPlanFieldId?: number;
    /** true or false. true - to approve; false - to unapprove */
    approveAliasRequest: ApproveAliasRequest;
  };
export type MappingDestinationOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 100 | 200;
export type FieldDataType = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldDataTypeRead = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  isLookupType?: boolean;
  isClientDefinedListOrUniqueString?: boolean;
  isHierarchy?: boolean;
  isClientHierarchy?: boolean;
  isClientList?: boolean;
  isFinance?: boolean;
  isFinanceAudience?: boolean;
  isCostDatabase?: boolean;
  isParameterizedByGeographyAndMedia?: boolean;
  isNumeric?: boolean;
  isLongInt?: boolean;
  isDecimalType?: boolean;
  isString?: boolean;
  isRichText?: boolean;
  isDate?: boolean;
  cSharpDataType?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldLevel = {
  fieldLevelId?: number;
  fieldLevelName?: string | null;
};
export type FieldTag = {
  fieldTagId?: number;
  fieldTagName?: string | null;
  fieldTagDescription?: string | null;
};
export type MediaPlanFieldLevel = {
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  fieldDataTypeId?: number;
  columnName?: string | null;
  fieldLevelId?: number;
  fieldDescription?: string | null;
  isMandatory?: boolean;
  sortOrder?: number;
  defaultValue?: string | null;
  isApportionable?: boolean;
  isDefaultApportionmentField?: boolean;
  isDefaultApportioned?: boolean;
  fieldDataType?: FieldDataType;
  fieldLevel?: FieldLevel;
  fieldTags?: FieldTag[] | null;
};
export type MediaPlanFieldLevelRead = {
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  fieldDataTypeId?: number;
  columnName?: string | null;
  fieldLevelId?: number;
  fieldDescription?: string | null;
  isMandatory?: boolean;
  sortOrder?: number;
  defaultValue?: string | null;
  isApportionable?: boolean;
  isDefaultApportionmentField?: boolean;
  isDefaultApportioned?: boolean;
  fieldDataType?: FieldDataTypeRead;
  fieldLevel?: FieldLevel;
  fieldTags?: FieldTag[] | null;
};
export type ClientFieldValue = {
  clientFieldValueId?: number;
  mediaPlanFieldId?: number;
  valueDisplayName?: string | null;
  isDisabled?: boolean;
  modifiedBy?: string | null;
  modifiedOn?: string;
  sortOrder?: number;
};
export type ClientMediaPlanField = {
  clientMediaPlanFieldId?: number;
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  isMandatory?: boolean;
  isDisabled?: boolean;
  sortOrder?: number;
  submittedBy?: string | null;
  submittedOn?: string;
  approvedBy?: string | null;
  approvedOn?: string | null;
  isApportioned?: boolean;
  isApportionmentField?: boolean;
  includeInSubtotals?: boolean;
  mediaPlanField?: MediaPlanFieldLevel;
  clientFieldValues?: ClientFieldValue[] | null;
};
export type ClientMediaPlanFieldRead = {
  clientMediaPlanFieldId?: number;
  mediaPlanFieldId?: number;
  mediaPlanFieldLevelId?: number;
  fieldLabel?: string | null;
  isMandatory?: boolean;
  isDisabled?: boolean;
  sortOrder?: number;
  submittedBy?: string | null;
  submittedOn?: string;
  approvedBy?: string | null;
  approvedOn?: string | null;
  isApportioned?: boolean;
  isApportionmentField?: boolean;
  includeInSubtotals?: boolean;
  mediaPlanField?: MediaPlanFieldLevelRead;
  clientFieldValues?: ClientFieldValue[] | null;
};
export type ApproveAliasRequest = {
  approve?: boolean;
};
export const {
  useGetClientMediaPlanFieldsQuery,
  useLazyGetClientMediaPlanFieldsQuery,
  usePostClientMediaPlanFieldsMutation,
  usePutClientMediaPlanFieldsByClientMediaPlanFieldIdApproveAliasMutation,
} = injectedRtkApi;
