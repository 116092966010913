import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react'
import { Form, Row, Col, Space, Button, message } from 'antd'
import { setCurrentRedistribution } from 'Actions/mediaPlansActions'
import { IMSHierarchies } from '@mindshare/layout'
import { RedistributionConfigNameSetupForm, RedistributionFieldSetupForm, RedistributionPercentageSetup } from 'Components/Redistribution/components/index'
import { useRedistributionFormValues } from 'Components/Redistribution/hooks/useRedistributionFormValues'
import { useRedistributionOnSubmit } from 'Components/Redistribution/hooks/useRedistributionOnSubmit'
import { ApplyRedistributionComponent } from 'Components/Redistribution/components/ApplyRedistributionComponent'
import { getFieldValuesByLevelId, getUniqueFieldValues } from 'Helpers/redistributionHelpers'
import { isDateType } from 'Components/shared/constants/entities/IFieldMetaData'
import { getFieldDataType } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { formatDate } from 'Helpers/formatDate'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { DraggableModalContainer } from 'Containers/MediaPlanVersion/DraggableModalContainer'
import { useAppDispatch, useAppSelector } from '../../../store'
import { useRedistributionInitialValues } from '../hooks/useRedistributionInitialValues'
import { selectCurrentMediaPlanVersionFields, selectRedistributeByFields, selectParseData } from '../../../selectors'

interface IProps {
  hideCreateModal: () => void
  hideModal: () => void
  visible: boolean
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  financeList: IMediaPlanVersionFinanceListFields
}

enum CreateRedistributionSteps {
  CONFIG_NAME_SETUP,
  FIELDS_SELECTION,
  PERCENTAGE_SETUP,
  APPLY_REDISTRIBUTION
}

export const RedistributionCreateContainer: FunctionComponent<IProps> = ({
  hideCreateModal,
  hideModal,
  visible,
  hierarchies,
  masteredListsData,
  financeList
}) => {
  const currentRedistributionId = useAppSelector(
    state => state.mediaPlans.currentRedistribution?.redistributionId
  )
  const [redistributionConfigNameForm] = Form.useForm()
  const [redistributionFieldsForm] = Form.useForm()
  const [step, setStep] = useState(CreateRedistributionSteps.CONFIG_NAME_SETUP)
  const [amountToRedistribute, setAmountToRedistribute] = useState(null)
  const initialValues = useRedistributionInitialValues(undefined)
  const {
    formValues,
    setFieldValue,
    resetForm,
    updateFieldValue,
    removeFieldValue,
    addFieldValue
  } = useRedistributionFormValues(initialValues)
  const onSubmit = useRedistributionOnSubmit()
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)
  const mediaPlanField = mediaPlanVersionFields.find((field) => field.clientMediaPlanFieldId === formValues.fieldToRedistributeById)
  const selectedRedistributeByFields = useAppSelector(selectRedistributeByFields)
  const parseData = useAppSelector(selectParseData)
  const { dataTypeName } = getFieldDataType(mediaPlanField) || {}

  const dispatch = useAppDispatch()

  const fieldValues = getFieldValuesByLevelId(mediaPlanField, parseData, masteredListsData, hierarchies, financeList)
  const uniqueFieldValues = getUniqueFieldValues(fieldValues)
  const fieldLabel = mediaPlanField?.fieldLabel || 'Redistribute by field'

  const fieldsAmount = useMemo(() => selectedRedistributeByFields.reduce((acc, item) => acc + Number(item.value), 0) as number, [selectedRedistributeByFields])
  const isNextStepDisabled = useMemo(() => {
    const {
      redistributionName,
      fieldToRedistributeId,
      fieldToRedistributeById,
      redistributeByValues
    } = formValues

    return (
      (step === CreateRedistributionSteps.CONFIG_NAME_SETUP && !redistributionName) ||
      (step === CreateRedistributionSteps.FIELDS_SELECTION && (!fieldToRedistributeId || !fieldToRedistributeById)) ||
      (step === CreateRedistributionSteps.PERCENTAGE_SETUP && !redistributeByValues.length)
    )
  }, [step, formValues])

  const hideModalForm = useCallback( () => {
    resetForm()
    dispatch(setCurrentRedistribution(null))
    hideModal()
  }, [hideModal, resetForm, dispatch])

  const handleNextStep = useCallback(async () => {
    try {
      if (step === CreateRedistributionSteps.CONFIG_NAME_SETUP) {
        await redistributionConfigNameForm.validateFields()
      } else if (step === CreateRedistributionSteps.FIELDS_SELECTION && uniqueFieldValues.length < 2) {
        message.error({ content: `Amount of the ${fieldLabel} values should be greater than 1` })
        return
      }
      setStep(step + 1)
    } catch {
      setStep(step)
    }
  }, [redistributionConfigNameForm, step, uniqueFieldValues.length, fieldLabel])

  const handleSubmit = useCallback(async () => {
    const newData = {
      ...formValues,
      redistributeByValues: formValues.redistributeByValues?.map(item => {
        const value = isDateType(dataTypeName)
          ? formatDate(item.valueId)
          : item.valueId

        return {
          ...item,
          value: value?.toString() || ''
        }
      })
    }
    const result = await onSubmit({
      ...newData,
      redistributionId: currentRedistributionId
    })
    if (result) {
      message.success({
        content: `Redistribution ${
          currentRedistributionId ? 'updated' : 'created'
        } successfully`
      })
      handleNextStep()
    }
  }, [
    formValues,
    onSubmit,
    handleNextStep,
    currentRedistributionId,
    dataTypeName
  ])

  const handlePrevStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    if (fieldsAmount > 0) {
      setAmountToRedistribute(fieldsAmount)
    } else {
      setAmountToRedistribute(null)
    }
  }, [fieldsAmount])

  const handleAmountChange = (value: number) => {
    setAmountToRedistribute(value)
  }

  const renderFormSteps = () => {
    switch (step) {
      case CreateRedistributionSteps.CONFIG_NAME_SETUP:
        return (
          <RedistributionConfigNameSetupForm
            formInstance={redistributionConfigNameForm}
            onChange={setFieldValue}
          />
        )
      case CreateRedistributionSteps.FIELDS_SELECTION:
        return (
          <RedistributionFieldSetupForm
            formInstance={redistributionFieldsForm}
            setFieldValue={setFieldValue}
            {...formValues}
          />
        )
      case CreateRedistributionSteps.PERCENTAGE_SETUP:
        return (
          <RedistributionPercentageSetup
            updateFieldValue={updateFieldValue}
            removeFieldValue={removeFieldValue}
            addFieldValue={addFieldValue}
            hierarchies={hierarchies}
            masteredListsData={masteredListsData}
            financeList={financeList}
            {...formValues}
          />
        )
      default:
        return null
    }
  }

  const title = (
    <div className='draggable-container'>
      Redistribute
    </div>
  )

  return (
    <>
      {step === CreateRedistributionSteps.APPLY_REDISTRIBUTION ? (
        <ApplyRedistributionComponent
          hideModal={hideModalForm}
          goBack={handlePrevStep}
          amountToRedistribute={amountToRedistribute}
          onChange={handleAmountChange}
          formValues={formValues}
        />
      ) : (
        <DraggableModalContainer
          width={800}
          footer={null}
          onCancel={hideModal}
          title={title}
          open={visible}
          className='redistribution-modal'
          modalBody={(
            <>
              {renderFormSteps()}
              <Row justify='space-between' className='modal-footer'>
                <Col>
                  <Space>
                    <Button
                      aria-label='Cancel'
                      onClick={hideModalForm}
                    >
              Cancel
                    </Button>
                    <Button
                      aria-label='Back'
                      onClick={step === CreateRedistributionSteps.CONFIG_NAME_SETUP ? hideCreateModal : handlePrevStep}
                      type='primary'
                    >
              Back
                    </Button>
                    {step === CreateRedistributionSteps.PERCENTAGE_SETUP ? (
                      <Button
                        aria-label='Last Step'
                        type='primary'
                        htmlType='submit'
                        onClick={handleSubmit}
                        disabled={isNextStepDisabled}
                      >
              Select Flights...
                      </Button>
                    ) : <Button
                      aria-label='Next Step'
                      type='primary'
                      onClick={handleNextStep}
                      disabled={isNextStepDisabled}
                    >
              Next
                    </Button>
                    }
                  </Space>
                </Col>
              </Row>
            </>
          )}
        />
      )}
    </>
  )
}
