import React, { FunctionComponent, useState, useEffect, useCallback, useMemo } from 'react'
import { Button, Checkbox, Input, Modal, Select, Tooltip } from 'antd'
import classNames from 'classnames'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MinusCircleOutlined, PlusCircleOutlined, DragOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  NUMBER_TYPES,
  isBlank,
  isClientHierarchyType,
  getClientHierarchyTypeDetails,
  getClientHierarchyNode,
  IMSHierarchies,
  IClientHierarchy,
  IGenericHierarchies,
  getHierarchyNode
} from '@mindshare/layout'
import { TemplateFieldType } from 'Apis/generated/templateFieldTypesApi'
import { TemplateFieldAvailability } from 'Apis/generated/templateFieldAvailabilitiesApi'
import { FieldDataTypeRead } from 'Apis/generated/fieldDataTypesApi'
import { LinkedLookup, LinkedLookupValue } from 'Apis/generated/linkedLookupsApi'
import { IMediaPlanFieldRow } from 'Components/MediaPlanField/constants/entities/IMediaPlanFieldRow'
import {
  isDateType,
  isRichTextType,
  isMediaPartnersType,
  usesDropDownInput,
  isFinanceTargetAudienceListType,
  isFinanceBuyingAudienceListType,
  isFinanceBookingCategoryList,
  isFinanceProductHierarchyType,
  isFinanceStationHierarchyType,
  isClientCampaignsType,
  isCostBuyingRouteHierarchyType,
  isClientAgenciesType
} from 'Components/shared/constants/entities/IFieldMetaData'
import { getDisplayLabel, IClientMediaPlanField } from 'Components/Client/constants/entities/IClientMediaPlanField'
import { TemplateFieldTypes } from 'Constants/enums/TemplateFieldTypes'
import { isAggregatedOrCalculated, IMediaPlanTemplateFields, isAggregated } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { compare } from 'Helpers/sortHelper'
import FieldAliasComponent from 'Components/FieldAliasComponent'
import RichTextComponent from 'Components/RichTextComponent'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { IRowFunctions } from 'Components/TemplateFieldSelection/hooks/useTemplateFieldRows'
import MediaPlanInputComponent from 'Components/MediaPlanInputComponent'
import ModalComponent from 'Components/ModalComponent'
import { useModal } from 'Hooks/CustomHooks'
import { ILinkedLookupFieldsHelperValues } from 'Components/LinkedLookup/constants/entities/ILinkedLookupHelperValues'
import { isFieldOfSameOrHigherLevel } from 'Components/LinkedLookup/helpers/fieldHelper'
import { MasteredFieldValidationMessage } from 'Constants/enums/MasteredFieldValidationMessage'
import {
  ITemplateMasteredFieldsHelperValues,
  IMasteredHierachiesHelperValues,
  ITemplateGetFinanceListFields
} from 'Components/TemplateFieldSelection/constants/entities/ITemplateMasteredFieldsHelperValues'
import { useGetTemplateFinanceListFields, useGetTemplateMasteredHierarchiesFields } from 'Components/TemplateFieldSelection/hooks/useGetTemplateMasteredFields'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { TreeSelectComponent } from 'Components/Hierarchies/components/TreeSelectComponent'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { IClientSettingExtended } from 'Components/Client/constants/entities/IClientSetting'
import { assignHierarchiesTreeNodeKeys } from 'Components/Hierarchies/constants/entities/IHierarchies'
import { SelectTooltip } from 'Components/SelectTooltip'
import TemplatePlanFieldValueMasteredListSelectComponent from './TemplatePlanFieldValueMasteredListSelectComponent'
import { TemplateSpecificOptionsModal } from './TemplateSpecificOptionsModal'
import { IValidationResult } from './constants/entities/IValidationResult'

const allowsAggregate = NUMBER_TYPES
const shouldNotIncludeCalculated = [
  'Client defined list',
  'Business hierarchy',
  'Brand hierarchy',
  'Media hierarchy',
  'Geography hierarchy',
  'Unique string',
  'Media partners',
  'Rich text',
  'Finance target audience list',
  'Finance buying audience list',
  'Finance booking category list',
  'Finance product hierarchy',
  'Finance station hierarchy',
  'Cost buying route hierarchy'
]

export interface ITemplatePlanFieldItemProps {
  availableMetaData: IClientMediaPlanField[]
  templateFieldType: TemplateFieldType[]
  hierarchies: IMSHierarchies
  selectedHierarchies: { geography: number; media: number; business: number; brand: number }
  unfilteredHierarchies: IMSHierarchies
  isAliased: boolean
  item: IMediaPlanFieldRow
  masteredListsData: IMasteredListsData
  rowFunctions: IRowFunctions
  templateAvailability: TemplateFieldAvailability[]
  tabLevel: FieldLevelType
  linkedLookupFieldsHelperValues: ILinkedLookupFieldsHelperValues
  masteredDataHelperValues: ITemplateMasteredFieldsHelperValues
  errors: IValidationResult[]
  requiredHierarchies: Partial<IClientSettingExtended>
}

export const TemplatePlanFieldItemComponent: FunctionComponent<ITemplatePlanFieldItemProps> = ({
  item,
  hierarchies,
  selectedHierarchies,
  unfilteredHierarchies,
  availableMetaData,
  masteredListsData,
  templateFieldType,
  templateAvailability,
  isAliased,
  rowFunctions,
  tabLevel,
  linkedLookupFieldsHelperValues,
  masteredDataHelperValues,
  errors,
  requiredHierarchies
}) => {
  const { open, close, isOpen } = useModal(false)
  const isManual = item.templateFieldTypeId === TemplateFieldTypes.MANUAL ? 'Default' : ''
  const [activeFieldType, setCurrentFieldType] = useState(isManual)
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.itemRowId })

  const style: React.CSSProperties = {
    transition,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    ...(isDragging ? { position: 'relative' } : {})
  }

  const {
    availableLinkedLookupOptions,
    isLinkedFieldDisabled,
    isFilteredLookupValuesVisible,
    isEnforcedLink,
    referencedMediaPlanField,
    dependentMediaPlanField,
    linkedLookupConnection,
    isReferencedFieldMandatory
  } = linkedLookupFieldsHelperValues?.[item.mediaPlanField.mediaPlanFieldId] ?? {
    availableLinkedLookupOptions: undefined,
    isLinkedFieldDisabled: false,
    isFilteredLookupValuesVisible: false,
    isEnforcedLink: false,
    dependentMediaPlanField: undefined,
    referencedMediaPlanField: undefined
  }

  const {
    isFieldInvalid,
    geographyHierarchyId,
    mediaHierarchyId,
    isFieldDisabled
  } = masteredDataHelperValues?.[item.clientMediaPlanFieldId] ?? {
    isFieldInvalid: false,
    geographyHierarchyId: undefined,
    mediaHierarchyId: undefined,
    isFieldDisabled: false
  }

  const hasFieldError = useMemo(
    () =>
      !!errors.find(
        e => e.clientMediaPlanFieldId === item.clientMediaPlanFieldId
      ),
    [errors, item.clientMediaPlanFieldId]
  )

  const financeListFieldsData = useGetTemplateFinanceListFields({ geographyHierarchyId, mediaHierarchyId, currentField: item })
  const {
    getMasteredHierarchiesValues,
    isMasteredHierarchyDisabled
  } = useGetTemplateMasteredHierarchiesFields({ geographyHierarchyId, mediaHierarchyId, currentField: item })

  const { isEnforcedCombinationInvalid } = Object.values(
    linkedLookupFieldsHelperValues
  ).find(
    v =>
      (v.referencedMediaPlanField as IMediaPlanFieldRow)?.mediaPlanField.mediaPlanFieldId ===
      item.mediaPlanField.mediaPlanFieldId
  ) ?? {
    isEnforcedCombinationInvalid: () => false
  }

  const isEnforcedCombinationError = isEnforcedCombinationInvalid(item)

  const fieldDataType = item.mediaPlanField?.fieldDataType as FieldDataTypeRead

  const isHierarchy = fieldDataType && isClientHierarchyType(fieldDataType.dataTypeName)
  const isSelectedHierarchy = isHierarchy &&
    item.mediaPlanField.fieldLevelId === FieldLevelType.PLAN &&
    selectedHierarchies[fieldDataType.dataTypeName.split(' ')[0].toLowerCase()]
  const isClientHierarchy = isHierarchy &&
  item.mediaPlanField.fieldLevelId === FieldLevelType.PLAN &&
  fieldDataType.isClientHierarchy
  const isRichText = fieldDataType && isRichTextType(fieldDataType.dataTypeName)
  const isFinanceListFieldType = [
    FieldDataType.FINANCE_BOOKING_CATEGORY_LIST,
    FieldDataType.FINANCE_BUYING_AUDIENCE_LIST,
    FieldDataType.FINANCE_TARGET_AUDIENCE_LIST
  ].includes(fieldDataType?.fieldDataTypeId)
  const isRequiredHierarchy = isHierarchy &&
    item.mediaPlanField.fieldLevelId === FieldLevelType.PLAN &&
    requiredHierarchies[`is${fieldDataType.dataTypeName.split(' ')[0]}Required`]

  const doesNotAllowCalculated = (
    item.mediaPlanField && fieldDataType && shouldNotIncludeCalculated.some(c => c === fieldDataType.dataTypeName)
  ) || false
  const doesAllowAggregate = (
    item.mediaPlanField && fieldDataType && allowsAggregate.some(c => c === fieldDataType.dataTypeName)
  ) && item.mediaPlanField.fieldLevelId !== FieldLevelType.SUB_FLIGHT

  /* if the selected default value is not in the hierarchy list, then set it to blank */
  useEffect(() => {
    if (isHierarchy && item.defaultValue) {
      let hierarchyNode: IClientHierarchy | IGenericHierarchies
      let isHierarchiesLoaded = false
      const isMasteredHierarchy =
        isFinanceProductHierarchyType(fieldDataType.dataTypeName) ||
        isFinanceStationHierarchyType(fieldDataType.dataTypeName) ||
        isCostBuyingRouteHierarchyType(fieldDataType.dataTypeName)
      if (isMasteredHierarchy) {
        const { hierarchyList, hierarchyType, isLoaded } = getMasteredHierarchiesValues(fieldDataType.fieldDataTypeId)
        hierarchyNode = getHierarchyNode(hierarchyList, hierarchyType, Number(item.defaultValue))
        isHierarchiesLoaded = isLoaded

        if (!geographyHierarchyId || !mediaHierarchyId || isMasteredHierarchyDisabled) {
          rowFunctions.setFieldValue(null, item)
        }
      } else {
        const { hierarchyList, hierarchyType } = getClientHierarchyTypeDetails(fieldDataType.dataTypeName, unfilteredHierarchies)
        hierarchyNode = getClientHierarchyNode(hierarchyList, hierarchyType, Number(item.defaultValue))
        isHierarchiesLoaded = !!hierarchyList && hierarchyList.length > 0
      }
      if (isHierarchiesLoaded && !hierarchyNode && !isMasteredHierarchy) {
        rowFunctions.setFieldValue(null, item)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldDataType, isHierarchy, item, unfilteredHierarchies, getMasteredHierarchiesValues, geographyHierarchyId, mediaHierarchyId, isMasteredHierarchyDisabled])

  /* if geography or media value is absent then set finance list field default value to blank */
  useEffect(() => {
    if (isFinanceListFieldType && item.defaultValue) {
      if (!geographyHierarchyId || !mediaHierarchyId || isFieldDisabled) {
        rowFunctions.setFieldValue(null, item)
      }
    }
  }, [item, rowFunctions, geographyHierarchyId, mediaHierarchyId, isFieldDisabled, isFinanceListFieldType])

  const fieldTypeFilter = useCallback((): TemplateFieldType[] => {
    if (doesNotAllowCalculated) {
      return templateFieldType.filter(c => c.templateFieldTypeName !== 'Calculated' && c.templateFieldTypeName !== 'Aggregated')
    } else if (doesAllowAggregate) {
      return templateFieldType
    } else {
      return templateFieldType.filter(c => c.templateFieldTypeName !== 'Aggregated')
    }
  }, [doesAllowAggregate, doesNotAllowCalculated, templateFieldType])

  const onFieldTypeChange = useCallback((type: string) => {
    const newValue = Number(type)
    const fromAggregatedToCalculated = isAggregated(item) && newValue === TemplateFieldTypes.CALCULATED

    if (!newValue) {
      setCurrentFieldType('Default')
      rowFunctions.handleFieldTypeChange(type, item)
    } else if (newValue === TemplateFieldTypes.MANUAL || fromAggregatedToCalculated) {
      Modal.confirm({
        title: 'This will remove the calculation from this field. Are you sure?',
        onOk () {
          setCurrentFieldType('Default')
          rowFunctions.handleFieldTypeChange(type, item)
        }
      })
    } else {
      rowFunctions.handleFieldTypeChange(type, item)
      setCurrentFieldType('')
    }
  }, [item, rowFunctions])

  const tabColumnClass = tabLevel === FieldLevelType.PLAN ? 'template-plan-tab-columns' : 'template-flight-tab-columns'
  const errorMessage =
    (isLinkedFieldDisabled &&
      `Please select a value in field ${getDisplayLabel(
        referencedMediaPlanField as IMediaPlanFieldRow
      )}`) ||
    (isFieldDisabled &&
      MasteredFieldValidationMessage.SetFieldValueErrorMessage)

  const enforcedErrorMessage = `This is not a valid combination for the value selected in ${getDisplayLabel(referencedMediaPlanField as IMediaPlanFieldRow)}`
  const encouragedErrorMessage = `This is not a recommended combination for the value selected in ${getDisplayLabel(referencedMediaPlanField as IMediaPlanFieldRow)}`

  return (
    <div className='template-plan-field-item' ref={setNodeRef} style={style} {...attributes}>
      <div className={`template-plan-columns ${tabColumnClass}`} data-testid='item-field'>
        <div data-testid='title'>
          <FieldTitle
            mediaPlanFieldRow={item}
            availableMetaData={availableMetaData}
            handleTitleChange={rowFunctions.handleTitleChange}
            disabled={(item.isMandatory || isSelectedHierarchy || isClientHierarchy) && !item.isDisabled}
            hasError={isFieldInvalid}
          />
        </div>
        <div>
          <FieldAlias mediaPlanFieldRow={item} isAliased={isAliased} setAlias={rowFunctions.setAlias} />
        </div>
        <div>
          <FieldTypes mediaPlanFieldRow={item} fieldTypeFilter={fieldTypeFilter} onFieldTypeChange={onFieldTypeChange} />
        </div>
        <div>
          <TemplatePlanFieldRenderByType
            mediaPlanFieldRow={item}
            activeFieldType={activeFieldType}
            fieldDataType={fieldDataType}
            isManual={isManual}
            masteredListsData={masteredListsData}
            hierarchies={hierarchies}
            setFieldValue={rowFunctions.setFieldValue}
            isLinkedFieldDisabled={isLinkedFieldDisabled}
            availableLinkedLookupOptions={availableLinkedLookupOptions}
            isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
            isEnforcedLink={isEnforcedLink}
            errorMessage={errorMessage}
            enforcedErrorMessage={enforcedErrorMessage}
            encouragedErrorMessage={encouragedErrorMessage}
            linkedLookupConnection={linkedLookupConnection}
            dependentMediaPlanField={dependentMediaPlanField}
            isReferencedFieldMandatory={isReferencedFieldMandatory}
            isEnforcedCombinationError={isEnforcedCombinationError}
            financeListFieldsData={financeListFieldsData}
            getMasteredHierarchiesValues={getMasteredHierarchiesValues}
            isMasteredHierarchyDisabled={isMasteredHierarchyDisabled}
            isFieldDisabled={isFieldDisabled}
            referencedMediaPlanField={referencedMediaPlanField}
            hasFieldError={hasFieldError}
          />
        </div>
        <div>
          <FieldAvailability
            mediaPlanFieldRow={item}
            isMandatory={item.isMandatory || isSelectedHierarchy || isRequiredHierarchy}
            templateAvailability={templateAvailability}
            setAvailability={rowFunctions.setAvailability}
          />
        </div>
        <div>
          <Checkbox
            data-testid='editable'
            checked={item.isEditable}
            onChange={(event: CheckboxChangeEvent) => rowFunctions.handleEditable(event.target.checked, item)}
          />
        </div>
        {tabLevel === FieldLevelType.PLAN &&
          <div>
            <Checkbox
              data-testid='is-footer'
              disabled={!isRichText}
              checked={item.isFooter}
              onChange={(event: CheckboxChangeEvent) => rowFunctions.handleFooter(event, item)}
            />
          </div>}
        <div className='option-items'>
          <Button
            className='btn-unset-default settings-icon'
            icon={<SettingOutlined />}
            data-testid='settings-icon'
            onClick={open}
            disabled={!(isHierarchy || isAggregatedOrCalculated(item))}
          />
          <ModalComponent
            width='500px'
            footer={false}
            onCancel={close}
            modalBody={<TemplateSpecificOptionsModal
              fieldDataType={fieldDataType}
              getMasteredHierarchiesValues={getMasteredHierarchiesValues}
              hierarchies={hierarchies}
              rowFunctions={rowFunctions}
              item={item}
            />}
            title='Specific options'
            open={isOpen}
            centered={true}
          />
        </div>
        <div className='item-icons'>
          <Tooltip title='Add field'>
            <Button
              className='plus-icon'
              icon={<PlusCircleOutlined />}
              data-testid='plus-icon'
              onClick={rowFunctions.handleAddButton}
            />
          </Tooltip>
          {(!(item.isMandatory || isSelectedHierarchy || isClientHierarchy) || item.isDisabled) && (
            <Tooltip title='Remove field'>
              <Button
                className='minus-icon'
                icon={<MinusCircleOutlined />}
                onClick={() => rowFunctions.handleRemoveButton(item.itemRowId)}
              />
            </Tooltip>
          )}
        </div>
        <div className='item-icons'>
          <Tooltip title='Drag field'>
            <DragOutlined
              data-testid='drag-icon'
              ref={setActivatorNodeRef}
              style={{ cursor: 'move' }}
              {...listeners}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default TemplatePlanFieldItemComponent

const renderTooltipTitle = ({ mediaPlanField }) => {
  if (!mediaPlanField.mediaPlanFieldId) return ''

  return (
    <>
      <p>{mediaPlanField.fieldDescription}</p>
      <p>Data Type: {mediaPlanField.fieldDataType?.dataTypeName}</p>
    </>
  )
}

const FieldTitle = ({ disabled, availableMetaData, mediaPlanFieldRow, handleTitleChange, hasError }) => (
  <Tooltip title={hasError ? MasteredFieldValidationMessage.FieldNameErrorMessage : renderTooltipTitle(mediaPlanFieldRow)}>
    <Select
      className={classNames('ms-select', { '--error': hasError })}
      onChange={(value: string) => handleTitleChange(Number(value), mediaPlanFieldRow)}
      placeholder={mediaPlanFieldRow.isMandatory ? mediaPlanFieldRow.fieldName : 'Select title'}
      disabled={disabled}
      value={mediaPlanFieldRow.fieldName}
      optionFilterProp='title'
      showSearch={true}
      allowClear={true}
    >
      {[...availableMetaData]
        .sort((a, b) => compare(getDisplayLabel(a), getDisplayLabel(b)))
        .map(el => (
          <Select.Option
            key={el.clientMediaPlanFieldId}
            value={el.clientMediaPlanFieldId}
            title={getDisplayLabel(el)}
          >
            <Tooltip placement="topLeft" title={renderTooltipTitle(el)}>
              {getDisplayLabel(el)}
            </Tooltip>
          </Select.Option>
        ))}
    </Select>
  </Tooltip>
)

const FieldAlias = ({ isAliased, mediaPlanFieldRow, setAlias }) => {
  return isAliased ? (
    <FieldAliasComponent
      placeholder='Alias'
      value={mediaPlanFieldRow.fieldLabel}
      approved={!isBlank(mediaPlanFieldRow.approvedOn) && mediaPlanFieldRow.savedFieldLabel === mediaPlanFieldRow.fieldLabel}
      onAliasChange={(newAlias) => setAlias(newAlias, mediaPlanFieldRow)}
    />
  ) : <></>
}

const FieldTypes = ({ mediaPlanFieldRow, onFieldTypeChange, fieldTypeFilter }) => {
  const value = mediaPlanFieldRow.templateFieldTypeId?.toString() || TemplateFieldTypes.MANUAL.toString()
  return (
    <Select
      className='ms-select'
      placeholder='Field Type'
      onChange={onFieldTypeChange}
      data-testid='templateFieldType'
      optionFilterProp='children'
      defaultValue={TemplateFieldTypes.MANUAL.toString()}
      allowClear={true}
      showSearch={true}
      value={value}
    >
      {fieldTypeFilter().map(el => (
        <Select.Option
          key={el.templateFieldTypeId}
          value={String(el.templateFieldTypeId)}
        >
          {el.templateFieldTypeLabel}
        </Select.Option>
      ))}
    </Select>
  )
}

const FieldAvailability = ({ templateAvailability, mediaPlanFieldRow, setAvailability, isMandatory }) => {
  if (isMandatory) {
    return <Input disabled={true} placeholder='Mandatory' />
  } else {
    return (
      <Select
        data-testid='availability'
        className='ms-select'
        placeholder='Availability'
        defaultValue={mediaPlanFieldRow.templateFieldAvailabilityId && mediaPlanFieldRow.templateFieldAvailabilityId.toString()}
        onChange={(fieldAvailabilityId: string) => setAvailability(fieldAvailabilityId, mediaPlanFieldRow)}
        allowClear={true}
        optionFilterProp='children'
        showSearch={true}
        value={mediaPlanFieldRow.templateFieldAvailabilityId?.toString()}
      >
        {templateAvailability.map(el => (
          <Select.Option
            key={el.templateFieldAvailabilityId}
            value={String(el.templateFieldAvailabilityId)}
          >
            {el.templateFieldAvailabilityLabel}
          </Select.Option>
        )
        )}
      </Select>
    )
  }
}

const FieldValueSelect = ({
  mediaPlanFieldRow,
  defaultValue,
  setFieldValue,
  isFilteredLookupValuesVisible,
  errorMessage,
  isEnforcedLink,
  enforcedErrorMessage,
  encouragedErrorMessage,
  linkedLookupConnection,
  dependentMediaPlanField,
  isReferencedFieldMandatory,
  isEnforcedCombinationError,
  isFinanceTargetAudience,
  isFinanceBuyingAudience,
  isFinanceBookingCategory,
  financeListFieldsData,
  availableLinkedLookupOptions = [],
  disabled = false
}) => {
  const renderOptionsByAvailability = useCallback(
    (f: any) =>
      f.options.map(option => (
        <Select.Option
          key={option.value}
          value={option.value}
          {...option.optionProps}
        >
          <Tooltip
            title={option.label}
          >
            {option.label}
          </Tooltip>
        </Select.Option>
      )),
    []
  )

  const notSelectedValues = mediaPlanFieldRow.clientFieldValues?.filter(
    item =>
      !linkedLookupConnection?.linkedLookupValues?.some(
        v => item.clientFieldValueId === v.dependentValueId
      )
  ) || []

  const filteredValues = mediaPlanFieldRow.clientFieldValues
    ?.filter(item =>
      availableLinkedLookupOptions?.some(option => item.clientFieldValueId === option.dependentValueId)
    ) || []
  const allValues = [...filteredValues, ...notSelectedValues]

  const validOptions = allValues.map(option => ({ value: option.clientFieldValueId, label: option.valueDisplayName }))

  const invalidOptions = mediaPlanFieldRow.clientFieldValues
    ?.filter(
      item =>
        !availableLinkedLookupOptions.some(
          option => item.clientFieldValueId === option.dependentValueId
        ) && !notSelectedValues.some((v) => item.clientFieldValueId === v.clientFieldValueId)
    )
    .map(option => ({
      value: option.clientFieldValueId,
      label: option.valueDisplayName,
      optionProps: {
        disabled: isEnforcedLink,
        className: 'not-valid'
      }
    })) || []

  const options = [
    ...(validOptions.length ? [{
      label: isEnforcedLink ? 'Valid' : 'Recommended',
      options: validOptions
    }] : []),
    ...(invalidOptions.length ? [{
      label: isEnforcedLink ? 'Not valid' : 'Not recommended',
      options: invalidOptions
    }] : [])
  ]
  const isDisplayingLookupOptions =
    isFilteredLookupValuesVisible &&
    (
      !!validOptions.length &&
      !!invalidOptions.length ||
      !!invalidOptions.length
    )

  const isInvalidCombination = isFilteredLookupValuesVisible &&
    !validOptions.find(
      option => option.value === Number(mediaPlanFieldRow.defaultValue)
    ) &&
    isFieldOfSameOrHigherLevel(dependentMediaPlanField, mediaPlanFieldRow)
  const isEnforcedLinkInvalid =
    isEnforcedLink &&
    isInvalidCombination &&
    (!dependentMediaPlanField?.defaultValue
      ? isReferencedFieldMandatory
      : dependentMediaPlanField?.defaultValue)
  const isEncouragedLinkInvalid = !isEnforcedLink && isInvalidCombination && dependentMediaPlanField?.defaultValue
  const error = (isEnforcedLinkInvalid && enforcedErrorMessage) || (isEncouragedLinkInvalid && encouragedErrorMessage)
  const hasError = !disabled && (isEnforcedCombinationError || isEnforcedLinkInvalid)

  const isDisplayingFinanceOptions = isFinanceTargetAudience || isFinanceBuyingAudience || isFinanceBookingCategory
  const renderFinanceOptions = financeListFieldsData[mediaPlanFieldRow.mediaPlanField.columnName]?.renderOptions
  const financeFieldDefaultValue = financeListFieldsData[mediaPlanFieldRow.mediaPlanField.columnName]?.defaultValue
  const value = isDisplayingFinanceOptions ? financeFieldDefaultValue : defaultValue

  return (
    <SelectTooltip
      key={value}
      selectProps={{
        placeholder: 'Select Value',
        onChange: setFieldValue,
        defaultValue: value,
        allowClear: true,
        optionFilterProp: 'children',
        showSearch: true,
        disabled,
        className: classNames({ '--error': hasError }),
        'data-testid': 'templateFieldValueSelect'
      }}
      tooltipProps={{
        title:
          disabled
            ? errorMessage
            : error || ''
      }}
    >
      {isDisplayingFinanceOptions ? renderFinanceOptions()
        : isDisplayingLookupOptions
          ? options.map((f, index) => (
            <Select.OptGroup key={`${f.label}${index}`} label={f.label}>
              {renderOptionsByAvailability(f)}
            </Select.OptGroup>
          ))
          : mediaPlanFieldRow.clientFieldValues &&
          mediaPlanFieldRow.clientFieldValues.map(cf => (
            <Select.Option
              key={cf.clientFieldValueId}
              value={cf.clientFieldValueId}
            >
              {cf.valueDisplayName}
            </Select.Option>
          ))}
    </SelectTooltip>
  )
}

const TemplatePlanFieldRenderByType = ({
  activeFieldType,
  fieldDataType,
  masteredListsData,
  hierarchies,
  isManual,
  mediaPlanFieldRow,
  setFieldValue,
  availableLinkedLookupOptions,
  isFilteredLookupValuesVisible,
  isEnforcedLink,
  linkedLookupConnection,
  dependentMediaPlanField,
  isReferencedFieldMandatory,
  isEnforcedCombinationError,
  financeListFieldsData,
  getMasteredHierarchiesValues,
  isMasteredHierarchyDisabled,
  isFieldDisabled,
  referencedMediaPlanField,
  hasFieldError,
  isLinkedFieldDisabled = false,
  enforcedErrorMessage = '',
  encouragedErrorMessage = '',
  errorMessage = ''
}: {
  activeFieldType: string
  fieldDataType: FieldDataTypeRead
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  isManual: string
  mediaPlanFieldRow: IMediaPlanFieldRow
  setFieldValue: IRowFunctions['setFieldValue']
  availableLinkedLookupOptions?: LinkedLookupValue[]
  isFilteredLookupValuesVisible?: boolean
  isEnforcedLink?: boolean
  isLinkedFieldDisabled?: boolean
  enforcedErrorMessage?: string
  encouragedErrorMessage?: string
  errorMessage?: string
  linkedLookupConnection?: LinkedLookup
  dependentMediaPlanField?: IMediaPlanFieldRow | IMediaPlanTemplateFields
  isReferencedFieldMandatory?: boolean
  isEnforcedCombinationError?: boolean
  financeListFieldsData: ITemplateGetFinanceListFields
  getMasteredHierarchiesValues: (key: number) => IMasteredHierachiesHelperValues
  isMasteredHierarchyDisabled: boolean
  isFieldDisabled: boolean
  referencedMediaPlanField?: IMediaPlanFieldRow | IMediaPlanTemplateFields
  hasFieldError?: boolean
}) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const isHierarchy = fieldDataType && isClientHierarchyType(fieldDataType.dataTypeName)
  const isMasteredHierarchy =
    fieldDataType &&
    (isFinanceProductHierarchyType(fieldDataType.dataTypeName) ||
      isFinanceStationHierarchyType(fieldDataType.dataTypeName) ||
      isCostBuyingRouteHierarchyType(fieldDataType.dataTypeName))
  const isMediaPartners = fieldDataType && isMediaPartnersType(fieldDataType.dataTypeName)
  const isClientCampaigns = fieldDataType && isClientCampaignsType(fieldDataType.dataTypeName)
  const isClientAgencies = fieldDataType && isClientAgenciesType(fieldDataType.dataTypeName)
  const isFinanceTargetAudience = fieldDataType && isFinanceTargetAudienceListType(fieldDataType.dataTypeName)
  const isFinanceBuyingAudience = fieldDataType && isFinanceBuyingAudienceListType(fieldDataType.dataTypeName)
  const isFinanceBookingCategory = fieldDataType && isFinanceBookingCategoryList(fieldDataType.dataTypeName)
  const isRichText = fieldDataType && isRichTextType(fieldDataType.dataTypeName)
  const isDate = fieldDataType && isDateType(fieldDataType?.dataTypeName)
  const usesDropdown = usesDropDownInput(fieldDataType?.dataTypeName)
  const [newValue, setNewValue] = useState<any>()
  const onSetFieldValue = useCallback((valueOrEvent) => {
    setFieldValue(valueOrEvent, mediaPlanFieldRow)
  }, [mediaPlanFieldRow, setFieldValue])

  const onSelectHierarchy = useCallback((name, selectedID) => {
    setFieldValue(selectedID, mediaPlanFieldRow)
  }, [mediaPlanFieldRow, setFieldValue])

  const hierachyTypeDetails = useMemo(
    () => isHierarchy ? (
      isMasteredHierarchy ? getMasteredHierarchiesValues(fieldDataType.fieldDataTypeId) : getClientHierarchyTypeDetails(fieldDataType.dataTypeName, hierarchies)
    ) : null,
    [isHierarchy, isMasteredHierarchy, hierarchies, fieldDataType, getMasteredHierarchiesValues]
  )

  const treeData = useMemo(
    () => isHierarchy ? assignHierarchiesTreeNodeKeys(hierachyTypeDetails?.hierarchyList, hierachyTypeDetails?.idProp, hierachyTypeDetails?.valueProp) : null,
    [isHierarchy, hierachyTypeDetails]
  )

  if (isRichText) {
    return (
      <div className={`rich-text-value${mediaPlanFieldRow.defaultValue ? ' --has-value' : ''}`}>
        {!mediaPlanFieldRow.defaultValue && <div className='rich-text-value__placeholder'>Click edit to add text</div>}
        <Button
          data-testid='rich-text-edit-button'
          className='rich-text-value__edit-button'
          icon={<EditOutlined />}
          onClick={() => setShowEditModal(true)}
        />
        <RichTextComponent
          value={mediaPlanFieldRow.defaultValue?.toString()}
          title={mediaPlanFieldRow.fieldName}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          updateValue={onSetFieldValue}
        />
      </div>
    )
  } else if (isHierarchy) {
    const isMasteredHierarchyFieldDisabled = isMasteredHierarchy && isMasteredHierarchyDisabled
    const linkedLookupFieldTooltip = isLinkedFieldDisabled ? errorMessage : undefined
    const masteredFieldTooltip = isMasteredHierarchyFieldDisabled ? MasteredFieldValidationMessage.SetFieldValueErrorMessage : undefined

    return (
      <TreeSelectComponent
        treeData={treeData}
        idColumn={hierachyTypeDetails?.idProp}
        valueColumn={hierachyTypeDetails?.valueProp}
        value={Number(mediaPlanFieldRow.defaultValue)}
        isDisabled={isMasteredHierarchyFieldDisabled || isLinkedFieldDisabled}
        hierarchyName={fieldDataType.dataTypeName}
        setFieldValue={onSelectHierarchy}
        isEnforcedLink={isEnforcedLink}
        linkedLookupValues={linkedLookupConnection?.linkedLookupValues}
        availableLinkedLookupOptions={availableLinkedLookupOptions}
        isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
        dependentMediaPlanField={dependentMediaPlanField}
        field={mediaPlanFieldRow}
        isReferencedFieldMandatory={isReferencedFieldMandatory}
        enforcedErrorMessage={enforcedErrorMessage}
        encouragedErrorMessage={encouragedErrorMessage}
        isEnforcedCombinationError={isEnforcedCombinationError}
        tooltipTitle={linkedLookupFieldTooltip || masteredFieldTooltip}
        isLinkedFieldDisabled={isLinkedFieldDisabled}
        referencedMediaPlanField={referencedMediaPlanField}
        hasFieldError={hasFieldError}
      />
    )
  } else if (usesDropdown && isManual === 'Default') {
    return isMediaPartners || isClientCampaigns || isClientAgencies ? (
      <Tooltip title={errorMessage}>
        <TemplatePlanFieldValueMasteredListSelectComponent
          masteredListsData={masteredListsData}
          mediaPlanFieldDataType={fieldDataType?.fieldDataTypeId}
          mediaPlanFieldRow={mediaPlanFieldRow}
          setFieldValue={onSetFieldValue}
          disabled={isLinkedFieldDisabled}
          availableLinkedLookupOptions={availableLinkedLookupOptions}
          isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
          isEnforcedLink={isEnforcedLink}
          errorMessage={errorMessage}
          enforcedErrorMessage={enforcedErrorMessage}
          encouragedErrorMessage={encouragedErrorMessage}
          linkedLookupConnection={linkedLookupConnection}
          dependentMediaPlanField={dependentMediaPlanField}
          isReferencedFieldMandatory={isReferencedFieldMandatory}
          isEnforcedCombinationError={isEnforcedCombinationError}
        />
      </Tooltip>
    ) : (
      <FieldValueSelect
        mediaPlanFieldRow={mediaPlanFieldRow}
        setFieldValue={onSetFieldValue}
        defaultValue={
          mediaPlanFieldRow.clientFieldValues && mediaPlanFieldRow.clientFieldValues
            .find((cf) => cf.clientFieldValueId === Number(mediaPlanFieldRow.defaultValue))?.valueDisplayName
        }
        disabled={isLinkedFieldDisabled || isFieldDisabled}
        availableLinkedLookupOptions={availableLinkedLookupOptions}
        isFilteredLookupValuesVisible={isFilteredLookupValuesVisible}
        isEnforcedLink={isEnforcedLink}
        errorMessage={errorMessage}
        enforcedErrorMessage={enforcedErrorMessage}
        encouragedErrorMessage={encouragedErrorMessage}
        linkedLookupConnection={linkedLookupConnection}
        dependentMediaPlanField={dependentMediaPlanField}
        isReferencedFieldMandatory={isReferencedFieldMandatory}
        isEnforcedCombinationError={isEnforcedCombinationError}
        isFinanceTargetAudience={isFinanceTargetAudience}
        isFinanceBuyingAudience={isFinanceBuyingAudience}
        isFinanceBookingCategory={isFinanceBookingCategory}
        financeListFieldsData={financeListFieldsData}
      />
    )
  } else {
    if (activeFieldType === 'Default') {
      return (
        <MediaPlanInputComponent
          id={mediaPlanFieldRow.mediaPlanTemplateFieldId || mediaPlanFieldRow.mediaPlanVersionFieldId}
          mediaPlanField={mediaPlanFieldRow}
          value={mediaPlanFieldRow.defaultValue}
          updateDataValues={onSetFieldValue}
          placeholder='Set Value'
          type={isDate ? 'date' : ''}
          setNewValue={setNewValue}
          newValue={newValue}
          isTemplate={true}
        />
      )
    } else {
      return <Tooltip title={errorMessage}><Input placeholder='Select Value' disabled={true} /></Tooltip>
    }
  }
}
