import { createSelector } from 'reselect'
import { selectApp } from '../selectors'

export const selectCurrentClient = createSelector(
  selectApp,
  app => app.currentClient
)

export const selectClients = createSelector(
  selectApp,
  app => app.clients
)
