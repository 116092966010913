import React, { CSSProperties, FunctionComponent, memo, useCallback, useEffect, useMemo } from 'react'
import { SubtotalCalculationResult } from 'Apis/generated/mediaPlanVersionsApi'
import { IMSHierarchies } from '@mindshare/layout'
import { useDndContext } from '@dnd-kit/core'
import { copyFlight, setSelectedDates, setSelectedFlights } from 'Actions/mediaPlansActions'
import { IMediaPlanTemplateFields, availableFieldsByLevel, getFieldColumnName } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { FieldLevelType } from 'Constants/enums/FieldLevel'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { IExpandedHierarchiesFlightGroupsValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IClickableFieldProps, useFlightGroupsDragAndDropHelper } from 'Components/MediaPlanVersion/hooks'
import { getSubtotalFlightFields } from 'Components/Subtotals/helpers/getSubtotalFlightFields'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { flightEndDate, flightStartDate } from 'Components/MediaPlanVersion/constants/entities/FlightDates'
import { useFlightGroupCommands } from 'Hooks/useFlightGroupCommands'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useOutsideClick } from 'Hooks/useOutsideClick'
import { useFlightGroupAutoFill } from 'Hooks/useFlightGroupAutoFill'
import { IPoint } from 'Helpers/selectionHelper'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  selectCalendarView,
  selectCollapsedFlightGroups,
  selectCurrentMediaPlanVersionFields,
  selectFlightGroupSelection,
  selectMediaPlanErrors,
  selectFlightGroups,
  selectSelectedFlights,
  selectCollapsedFlightGroupRows,
  selectFlightFields
} from '../../selectors'
import FlightGroupContainer from './FlightGroupContainer'

interface IProps {
  calculationMode: boolean
  flightGroups: IFlightGroup[]
  sortedFlightGroupFields: IFlightGroup[]
  planHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  handleAddField: (fieldType: FieldLevelType, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  unfilteredHierarchies: IMSHierarchies
  displayFlightDates: boolean
  expandedHierarchiesFlightGroupsValues: IExpandedHierarchiesFlightGroupsValues
  collapsedViewField: string
  compressedCalendarView: boolean
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  clickableFieldProps: IClickableFieldProps
  originalFlightGroups: IFlightGroup[]
  subtotals: SubtotalCalculationResult
  isLoadingHierarchies: boolean
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  currentMasteredHierarchies: Partial<IMSHierarchies>
  cachedMasteredHierarchies: Partial<IMSHierarchies>
}

export const FlightGroupsContainer: FunctionComponent<IProps> = ({
  flightGroups,
  sortedFlightGroupFields,
  planHierarchies,
  masteredListsData,
  calculationMode,
  tokenHandler,
  onAvailableFieldSelected,
  handleAddField,
  removePlanField,
  getStickyStyle,
  unfilteredHierarchies,
  displayFlightDates,
  expandedHierarchiesFlightGroupsValues,
  collapsedViewField,
  compressedCalendarView,
  masteredDataHelperValues,
  clickableFieldProps,
  originalFlightGroups,
  subtotals,
  isLoadingHierarchies,
  financeListFieldsData,
  currentMasteredHierarchies,
  cachedMasteredHierarchies
}: IProps) => {
  const availableFlightGroupFieldsSelector = useAppSelector(selectCurrentMediaPlanVersionFields)

  const availableFlightGroupFields = useMemo(() => {
    return availableFieldsByLevel(availableFlightGroupFieldsSelector, FieldLevelType.FLIGHT_GROUP)
  }, [availableFlightGroupFieldsSelector])

  const flightFields = useAppSelector(selectFlightFields)
  const availableFlightFields = useMemo(() => getSubtotalFlightFields(flightFields, subtotals), [flightFields, subtotals])

  const dispatch = useAppDispatch()

  const sortedFlightGroups = useMemo(
    () => [...originalFlightGroups].sort((current, next) => current.sortOrder - next.sortOrder),
    [originalFlightGroups]
  )
  const sortedFlightFields = useMemo(
    () => flightFields && [...flightFields, flightStartDate, flightEndDate].sort((a, b) => a.sortOrder - b.sortOrder),
    [flightFields]
  )
  const errors = useAppSelector(selectMediaPlanErrors)

  const getFieldError = useCallback(
    (clientMediaPlanFieldId: number, flightGroup: IFlightGroup) => {
      const flights = flightGroup?.flights?.filter((flight) => flight.merge)

      const currentError = errors.find(e => {
        const flightWithError = flights?.find(flight => flight.mediaPlanFlightId === e.instanceId)

        return flightWithError && e.fieldId === clientMediaPlanFieldId
      })

      return currentError?.error ?? ''
    },
    [errors]
  )

  const flightGroupSelection = useAppSelector(selectFlightGroupSelection)
  const { selectFlightGroups: doFlightGroupSelection, ...flightGroupCommands } = useFlightGroupCommands({
    flightGroups: sortedFlightGroups,
    flightGroupFields: sortedFlightGroupFields,
    masteredListsData,
    financeListFieldsData,
    currentMasteredHierarchies,
    cachedMasteredHierarchies,
    hierarchies: unfilteredHierarchies
  })
  const {
    clearSelection,
    copyFlightGroups,
    checkCanUpsert,
    upsertFlightGroups,
    checkCanCopySelection
  } = flightGroupCommands
  const calendarView = useAppSelector(selectCalendarView)
  const originalFlightGroupData = useAppSelector(selectFlightGroups)
  const collapsedFlightGroups = useAppSelector(selectCollapsedFlightGroups)
  const selectedFlights = useAppSelector(selectSelectedFlights)
  const collapsedRows = useAppSelector(selectCollapsedFlightGroupRows)
  const clearFlightAndFlightGroupSelections = useCallback((event?: MouseEvent) => {
    const withinDropdownOrModal = (event?.target as HTMLElement)?.closest('.ant-dropdown') ||
      (event?.target as HTMLElement)?.closest('.ant-picker-dropdown') ||
      (event?.target as HTMLElement)?.closest('.ant-modal-root') ||
      (event?.target as HTMLElement)?.closest('.ant-tooltip') ||
      ((event?.target as HTMLElement)?.className &&
        typeof (event?.target as HTMLElement).className.includes !== 'undefined' &&
        (event?.target as HTMLElement).className.includes('ant-scrolling-effect'))

    const withinMergeModal =
      (event?.target as HTMLElement)?.closest('.merge')

    if (withinDropdownOrModal || withinMergeModal) {
      return
    }

    clearSelection()
    dispatch(setSelectedFlights([]))
    dispatch(setSelectedDates([]))
    dispatch(copyFlight(null))
  }, [clearSelection, dispatch])

  const outsideClickRef = useOutsideClick<HTMLTableSectionElement>(clearFlightAndFlightGroupSelections)
  const { active } = useDndContext()
  const { filteredFlightGroups, checkCanMoveFlightGroup } = useFlightGroupsDragAndDropHelper({
    selectedFlightGroups: flightGroupSelection,
    flightGroups,
    activeDraggableId: Number(active?.id)
  })
  const { fillPoint } = useFlightGroupAutoFill({
    filteredFlightGroups,
    flightGroupFields: sortedFlightGroupFields,
    flightGroupSelection,
    checkCanCopySelection
  })

  useEffect(() => {
    if (selectedFlights?.length) {
      clearSelection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlights])

  const onSelect = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>,
    point: IPoint,
    fgSelection: Partial<IFlightGroup>,
    isSelected: boolean) => {
    doFlightGroupSelection(event, point, fgSelection, isSelected)
  }, [doFlightGroupSelection])

  const { data: currentClient } = useCurrentClient()
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTableSectionElement>) => {
    const handlers = {
      'ctrl+KeyC': () => checkCanCopySelection(flightGroupSelection) && copyFlightGroups(flightGroupSelection),
      'ctrl+KeyV': async () => {
        const target = event.target as HTMLElement
        const dataKey = target.getAttribute('data-key') ||
          target.closest('.flightGroup__field')?.getAttribute('data-key') ||
          target.closest('.flight__tableCell')?.getAttribute('data-key')
        if (!dataKey) return
        event.preventDefault()
        event.stopPropagation()
        const parts = dataKey.split('-')
        // data-key is in the following format
        // for flightGroup fields: flightGroupId-flightGroupIndex-fieldIndex
        // for flight fields: clientMediaPlanFieldId-flightGroupIndex-flightIndex-fieldIndex
        const flightGroupIndex = Number(dataKey.split('-')[1])
        const fieldIndex = Number(parts[parts.length - 1])
        const flightIndex = parts.length > 3 ? Number(parts[2]) : undefined
        const mediaPlanField = parts.length > 3 ? sortedFlightFields[Number(parts[3])] : sortedFlightGroupFields[Number(parts[2])]
        if (!await checkCanUpsert({ flightGroupIndex, flightGroupFieldIndex: fieldIndex }, true)) return
        upsertFlightGroups(
          currentClient.id,
          originalFlightGroupData[flightGroupIndex]?.[getFieldColumnName(mediaPlanField)],
          mediaPlanField,
          flightGroupSelection,
          flightGroupIndex,
          flightIndex
        )
      }
    }
    const handlerKey = [(event.ctrlKey || event.metaKey) && 'ctrl', event.code].filter(Boolean).join('+')
    const handler = handlers[handlerKey]

    if (handler) {
      handler()
    }
  }, [
    checkCanCopySelection,
    checkCanUpsert,
    flightGroupSelection,
    copyFlightGroups,
    upsertFlightGroups,
    sortedFlightGroupFields,
    sortedFlightFields,
    currentClient,
    originalFlightGroupData
  ])

  return (
    <tbody ref={outsideClickRef} onKeyDown={handleKeyDown}>
      {filteredFlightGroups.map((flightGroup, index) => {
        const originalIndex = originalFlightGroupData.findIndex(ofg => ofg.mediaPlanFlightGroupId === flightGroup.mediaPlanFlightGroupId)
        const flightGroupIndex = originalIndex === -1 && !flightGroup.subtotal ? index : originalIndex
        const isCollapsed = collapsedRows.includes(flightGroup.mediaPlanFlightGroupId)
        const isSubtotalGroup = !!flightGroup.subtotal
        const key = isSubtotalGroup ? `${flightGroup.mediaPlanFlightGroupId}-${index}` : flightGroup.mediaPlanFlightGroupId
        const canDragFlightGroup = checkCanMoveFlightGroup(flightGroup)
        const selectedFlightGroupsCount = flightGroupSelection && Object.keys(flightGroupSelection).length

        return (
          <FlightGroupContainer
            key={key}
            flightGroupArrayLength={flightGroups.length}
            planHierarchies={planHierarchies}
            masteredListsData={masteredListsData}
            mediaPlanFields={sortedFlightGroupFields}
            availableMediaPlanFields={availableFlightGroupFields}
            calculationMode={calculationMode}
            tokenHandler={tokenHandler}
            onAvailableFieldSelected={onAvailableFieldSelected}
            handleAddField={handleAddField}
            removePlanField={removePlanField}
            getStickyStyle={getStickyStyle}
            unfilteredHierarchies={unfilteredHierarchies}
            displayFlightDates={displayFlightDates}
            expandedHierarchiesFlightGroupsValues={expandedHierarchiesFlightGroupsValues}
            collapsedViewField={collapsedViewField}
            compressedCalendarView={compressedCalendarView}
            masteredDataHelperValues={masteredDataHelperValues}
            clickableFieldProps={clickableFieldProps}
            availableFlightFields={availableFlightFields}
            isLoadingHierarchies={isLoadingHierarchies}
            financeListFieldsData={financeListFieldsData}
            getFieldError={getFieldError}
            calendarView={calendarView}
            originalFlightGroupIndex={index}
            isSubtotalGroup={isSubtotalGroup}
            flightGroupIndex={flightGroupIndex}
            isCollapsed={isCollapsed}
            canDragFlightGroup={canDragFlightGroup}
            selectedFlightGroupsCount={selectedFlightGroupsCount}
            flightGroup={flightGroup}
            collapsedFlightGroups={collapsedFlightGroups}
            sortedFlightFields={sortedFlightFields}
            fillPoint={fillPoint}
            onSelect={onSelect}
            {...flightGroupCommands}
          />
        )
      })}
    </tbody>
  )
}

export default memo(FlightGroupsContainer)
