import React, { FunctionComponent, memo } from 'react'
import { IMSHierarchies } from '@mindshare/layout'

import {
  IMediaPlanTemplateFields,
  isFlightOrSubFlight
} from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'

import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'

import { IExpandedHierarchiesValues } from 'Components/Hierarchies/helpers/getExpandedHierarchiesValues'
import { MASTERED_FIELD_DATA_TYPES } from 'Constants/enums/FieldDataType'
import EmptyCellComponent from 'Components/MediaPlanVersion/EmptyCellComponent'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import FieldContainer from './FieldContainer'
import MasteredHierarchyFieldContainer from './MasteredHierarchyFieldContainer'

export interface IFieldContainerProps {
  calculationMode: boolean
  defaultClass?: string
  endDate?: string
  field: IMediaPlanTemplateFields
  flightGroupIndex?: number
  flightIndex?: number
  masteredListsData: IMasteredListsData
  merge?: boolean
  startDate?: string
  subFlightIndex?: number
  tokenHandler: (token: string) => void
  valid: boolean
  collapsedFlightGroup?: boolean
  linkedLookupFieldsHelperValues?: IMediaPlanVersionLinkedLookupHelperValues
  unfilteredHierarchies?: IMSHierarchies
  expandedHierarchiesValues?: IExpandedHierarchiesValues
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  masteredDataHelperValues?: IMediaPlanVersionMasteredFieldsHelperValues
  isCollapsed?: boolean
  isLoadingHierarchies?: boolean
}

export const FieldTypeSwitchContainer: FunctionComponent<IFieldContainerProps> = (props: IFieldContainerProps) => {
  const isEmptyFlightOrSubflightCell = isFlightOrSubFlight(props.field) && !props.merge
  const typeIsMasteredField = MASTERED_FIELD_DATA_TYPES.includes(props.field.clientMediaPlanField.mediaPlanField.fieldDataTypeId) ||
    MASTERED_FIELD_DATA_TYPES.includes(props.field.expandedHierarchyTypeId)

  if (
    isEmptyFlightOrSubflightCell) {
    return (
      <EmptyCellComponent fieldLabel={props.field.fieldLabel} />
    )
  }

  return typeIsMasteredField ? <MasteredHierarchyFieldContainer {...props}/> : <FieldContainer {...props} />
}

export default memo(FieldTypeSwitchContainer)
