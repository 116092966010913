import { eachMonthOfInterval, endOfMonth } from 'date-fns'
import moment from 'moment'
import { FlightSubtotal, FlightGrandTotal } from 'Apis/generated/mediaPlanVersionsApi'
import { dateToString, isDateBetween, toMomentDate, toISOString } from 'Helpers/calendarHelper'
import { IFlight } from 'Components/MediaPlanVersion/constants/entities/IFlight'

interface ISubtotalFlight extends Partial<Omit<IFlight, 'flightStartDate' | 'flightEndDate'>> {
  flightStartDate?: Date | string
  flightEndDate?: Date | string
  month?: number
}

export const getUnaffectedFlights = (flights: ISubtotalFlight[], subtotalFlightDates: FlightSubtotal[]) => {
  if (!flights) return []

  return flights.filter((f) => (
    !subtotalFlightDates?.some((item) => {
      const startDateStr = dateToString(item.startDate)
      const endDateStr = dateToString(item.endDate)

      if (isDateBetween(startDateStr, endDateStr, dateToString(f.flightStartDate)) || isDateBetween(startDateStr, endDateStr, dateToString(f.flightEndDate))) {
        return true
      }

      if (
        isDateBetween(
          dateToString(f.flightStartDate),
          dateToString(f.flightEndDate),
          startDateStr
        ) ||
        isDateBetween(
          dateToString(f.flightStartDate),
          dateToString(f.flightEndDate),
          endDateStr
        )
      ) {
        return true
      }

      return false
    })
  ))
}

export const getMonthIntervals = (planStartDate: string, planEndDate: string) => {
  const months = eachMonthOfInterval({ start: toMomentDate(planStartDate).toDate(), end: toMomentDate(planEndDate).toDate() })

  return months.map((m, idx) => {
    const currentEndMonth = endOfMonth(m)

    return {
      flightStartDate: m,
      flightEndDate: currentEndMonth,
      month: idx + 1
    }
  })
}

export const getCalculatedGrandTotalFlights = (flights: FlightGrandTotal[], planStartDate: string, planEndDate: string) => {
  const flightsDates = flights.map((f) => ({
    ...f,
    startDate: dateToString(f.startDate),
    endDate: dateToString(f.endDate)
  }))
  const monthIntervals = getMonthIntervals(planStartDate, planEndDate)
  const unaffectedFlights = getUnaffectedFlights(monthIntervals, flightsDates)
  const formattedUnaffectedFlights = unaffectedFlights.map((f) => ({
    ...f,
    startDate: dateToString(f.flightStartDate),
    endDate: dateToString(f.flightEndDate)
  }))
  const flightList: FlightGrandTotal[] = [...formattedUnaffectedFlights, ...flightsDates].sort((a, b) => moment.utc(a.startDate).diff(b.startDate))

  return flightList.map((flight: FlightGrandTotal, idx: number) => {
    const planStartDateISO = toISOString(toMomentDate(planStartDate))

    return (
      idx === 0
        ? {
          ...flight,
          startDate: planStartDateISO
        }
        : flight
    )
  })
}
