import React from 'react'
import { List, Typography, Button, Popconfirm, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import { TabularLookupWeb } from 'Apis/generated/tabularLookupApi'
import { useDeleteTabularLookup } from 'Components/TabularLookup/hooks/useDeleteTabularLookup'
import { popoverMessage } from 'Constants/enums/PopoverMessage'
import { TabularLookupLevel } from 'Components/TabularLookup/enums/tabularLookupLevel'

const { Text } = Typography

interface ITabularLookupListProps {
  tabularLookups: TabularLookupWeb[]
  canRemove?: boolean
  nextStep: () => void
  setTabularLookupId: (id: number) => void
  isTemplate: boolean
}

export const TabularLookupListComponent: React.FC<ITabularLookupListProps> = ({ tabularLookups, nextStep, setTabularLookupId, isTemplate, canRemove = true }) => {
  const { execute: deleteTabularLookup } = useDeleteTabularLookup()

  return (
    <List
      bordered={true}
      dataSource={tabularLookups}
      className='tabular-lookup__list'
      renderItem={(item) => {
        const { tabularLookupId, lookupName, lookupLevel } = item
        const editDisabled = !isTemplate && lookupLevel === TabularLookupLevel.Template && !item.isEditable

        return (
          <List.Item key={tabularLookupId}>
            <Text>{lookupName}</Text>
            <div className='action-buttons'>
              {editDisabled ? (
                <Tooltip title='View Tabular Lookup'>
                  <Button
                    data-testid='view-button'
                    icon={<EyeOutlined />}
                    onClick={() => {
                      nextStep()
                      setTabularLookupId(tabularLookupId)
                    }}
                    type='link'
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Edit Tabular Lookup'>
                  <Button
                    data-testid='edit-button'
                    icon={<EditOutlined />}
                    onClick={() => {
                      nextStep()
                      setTabularLookupId(tabularLookupId)
                    }}
                    type='link'
                  />
                </Tooltip>
              )}
              {canRemove && (
                <Popconfirm
                  placement='rightTop'
                  title={popoverMessage.confirmDeleteTabularLookup}
                  onConfirm={() => deleteTabularLookup({ lookupId: tabularLookupId })}
                  okText='Yes'
                  cancelText='No'
                >
                  <Tooltip title='Remove Tabular Lookup'>
                    <Button
                      data-testid='delete-button'
                      type='link'
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </div>
          </List.Item>
        )
      }}
    />
  )
}

export default TabularLookupListComponent
