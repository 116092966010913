import React, { FunctionComponent, useMemo } from 'react'
import { Checkbox } from 'antd'

import ModalComponent from 'Components/ModalComponent'

interface IProps {
  hideModal: () => void
  visible: boolean
  disabled?: boolean
  onLeftAlignExportChange: (leftAlign: boolean) => void
  onHideSortOrderExportChange: (value: boolean) => void
  onVerticalAlignmentExportChange: (value: boolean) => void
  leftAlignNumbersInExcelExport: boolean
  hideSortOrderInExcelExport: boolean
  verticalAlignment: boolean
}

export const PlanExportSettingsModalComponent: FunctionComponent<IProps> = ({
  hideModal,
  visible,
  disabled,
  leftAlignNumbersInExcelExport,
  onLeftAlignExportChange,
  verticalAlignment,
  hideSortOrderInExcelExport,
  onHideSortOrderExportChange,
  onVerticalAlignmentExportChange
}: IProps) => {
  const body = useMemo(() => (
    <div className='plan-export-settings-container' data-testid='plan-export-settings'>
      <Checkbox
        data-testid='leftAlignNumbersInExcelExport-checkbox'
        disabled={disabled}
        className='ms-template-title__checkbox-export'
        checked={leftAlignNumbersInExcelExport}
        onChange={(event) => onLeftAlignExportChange(event.target.checked)}
      >
        <label>Left align export</label>
      </Checkbox>
      <Checkbox
        data-testid='verticalAlignment-checkbox'
        disabled={disabled}
        className='ms-template-title__checkbox-export'
        checked={verticalAlignment}
        onChange={(event) => onVerticalAlignmentExportChange(event.target.checked)}
      >
        <label>Align plan fields vertically</label>
      </Checkbox>
      <Checkbox
        data-testid='hideSortOrderInExcelExport-checkbox'
        disabled={disabled}
        className='ms-template-title__checkbox-export'
        checked={hideSortOrderInExcelExport}
        onChange={(event) => onHideSortOrderExportChange(event.target.checked)}
      >
        <label>Hide sort column</label>
      </Checkbox>
    </div>
  ), [
    disabled,
    hideSortOrderInExcelExport,
    leftAlignNumbersInExcelExport,
    onHideSortOrderExportChange,
    onLeftAlignExportChange,
    onVerticalAlignmentExportChange,
    verticalAlignment
  ])

  return (
    <ModalComponent
      footer={null}
      onCancel={hideModal}
      modalBody={body}
      title='Plan export settings'
      open={visible}
    />
  )
}

export default PlanExportSettingsModalComponent
