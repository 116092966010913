import { useMemo } from 'react'
import { useLinkedLookupsList } from 'Components/LinkedLookup/hooks/useLinkedLookupsList'
import { getLinkedLookupVariables } from 'Components/MediaPlanVersion/hooks/linkedLookupHelper'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import { IMediaPlanVersionLinkedLookupHelperValues } from '../entities/IMediaPlanVersionLinkedLookupHelperValues'
import { useAppSelector } from '../../../store'
import { selectCurrentMediaPlanVersionFields } from '../../../selectors'
import { ILevelData } from '../helpers/getDataRelevantForLevel'

export const usePlanLinkedLookups = (levelData: ILevelData): IMediaPlanVersionLinkedLookupHelperValues => {
  const { data: linkedLookups = [] } = useLinkedLookupsList()
  const mediaPlanVersionFields = useAppSelector(selectCurrentMediaPlanVersionFields)

  const mediaPlanFieldsWithLookups = useMemo(() => mediaPlanVersionFields.filter((item) =>
    linkedLookups.some((l) => item.clientMediaPlanField.mediaPlanFieldId === l.dependentMediaPlanFieldId)
  ), [mediaPlanVersionFields, linkedLookups])

  const linkedLookupValues = useMemo(
    () => {
      return mediaPlanFieldsWithLookups.reduce((lookupValues: IMediaPlanVersionLinkedLookupHelperValues, currentField) => ({
        ...lookupValues,
        [currentField.clientMediaPlanField.mediaPlanFieldId]:
          getLinkedLookupVariables(
            { currentField, fields: mediaPlanVersionFields, linkedLookups, levelData }
          )
      }), {})
    },
    [mediaPlanFieldsWithLookups, mediaPlanVersionFields, linkedLookups, levelData]
  )

  const memoizedLinkedLookupValues = useDeepEqualMemoized(linkedLookupValues)

  return memoizedLinkedLookupValues
}

