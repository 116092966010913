import React, { useMemo } from 'react'
import { IMediaPlanMetaField } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { TabularLookupCreateStepComponent } from 'Components/TabularLookup/TabularLookupCreateStepComponent'
import { useAppSelector } from '../../store'
import { selectCurrentMediaPlanTemplate } from '../../selectors'

interface ITabularLookupTemplateCreateProps {
  isTemplate: boolean
  tabularLookupId: number
}

export const TabularLookupTemplateCreateComponent: React.FC<ITabularLookupTemplateCreateProps> = (props) => {
  const mediaPlanTemplate = useAppSelector(selectCurrentMediaPlanTemplate)
  const lookupMediaPlanTemplateFields = useMemo(
    () =>
      mediaPlanTemplate.mediaPlanTemplateFields.filter(
        (f: IMediaPlanMetaField) =>
          f.clientMediaPlanField.mediaPlanField.fieldDataType.isLookupType
      ),
    [mediaPlanTemplate.mediaPlanTemplateFields]
  )

  return (
    <TabularLookupCreateStepComponent
      fields={lookupMediaPlanTemplateFields}
      {...props}
    />
  )
}

export default TabularLookupTemplateCreateComponent
