import React, { FunctionComponent, useEffect, useState } from 'react'
import { IMSHierarchies } from '@mindshare/layout'

import { getRedistributions, setCurrentRedistribution } from 'Actions/mediaPlansActions'
import { RedistributionCreateContainer } from 'Components/Redistribution/containers/RedistributionCreateContainer'
import { RedistributionContainer } from 'Components/Redistribution/containers/RedistributionContainer'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useAppDispatch, useAppSelector } from '../../store'

interface IProps {
  hideModal: () => void
  visible: boolean
  hierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  financeList: IMediaPlanVersionFinanceListFields
}

export const RedistributionModalContainer: FunctionComponent<IProps> = ({
  hideModal,
  visible,
  hierarchies,
  masteredListsData,
  financeList
}) => {
  const dispatch = useAppDispatch()
  const [createModalVisible, setCreateModalVisible] = useState(false)
  const mediaPlanId = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion?.parseData?.mediaPlanId || '')
  const currentClientId = useAppSelector(state => state.app.currentClient.id)

  useEffect(() => {
    dispatch(getRedistributions(mediaPlanId, currentClientId))
  }, [dispatch, mediaPlanId, currentClientId])

  const showCreateModal = () => {
    setCreateModalVisible(true)
  }

  const hideCreateModal = () => {
    setCreateModalVisible(false)
  }

  const onCancel = () => {
    dispatch(setCurrentRedistribution(null))
    hideCreateModal()
    hideModal()
  }

  return (
    <>
      {createModalVisible ? (
        <RedistributionCreateContainer
          hideCreateModal={hideCreateModal}
          hideModal={onCancel}
          visible={createModalVisible}
          hierarchies={hierarchies}
          masteredListsData={masteredListsData}
          financeList={financeList}
        />
      ) : (
        <RedistributionContainer
          showCreateModal={showCreateModal}
          hideModal={onCancel}
          visible={visible}
          hierarchies={hierarchies}
          masteredListsData={masteredListsData}
          financeList={financeList}
        />
      )}
    </>
  )
}
