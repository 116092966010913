import { RootState } from 'Reducers/index'
import { createSelector } from 'reselect'
import { IMediaPlanField } from 'Components/MediaPlanField/constants/entities/IMediaPlanField'

export const selectMediaPlanField = (state: RootState) => state.mediaPlanField

export const selectMediaPlanFields = createSelector(
  selectMediaPlanField,
  mediaPlanField => mediaPlanField.mediaPlanFields as IMediaPlanField[]
)
