import React, { FunctionComponent, useCallback } from 'react'
import { Button, Radio, List, Row, Col, Typography, Modal } from 'antd'
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { IMSHierarchies } from '@mindshare/layout'
import { setCurrentRedistribution, deleteRedistribution } from 'Actions/mediaPlansActions'
import { Redistribution } from 'Apis/generated/redistributionsApi'
import { RedistributionDetailsComponent } from 'Components/Redistribution/components/RedistributionDetailsComponent'
import { IMediaPlanVersionFinanceListFields } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { defaultContainerHeight } from '../constants/redistribution'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectRedistributions, selectCurrentRedistribution } from '../../../selectors'

const { Paragraph } = Typography
const { confirm } = Modal

interface IProps {
  showCreateModal: () => void
  hierarchies: IMSHierarchies
  financeList: IMediaPlanVersionFinanceListFields
  masteredListsData: IMasteredListsData
}

export const RedistributionListComponent: FunctionComponent<IProps> = ({ showCreateModal, hierarchies, financeList, masteredListsData }) => {
  const redistributions = useAppSelector(selectRedistributions)
  const currentRedistribution = useAppSelector(selectCurrentRedistribution)
  const mediaPlanId = useAppSelector(state => state.mediaPlans.currentMediaPlanVersion.parseData?.mediaPlanId || '')
  const currentClientId = useAppSelector(state => state.app.currentClient.id)

  const dispatch = useAppDispatch()

  const handleRedistributionClick = useCallback((redistribution) => {
    dispatch(setCurrentRedistribution(redistribution, financeList, hierarchies, masteredListsData))
  }, [dispatch, financeList, hierarchies, masteredListsData])

  const deleteRedistributionTemplate = useCallback(async (redistributionId: number) => {
    await dispatch(deleteRedistribution(mediaPlanId, redistributionId, currentClientId))
  }, [dispatch, mediaPlanId, currentClientId])

  const onAddRedistributionTemplate = useCallback(() => {
    showCreateModal()
    dispatch(setCurrentRedistribution(null))
  }, [showCreateModal, dispatch])

  const showConfirmModal = ({ redistributionName, redistributionId }: Redistribution) => {
    const content = <p>Do you really want to delete <strong>{redistributionName}</strong> redistribution config?</p>

    confirm({
      title: 'Delete',
      content,
      okText: 'Ok',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk () {
        deleteRedistributionTemplate(redistributionId)
      },
      centered: true,
      icon: <></>
    })
  }

  return (
    <div className="redistribution-form" data-testid='redistribution-modal'>
      <Row gutter={32}>
        <Col span={16}>
          <div className="redistribution-header-container">
            <Paragraph>Please select a template or create a new one</Paragraph>
            <Button type='primary' onClick={onAddRedistributionTemplate} icon={<PlusCircleOutlined />}>Add</Button>
          </div>
          <List
            bordered={true}
            dataSource={redistributions}
            style={{ height: defaultContainerHeight }}
            renderItem={(redistribution) => {
              const { redistributionId, redistributionName } = redistribution

              return (
                <List.Item key={redistributionId}>
                  <Radio
                    checked={currentRedistribution?.redistributionId === redistributionId}
                    onClick={() => handleRedistributionClick(redistribution)}
                    data-testid='redistribution-item'
                  >
                    {redistributionName}
                  </Radio>
                  <Button
                    data-testid='delete-redistribution-button'
                    type='link'
                    icon={<DeleteOutlined />}
                    onClick={() => showConfirmModal(redistribution)}
                  />
                </List.Item>
              )
            }}>
          </List>
        </Col>
        <Col span={8}>
          <RedistributionDetailsComponent />
        </Col>
      </Row>
    </div>
  )
}

