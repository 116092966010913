import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLinkedLookups: build.query<
      GetLinkedLookupsApiResponse,
      GetLinkedLookupsApiArg
    >({
      query: (queryArg) => ({
        url: `/linkedLookups`,
        params: {
          clientId: queryArg.clientId,
          includeDeleted: queryArg.includeDeleted,
        },
      }),
    }),
    postLinkedLookups: build.mutation<
      PostLinkedLookupsApiResponse,
      PostLinkedLookupsApiArg
    >({
      query: (queryArg) => ({
        url: `/linkedLookups`,
        method: "POST",
        body: queryArg.linkedLookup,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    getLinkedLookupsById: build.query<
      GetLinkedLookupsByIdApiResponse,
      GetLinkedLookupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/linkedLookups/${queryArg.id}`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    putLinkedLookupsById: build.mutation<
      PutLinkedLookupsByIdApiResponse,
      PutLinkedLookupsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/linkedLookups/${queryArg.id}`,
        method: "PUT",
        body: queryArg.linkedLookup,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    postLinkedLookupsBatchUpdate: build.mutation<
      PostLinkedLookupsBatchUpdateApiResponse,
      PostLinkedLookupsBatchUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/linkedLookups/batchUpdate`,
        method: "POST",
        body: queryArg.body,
        params: {
          clientId: queryArg.clientId,
          updateValues: queryArg.updateValues,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetLinkedLookupsApiResponse =
  /** status 200 List of linked lookups */ LinkedLookup[];
export type GetLinkedLookupsApiArg = {
  clientId: number;
  includeDeleted?: boolean;
};
export type PostLinkedLookupsApiResponse =
  /** status 201 Linked lookup successfully created */ LinkedLookup;
export type PostLinkedLookupsApiArg = {
  clientId: number;
  linkedLookup: LinkedLookup;
};
export type GetLinkedLookupsByIdApiResponse =
  /** status 200 Linked lookup */ LinkedLookup;
export type GetLinkedLookupsByIdApiArg = {
  clientId: number;
  id?: number;
};
export type PutLinkedLookupsByIdApiResponse =
  /** status 201 Updates were applied successfully */ LinkedLookup;
export type PutLinkedLookupsByIdApiArg = {
  clientId: number;
  id?: number;
  linkedLookup: LinkedLookup;
};
export type PostLinkedLookupsBatchUpdateApiResponse =
  /** status 201 Linked lookups successfully updated */ LinkedLookup[];
export type PostLinkedLookupsBatchUpdateApiArg = {
  clientId: number;
  updateValues?: boolean;
  body: LinkedLookup[];
};
export type LinkedLookupValue = {
  linkedLookupValueId?: number;
  linkedLookupId?: number;
  dependentValueId?: number;
  referencedValueId?: number;
  includeChildrenOnReferencedSide?: boolean;
  includeLevelsBelowHierarchy?: boolean | null;
};
export type LinkedLookup = {
  linkedLookupId?: number;
  dependentMediaPlanFieldId?: number;
  referencedMediaPlanFieldId?: number;
  enforced?: boolean;
  linkedLookupValues?: LinkedLookupValue[] | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string | null;
  deletedBy?: string | null;
  deletedOn?: string | null;
};
export const {
  useGetLinkedLookupsQuery,
  useLazyGetLinkedLookupsQuery,
  usePostLinkedLookupsMutation,
  useGetLinkedLookupsByIdQuery,
  useLazyGetLinkedLookupsByIdQuery,
  usePutLinkedLookupsByIdMutation,
  usePostLinkedLookupsBatchUpdateMutation,
} = injectedRtkApi;
