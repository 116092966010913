import React, { CSSProperties, FunctionComponent } from 'react'
import { IMSHierarchies } from '@mindshare/layout'
import { StickyColumnType } from 'Constants/enums/StickyColumnType'
import { IMediaPlanVersionLinkedLookupHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionLinkedLookupHelperValues'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import {
  IMediaPlanVersionMasteredFieldsHelperValues,
  IMediaPlanVersionFinanceListFields
} from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useAppSelector } from '../../store'
import { selectAvailableFlightFields } from '../../selectors'
import FlightGroupFlightButtonContainer from './FlightGroupFlightButtonContainer'
import FlightTableFieldsContainer from './FlightTableFieldsContainer'

interface IProps {
  flightGroupIndex: number
  flightFields: IMediaPlanTemplateFields[]
  calculationMode: boolean
  collapse: boolean
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (selected: string) => void
  getStickyStyle: (type: StickyColumnType, id?: number) => CSSProperties
  linkedLookupFieldsHelperValues: IMediaPlanVersionLinkedLookupHelperValues
  clickableFieldProps: IClickableFieldProps
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  financeListFieldsData?: IMediaPlanVersionFinanceListFields
  collapsedFlightGroups?: number[]
}

export const FlightsTableContainer: FunctionComponent<IProps> = ({
  calculationMode,
  collapse,
  flightGroupIndex,
  flightFields,
  tokenHandler,
  unfilteredHierarchies,
  masteredListsData,
  onAvailableFieldSelected,
  getStickyStyle,
  linkedLookupFieldsHelperValues,
  clickableFieldProps,
  masteredDataHelperValues,
  financeListFieldsData,
  collapsedFlightGroups
}: IProps) => {
  const availableFlightFields = useAppSelector(selectAvailableFlightFields)

  return (
    <>
      <FlightGroupFlightButtonContainer
        {...{ flightFields, flightGroupIndex, getStickyStyle }}
      />
      <FlightTableFieldsContainer
        availableMediaPlanFields={availableFlightFields}
        calculationMode={calculationMode}
        collapse={collapse}
        masteredListsData={masteredListsData}
        flightFields={flightFields}
        onAvailableFieldSelected={onAvailableFieldSelected}
        tokenHandler={tokenHandler}
        unfilteredHierarchies={unfilteredHierarchies}
        availableFlightFields={availableFlightFields}
        flightGroupIndex={flightGroupIndex}
        linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
        clickableFieldProps={clickableFieldProps}
        masteredDataHelperValues={masteredDataHelperValues}
        financeListFieldsData={financeListFieldsData}
        collapsedFlightGroups={collapsedFlightGroups}
      />
    </>
  )
}

export default FlightsTableContainer
