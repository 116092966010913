import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplates:
      build.query<
        GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiResponse,
        GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/sync/mediaPlanTemplates/${queryArg.parentMediaPlanTemplateId}/childTemplates`,
          params: {
            clientId: queryArg.clientId,
          },
        }),
      }),
    getSyncTemplateOptions: build.query<
      GetSyncTemplateOptionsApiResponse,
      GetSyncTemplateOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/sync/templateOptions`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    getSyncTemplateJob: build.query<
      GetSyncTemplateJobApiResponse,
      GetSyncTemplateJobApiArg
    >({
      query: (queryArg) => ({
        url: `/sync/templateJob`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    postSyncTemplateJob: build.mutation<
      PostSyncTemplateJobApiResponse,
      PostSyncTemplateJobApiArg
    >({
      query: (queryArg) => ({
        url: `/sync/templateJob`,
        method: "POST",
        body: queryArg.postTemplateSyncJob,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    getSyncTemplateJobByTemplateJobId: build.query<
      GetSyncTemplateJobByTemplateJobIdApiResponse,
      GetSyncTemplateJobByTemplateJobIdApiArg
    >({
      query: (queryArg) => ({
        url: `/sync/templateJob/${queryArg.templateJobId}`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    getSyncLinkedTemplatesByBaseMediaPlanTemplateId: build.query<
      GetSyncLinkedTemplatesByBaseMediaPlanTemplateIdApiResponse,
      GetSyncLinkedTemplatesByBaseMediaPlanTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/sync/linkedTemplates/${queryArg.baseMediaPlanTemplateId}`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    getSyncTemplateUpdateResultByTemplateSyncUsageId: build.query<
      GetSyncTemplateUpdateResultByTemplateSyncUsageIdApiResponse,
      GetSyncTemplateUpdateResultByTemplateSyncUsageIdApiArg
    >({
      query: (queryArg) => ({
        url: `/sync/templateUpdateResult/${queryArg.templateSyncUsageId}`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiResponse =
  /** status 200 OK */ TemplateSyncTemplate[];
export type GetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesApiArg =
  {
    clientId: number;
    parentMediaPlanTemplateId?: number;
  };
export type GetSyncTemplateOptionsApiResponse =
  /** status 200 Returns Template Sync Options */ TemplateSyncOption[];
export type GetSyncTemplateOptionsApiArg = {
  clientId: number;
};
export type GetSyncTemplateJobApiResponse =
  /** status 200 OK */ TemplateSyncJob[];
export type GetSyncTemplateJobApiArg = {
  clientId: number;
};
export type PostSyncTemplateJobApiResponse =
  /** status 201 Create a new TemplateSyncJob */ number;
export type PostSyncTemplateJobApiArg = {
  clientId: number;
  postTemplateSyncJob: PostTemplateSyncJob;
};
export type GetSyncTemplateJobByTemplateJobIdApiResponse =
  /** status 200 Returns a TemplateSyncJob by id */ TemplateSyncJobDetailed;
export type GetSyncTemplateJobByTemplateJobIdApiArg = {
  clientId: number;
  templateJobId?: number;
};
export type GetSyncLinkedTemplatesByBaseMediaPlanTemplateIdApiResponse =
  /** status 200 Returns a list of linked MediaPlanTemplates updated by TemplateSyncJob by base MediaPlanTemplateId */ TemplateSyncUsage[];
export type GetSyncLinkedTemplatesByBaseMediaPlanTemplateIdApiArg = {
  clientId: number;
  baseMediaPlanTemplateId?: number;
};
export type GetSyncTemplateUpdateResultByTemplateSyncUsageIdApiResponse =
  /** status 200 Returns a result of template sync job for a child template by templateSyncUsageId */ TemplateSyncUpdateResult;
export type GetSyncTemplateUpdateResultByTemplateSyncUsageIdApiArg = {
  clientId: number;
  templateSyncUsageId?: number;
};
export type TemplateSyncTemplate = {
  clientId?: number;
  clientName?: string | null;
  clientDisplayName?: string | null;
  mediaPlanTemplateId?: number;
  templateName?: string | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string;
};
export type TemplateSyncOption = {
  templateSyncOptionId?: number;
  templateSyncOptionName?: string | null;
  isPerField?: boolean;
  isForGlobalClients?: boolean;
};
export type TemplateSyncJob = {
  templateSyncJobId?: number;
  templateSyncJobName?: string | null;
  mediaPlanTemplateId?: number;
  mediaPlanTemplateName?: string | null;
  isSuccessful?: boolean | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string;
};
export type TemplateSyncJobOption = {
  templateSyncOptionId?: number;
  mediaPlanFieldLevelId?: number | null;
};
export type ChildTemplate = {
  clientId?: number;
  mediaPlanTemplateId?: number | null;
  isNewTemplate?: boolean;
};
export type PostTemplateSyncJob = {
  templateSyncJobName?: string | null;
  parentMediaPlanTemplateId?: number;
  templateSyncJobOptions?: TemplateSyncJobOption[] | null;
  childTemplates?: ChildTemplate[] | null;
};
export type TemplateSyncJobDetailed = {
  templateSyncJobId?: number;
  templateSyncJobName?: string | null;
  mediaPlanTemplateId?: number;
  mediaPlanTemplateName?: string | null;
  isSuccessful?: boolean | null;
  jobBlobUrl?: string | null;
  statusBlobUrl?: string | null;
  templateSyncJobOptions?: TemplateSyncJobOption[] | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string;
};
export type TemplateSyncUsage = {
  templateSyncUsageId?: number;
  mediaPlanTemplateId?: number;
  destinationClientId?: number;
  destinationTemplateId?: number;
  destinationTemplateName?: string | null;
  lastTemplateSyncJobId?: number;
  templateSyncJobName?: string | null;
  templateSyncStatusId?: number;
  status?: string | null;
  lastUpdated?: string;
};
export type MediaPlanFieldSyncResult = {
  mediaPlanFieldLevelId?: number | null;
  success?: boolean;
};
export type TemplateSyncOptionResult = {
  templateSyncOptionId?: number;
  success?: boolean;
  description?: string | null;
  fieldSyncResults?: MediaPlanFieldSyncResult[] | null;
};
export type TemplateSyncUpdateResult = {
  destinationClientId?: number;
  destinationTemplateId?: number;
  destinationTemplateName?: string | null;
  templateSyncJobName?: string | null;
  statusBlobUrl?: string | null;
  status?: string | null;
  lastUpdated?: string;
  templateUpdateResults?: TemplateSyncOptionResult[] | null;
  errors?: string[] | null;
  warnings?: string[] | null;
};
export const {
  useGetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesQuery,
  useLazyGetSyncMediaPlanTemplatesByParentMediaPlanTemplateIdChildTemplatesQuery,
  useGetSyncTemplateOptionsQuery,
  useLazyGetSyncTemplateOptionsQuery,
  useGetSyncTemplateJobQuery,
  useLazyGetSyncTemplateJobQuery,
  usePostSyncTemplateJobMutation,
  useGetSyncTemplateJobByTemplateJobIdQuery,
  useLazyGetSyncTemplateJobByTemplateJobIdQuery,
  useGetSyncLinkedTemplatesByBaseMediaPlanTemplateIdQuery,
  useLazyGetSyncLinkedTemplatesByBaseMediaPlanTemplateIdQuery,
  useGetSyncTemplateUpdateResultByTemplateSyncUsageIdQuery,
  useLazyGetSyncTemplateUpdateResultByTemplateSyncUsageIdQuery,
} = injectedRtkApi;
