import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMediaPlanTemplateFields: build.query<
      GetMediaPlanTemplateFieldsApiResponse,
      GetMediaPlanTemplateFieldsApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanTemplateFields`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    putMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAlias:
      build.mutation<
        PutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasApiResponse,
        PutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasApiArg
      >({
        query: (queryArg) => ({
          url: `/mediaPlanTemplateField/${queryArg.mediaPlanTemplateFieldId}/approveAlias`,
          method: "PUT",
          body: queryArg.approveAliasRequest,
          params: {
            clientId: queryArg.clientId,
          },
        }),
      }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type GetMediaPlanTemplateFieldsApiResponse =
  /** status 200 Returns all Media Plan Template Fields which have aliases */ MediaPlanTemplateFieldAliasRead[];
export type GetMediaPlanTemplateFieldsApiArg = {
  clientId: number;
};
export type PutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasApiResponse =
  unknown;
export type PutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasApiArg =
  {
    clientId: number;
    mediaPlanTemplateFieldId?: number;
    /** true or false. true - to approve; false - to unapprove */
    approveAliasRequest: ApproveAliasRequest;
  };
export type MappingDestinationOptions = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 100 | 200;
export type FieldDataType = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldDataTypeRead = {
  fieldDataTypeId?: number;
  dataTypeName?: string | null;
  dataType?: string | null;
  label?: string | null;
  isLookupType?: boolean;
  isClientDefinedListOrUniqueString?: boolean;
  isHierarchy?: boolean;
  isClientHierarchy?: boolean;
  isClientList?: boolean;
  isFinance?: boolean;
  isFinanceAudience?: boolean;
  isCostDatabase?: boolean;
  isParameterizedByGeographyAndMedia?: boolean;
  isNumeric?: boolean;
  isLongInt?: boolean;
  isDecimalType?: boolean;
  isString?: boolean;
  isRichText?: boolean;
  isDate?: boolean;
  cSharpDataType?: string | null;
  mappingDestination?: MappingDestinationOptions;
};
export type FieldLevel = {
  fieldLevelId?: number;
  fieldLevelName?: string | null;
};
export type MediaPlanTemplateFieldAlias = {
  mediaPlanTemplateFieldId?: number;
  mediaPlanTemplateId?: number;
  templateName?: string | null;
  mediaPlanFieldLabel?: string | null;
  clientMediaPlanFieldLabel?: string | null;
  templateFieldLabel?: string | null;
  fieldDataType?: FieldDataType;
  fieldLevel?: FieldLevel;
  isDisabled?: boolean;
  submittedBy?: string | null;
  submittedOn?: string;
  approvedBy?: string | null;
  approvedOn?: string | null;
  fieldDescription?: string | null;
};
export type MediaPlanTemplateFieldAliasRead = {
  mediaPlanTemplateFieldId?: number;
  mediaPlanTemplateId?: number;
  templateName?: string | null;
  mediaPlanFieldLabel?: string | null;
  clientMediaPlanFieldLabel?: string | null;
  templateFieldLabel?: string | null;
  fieldDataType?: FieldDataTypeRead;
  fieldLevel?: FieldLevel;
  isDisabled?: boolean;
  submittedBy?: string | null;
  submittedOn?: string;
  approvedBy?: string | null;
  approvedOn?: string | null;
  isApproved?: boolean;
  fieldDescription?: string | null;
};
export type ApproveAliasRequest = {
  approve?: boolean;
};
export const {
  useGetMediaPlanTemplateFieldsQuery,
  useLazyGetMediaPlanTemplateFieldsQuery,
  usePutMediaPlanTemplateFieldByMediaPlanTemplateFieldIdApproveAliasMutation,
} = injectedRtkApi;
