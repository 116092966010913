import { baseApi as api, TabularLookupWeb } from '../apis/generated/tabularLookupApi'

export type PostTabularLookupsApiResponse = TabularLookupWeb

export type PostTabularLookupsApiArg = {
  clientId: number
  tabularLookup: TabularLookupWeb
}

const enhancedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postTabularLookup: build.mutation<
    PostTabularLookupsApiResponse,
    PostTabularLookupsApiArg
    >({
      query: (queryArg) => ({
        url: '/tabularLookups',
        method: 'POST',
        body: queryArg.tabularLookup,
        params: { clientId: queryArg.clientId }
      })
    })
  })
}).enhanceEndpoints({
  endpoints: {
    getTabularLookups: { providesTags: ['tabularLookupList'] },
    postTabularLookup: { invalidatesTags: ['tabularLookupList'] },
    putTabularLookupsByLookupId: { invalidatesTags: ['tabularLookupList'] }
  }
})

export { enhancedRtkApi as baseApi }

export const {
  useGetTabularLookupsQuery,
  usePostTabularLookupMutation,
  usePutTabularLookupsByLookupIdMutation
} = enhancedRtkApi
