import React from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { Select } from 'antd'
import { FieldDataType } from 'Constants/enums/FieldDataType'
import { ITabularLookupFormData } from 'Components/TabularLookup/TabularLookupModalComponent'

const { Option } = Select

interface ITabularLookupDataTypeSelectProps {
  onChange: (dataTypeId: number, field, index: number) => void
  field: ControllerRenderProps<ITabularLookupFormData>
  index: number
  value: number
  disabled: boolean
}

export const TabularLookupDataTypeSelectComponent: React.FC<ITabularLookupDataTypeSelectProps> = ({ onChange, field, index, value, disabled }) => (
  <Select
    id='select-dimensions'
    className='ms-select'
    onChange={(dataTypeId) => onChange(dataTypeId, field, index)}
    placeholder='Select data type'
    allowClear={true}
    showSearch={true}
    optionFilterProp='children'
    style={{ width: '100%' }}
    popupMatchSelectWidth={false}
    value={value}
    disabled={disabled}
  >
    <Option
      value={FieldDataType.INTEGER}
    >
        Integer
    </Option>
    <Option
      value={FieldDataType.STRING}
    >
        String
    </Option>
    <Option
      value={FieldDataType.DECIMAL}
    >
        Decimal
    </Option>
  </Select>
)

export default TabularLookupDataTypeSelectComponent
