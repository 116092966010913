import React, { FunctionComponent } from 'react'
import { IMSHierarchies } from '@mindshare/layout'
import PlanLevelFieldsContainer from 'Containers/MediaPlanVersion/PlanLevelFieldsContainer'
import { IMediaPlanTemplateFields } from 'Components/MediaPlans/constants/entities/IMediaPlanMetaFields'
import { IMediaPlanVersionMasteredFieldsHelperValues } from 'Components/MediaPlanVersion/entities/IMediaPlanVersionMasteredFieldsHelperValues'
import { usePlanLinkedLookups } from 'Components/MediaPlanVersion/hooks/usePlanLinkedLookups'
import { IClickableFieldProps } from 'Components/MediaPlanVersion/hooks'
import { IMasteredListsData } from 'Hooks/useMasteredListFieldsData'
import { useDeepEqualMemoized } from 'Hooks/useDeepEqualMemoized'
import { useDataRelevantForLevel } from 'Components/MediaPlanVersion/hooks/useDataRelevantForLevel'
import { useAppSelector } from '../../store'
import { selectVersionLevelFields, selectAvailablePlanFields } from '../../selectors'

interface IProps {
  unfilteredHierarchies: IMSHierarchies
  masteredListsData: IMasteredListsData
  calculationMode: boolean
  tokenHandler: (token: string) => void
  onAvailableFieldSelected: (c: string, flightGroupIndex?: number) => void
  removePlanField: (mediaPlanField: IMediaPlanTemplateFields) => void
  masteredDataHelperValues: IMediaPlanVersionMasteredFieldsHelperValues
  clickableFieldProps: IClickableFieldProps
}

export const PlanLevelContainer: FunctionComponent<IProps> = (props: IProps) => {
  const {
    calculationMode,
    masteredListsData,
    unfilteredHierarchies,
    tokenHandler,
    onAvailableFieldSelected,
    removePlanField,
    masteredDataHelperValues,
    clickableFieldProps
  } = props

  const versionLevelFields = useAppSelector(selectVersionLevelFields)
  const availablePlanFields = useAppSelector(selectAvailablePlanFields)

  const dataRelevantForLevel = useDataRelevantForLevel()
  const linkedLookupFieldsHelperValues = usePlanLinkedLookups(dataRelevantForLevel)

  const memoizedVersionLevelFields = useDeepEqualMemoized(versionLevelFields)
  const memoizedAvailablePlanFields = useDeepEqualMemoized(availablePlanFields)

  return (
    <PlanLevelFieldsContainer
      mediaPlanFields={memoizedVersionLevelFields}
      availableMediaPlanFields={memoizedAvailablePlanFields}
      onAvailableFieldSelected={onAvailableFieldSelected}
      tokenHandler={tokenHandler}
      calculationMode={calculationMode}
      unfilteredHierarchies={unfilteredHierarchies}
      masteredListsData={masteredListsData}
      removePlanField={removePlanField}
      linkedLookupFieldsHelperValues={linkedLookupFieldsHelperValues}
      masteredDataHelperValues={masteredDataHelperValues}
      clickableFieldProps={clickableFieldProps}
    />
  )
}

export default PlanLevelContainer
