import React, { memo, FC, PropsWithChildren, useCallback } from 'react'
import classNames from 'classnames'
import { copyFlight } from 'Actions/mediaPlansActions'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useFlightGroupSelection } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelection'
import { IFlightGroup } from 'Components/MediaPlanVersion/constants/entities/IFlightGroup'
import { useFlightGroupSelectedFlights } from 'Components/MediaPlanVersion/hooks/useFlightGroupSelectedFlights'
import { useAppDispatch } from '../../store'

interface IFlightGroupRow {
  isCopied: boolean
  isCollapsed: boolean
  canCopyFlightGroup: (flightGroupSelection: Partial<IFlightGroup>) => boolean
  flightGroupId: number
  flightGroupIndex: number
  onInsertFlightGroups: (params: { shift?: 1 | 0; withFlights: boolean; flightGroupIndex: number; flightGroupSelection?: Partial<IFlightGroup>; eventIndex?: number }) => void
  onClearSelection: () => void
  isSubtotalGroup?: boolean
}

const FlightGroupRow: FC<PropsWithChildren<IFlightGroupRow>> = (props) => {
  const {
    children,
    flightGroupId,
    flightGroupIndex,
    isCopied,
    isCollapsed,
    canCopyFlightGroup,
    onInsertFlightGroups,
    onClearSelection,
    isSubtotalGroup
  } = props

  const dispatch = useAppDispatch()
  const selectedFlights = useFlightGroupSelectedFlights(flightGroupIndex)

  const { isSelected, flightGroupSelection } = useFlightGroupSelection({ mediaPlanFlightGroupId: flightGroupId }) ?? {
    isSelected: false,
    flightGroupSelection: null
  }

  const {
    attributes,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: flightGroupId })

  const style: React.CSSProperties = {
    transition,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    ...(isDragging ? { position: 'relative', zIndex: 1 } : {})
  }

  const handleCopyFlight = useCallback(() => {
    if (selectedFlights?.length) {
      dispatch(copyFlight())
    }
  }, [dispatch, selectedFlights])

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLTableRowElement>) => {
    const handlers = {
      'ctrl+KeyC': () => {
        if (canCopyFlightGroup(flightGroupSelection)) {
          // this is done at FlightGroupContainer level instead
          return
        }
        handleCopyFlight()
      },
      'ctrl+KeyV': () => {
        onInsertFlightGroups({ withFlights: true, flightGroupIndex, eventIndex: flightGroupIndex })
      },
      'Escape': onClearSelection
    }
    const handlerKey = [(event.ctrlKey || event.metaKey) && 'ctrl', event.code].filter(Boolean).join('+')
    const handler = handlers[handlerKey]

    if (handler) {
      handler()
    }
  }, [onInsertFlightGroups, flightGroupIndex, onClearSelection, canCopyFlightGroup, flightGroupSelection, handleCopyFlight])

  return (
    <tr
      data-flight-group-id={flightGroupId}
      data-testid={`${isSubtotalGroup ? 'subtotal-flight-grid-row' : 'flight-grid-row'}`}
      className={classNames({
        'flightGroup__container': true,
        '-collapsed': isCollapsed,
        '-selected': isSelected,
        '-copied': isCopied,
        '-subtotal': isSubtotalGroup
      })}
      onKeyDown={handleKeyDown}
      tabIndex={-1}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {children}
    </tr>
  )
}

export default memo(FlightGroupRow)
