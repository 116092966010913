import React from 'react'
import { useGetTabularLookupsQuery } from 'Apis/enhancedTabularLookupApi'
import { useCurrentClient } from 'Hooks/useCurrentClient'
import { useAppSelector } from '../../store'
import { selectCurrentMediaPlanTemplate } from '../../selectors'
import { TabularLookupListComponent } from './TabularLookupListComponent'

interface ITabularLookupTemplateListProps {
  nextStep: () => void
  setTabularLookupId: (id: number) => void
  isTemplate: boolean
}

export const TabularLookupTemplateListComponent: React.FC<ITabularLookupTemplateListProps> = ({ nextStep, setTabularLookupId, isTemplate }) => {
  const { data: currentClient } = useCurrentClient()
  const mediaPlanTemplate = useAppSelector(selectCurrentMediaPlanTemplate)
  const { data: tabularLookups } = useGetTabularLookupsQuery(
    { clientId: currentClient?.id, templateId: mediaPlanTemplate.mediaPlanTemplateId }
  )

  return (
    <TabularLookupListComponent
      tabularLookups={tabularLookups}
      nextStep={nextStep}
      setTabularLookupId={setTabularLookupId}
      isTemplate={isTemplate}
    />
  )
}

export default TabularLookupTemplateListComponent
