import { RootState } from 'Reducers/index'
import { IMediaPlansState } from 'Reducers/mediaPlansReducer'
import { createSelector } from 'reselect'

export const selectMediaPlans = (state: RootState) => state.mediaPlans

export const selectMediaPlanErrors = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.errors
)

export const selectNewUniqueStringObject = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.newUniqueStringObject
)

export const selectSelectedFlights = createSelector(
  selectMediaPlans,
  (mediaPlans) => mediaPlans.selectedFlights
)

export const selectCopiedFlight = createSelector(
  selectMediaPlans,
  (mediaPlans): IMediaPlansState['copiedFlight'] => mediaPlans.copiedFlight
)

export const selectCopiedFlightData = createSelector(
  selectMediaPlans,
  (mediaPlans): IMediaPlansState['copiedFlightData'] => mediaPlans.copiedFlightData
)

export const selectFlightGroupSelection = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.selectedFlightGroups
)

const selectCurrentMediaPlanVersionUnmodified = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.currentMediaPlanVersionUnmodified
)

export const selectUnmodifiedStringifiedParseData = createSelector(
  selectCurrentMediaPlanVersionUnmodified,
  currentMediaPlanVersion => currentMediaPlanVersion && currentMediaPlanVersion?.parseDataStringified
)

export const selectCollapsedFlightGroupRows = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.collapsedFlightGroupRows
)

export const selectSubtotals = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.subtotals
)

export const createMediaPlanFieldErrorSelector = () =>
  createSelector(
    selectMediaPlanErrors,
    (_state, mediaPlanField) => mediaPlanField,
    (errors, mediaPlanField) =>
      errors.find(e => {
        const fieldLevelId = mediaPlanField.clientMediaPlanField.mediaPlanField.fieldLevelId
        return e.fieldId === mediaPlanField.clientMediaPlanFieldId && e.fieldLevel === fieldLevelId
      })?.error || '')

export const selectPlans = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.mediaPlans
)

export const selectSelectedCalculatedField = createSelector(
  selectMediaPlans,
  mediaPlans => mediaPlans.selectedCalculatedField
)
