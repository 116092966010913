import { baseApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    mediaPlanTemplates: build.query<
      MediaPlanTemplatesApiResponse,
      MediaPlanTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanTemplates`,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    postMediaPlanTemplates: build.mutation<
      PostMediaPlanTemplatesApiResponse,
      PostMediaPlanTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanTemplates`,
        method: "POST",
        body: queryArg.mediaPlanTemplateWithMediaTemplateFields,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    putMediaPlanTemplatesById: build.mutation<
      PutMediaPlanTemplatesByIdApiResponse,
      PutMediaPlanTemplatesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanTemplates/${queryArg.id}`,
        method: "PUT",
        body: queryArg.mediaPlanTemplateWithMediaTemplateFields,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
    postMediaPlanTemplatesByIdTestCalculations: build.mutation<
      PostMediaPlanTemplatesByIdTestCalculationsApiResponse,
      PostMediaPlanTemplatesByIdTestCalculationsApiArg
    >({
      query: (queryArg) => ({
        url: `/mediaPlanTemplates/${queryArg.id}/testCalculations`,
        method: "POST",
        body: queryArg.body,
        params: {
          clientId: queryArg.clientId,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as baseApi };
export type MediaPlanTemplatesApiResponse =
  /** status 200 Returns list of Media Plan Templates */ MediaPlanTemplate[];
export type MediaPlanTemplatesApiArg = {
  clientId: number;
};
export type PostMediaPlanTemplatesApiResponse =
  /** status 201 Create a new Media Plan Template */ number;
export type PostMediaPlanTemplatesApiArg = {
  clientId: number;
  mediaPlanTemplateWithMediaTemplateFields: MediaPlanTemplateWithMediaTemplateFields;
};
export type PutMediaPlanTemplatesByIdApiResponse = unknown;
export type PutMediaPlanTemplatesByIdApiArg = {
  clientId: number;
  id?: number;
  mediaPlanTemplateWithMediaTemplateFields: MediaPlanTemplateWithMediaTemplateFields;
};
export type PostMediaPlanTemplatesByIdTestCalculationsApiResponse =
  /** status 200 Get calculation results */ CalculationResults;
export type PostMediaPlanTemplatesByIdTestCalculationsApiArg = {
  clientId: number;
  id?: number;
  body: MediaPlanTemplateField[];
};
export type MediaPlanTemplate = {
  mediaPlanTemplateId?: number;
  templateName?: string | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string;
  deletedOn?: string | null;
  brandHierarchyId?: number | null;
  businessHierarchyId?: number | null;
  geographyHierarchyId?: number | null;
  mediaHierarchyId?: number | null;
  leftAlignNumbersInExcelExport?: boolean;
  hideSortOrderInExcelExport?: boolean;
  verticalAlignment?: boolean;
};
export type MediaPlanTemplateField = {
  mediaPlanTemplateFieldId?: number;
  mediaPlanTemplateId?: number;
  clientMediaPlanFieldId?: number;
  fieldLabel?: string | null;
  displayLabel?: string | null;
  defaultValue?: string | null;
  calculation?: string | null;
  includeLevelsBelowHierarchy?: boolean;
  isComplexCalculation?: boolean;
  isEditable?: boolean;
  isApportionmentField?: boolean;
  isFooter?: boolean;
  sortOrder?: number;
  requiredHierarchyLevelId?: number | null;
  approvedBy?: string | null;
  approvedOn?: string | null;
  templateFieldAvailabilityId?: number;
  templateFieldTypeId?: number;
  functionId?: number | null;
};
export type MediaPlanTemplateWithMediaTemplateFields = {
  mediaPlanTemplateId?: number;
  templateName?: string | null;
  createdBy?: string | null;
  createdOn?: string;
  modifiedBy?: string | null;
  modifiedOn?: string;
  deletedOn?: string | null;
  brandHierarchyId?: number | null;
  businessHierarchyId?: number | null;
  geographyHierarchyId?: number | null;
  mediaHierarchyId?: number | null;
  leftAlignNumbersInExcelExport?: boolean;
  hideSortOrderInExcelExport?: boolean;
  verticalAlignment?: boolean;
  mediaPlanTemplateFields?: MediaPlanTemplateField[] | null;
};
export type FieldLevelOptions = 1 | 2 | 3 | 4;
export type CalculationFieldResult = {
  fieldLevel?: FieldLevelOptions;
  instanceId?: number;
  fieldId?: number;
  result?: string | null;
  error?: string | null;
};
export type GroupingField = {
  clientMediaPlanFieldId?: number;
  groupingValue?: string | null;
};
export type FlightGroupSubtotal = {
  clientMediaPlanFieldId?: number;
  subtotal?: string | null;
};
export type FlightSubtotal = {
  clientMediaPlanFieldId?: number;
  subtotal?: string | null;
  startDate?: string;
  endDate?: string;
  month?: number;
};
export type SubtotalCalculationGroup = {
  flightGroupIds?: number[] | null;
  groupingFields?: GroupingField[] | null;
  flightGroupSubtotals?: FlightGroupSubtotal[] | null;
  flightSubtotals?: FlightSubtotal[] | null;
};
export type FlightGroupGrandTotal = {
  clientMediaPlanFieldId?: number;
  grandTotal?: string | null;
};
export type FlightGrandTotal = {
  clientMediaPlanFieldId?: number;
  grandTotal?: string | null;
  startDate?: string;
  endDate?: string;
  month?: number;
};
export type GrandTotal = {
  flightGroupGrandTotals?: FlightGroupGrandTotal[] | null;
  flightGrandTotals?: FlightGrandTotal[] | null;
};
export type SubtotalCalculationResult = {
  subtotalGroups?: SubtotalCalculationGroup[] | null;
  grandTotals?: GrandTotal;
};
export type CalculationResults = {
  topLevelErrors?: string[] | null;
  fieldResults?: CalculationFieldResult[] | null;
  subtotals?: SubtotalCalculationResult;
  calculatorHash?: string | null;
  compilationError?: boolean;
};
export const {
  useMediaPlanTemplatesQuery,
  useLazyMediaPlanTemplatesQuery,
  usePostMediaPlanTemplatesMutation,
  usePutMediaPlanTemplatesByIdMutation,
  usePostMediaPlanTemplatesByIdTestCalculationsMutation,
} = injectedRtkApi;
